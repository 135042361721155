<template>
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.6364 22.3031L31.3712 14.1668C30.9621 13.538 30.2727 13.1592 29.5227 13.1592H27.197C26.8561 13.1592 26.6515 13.538 26.8409 13.8183L32.5227 22.6061C32.5985 22.7198 32.5152 22.8713 32.3788 22.8713H29.8864H27.8561H25.9091C25.6742 22.8713 25.4924 23.0607 25.4773 23.288C25.2576 26.9546 22.2197 29.8637 18.4924 29.8637C14.7652 29.8637 11.7273 26.9546 11.5076 23.288C11.4924 23.0531 11.3106 22.8713 11.0758 22.8713H9.12879H7.38636H4.61364C4.47727 22.8713 4.40152 22.7198 4.4697 22.6061L10.1515 13.8183C10.3333 13.5304 10.1288 13.1592 9.79545 13.1592H7.4697C6.7197 13.1592 6.0303 13.538 5.62121 14.1668L0.363636 22.3031C0.128788 22.6668 0 23.0986 0 23.538V34.8713C0 35.9774 0.901515 36.8789 2.00758 36.8789H18.5H34.9924C36.0985 36.8789 37 35.9774 37 34.8713V23.538C37 23.0986 36.8788 22.6743 36.6364 22.3031Z"
      fill="#0095AB"
    />
    <path
      d="M19.1058 0.295455C18.7952 -0.0984849 18.1967 -0.0984849 17.8861 0.295455L12.8937 6.64394C12.4922 7.15152 12.8558 7.90152 13.4997 7.90152H15.4694V19.8939C15.4694 21.5682 16.8255 22.9242 18.4997 22.9242C20.174 22.9242 21.53 21.5682 21.53 19.8939V7.89394H23.4997C24.1437 7.89394 24.5073 7.14394 24.1058 6.63636L19.1058 0.295455Z"
      fill="#0095AB"
    />
  </svg>
</template>

<script>
export default {
  name: 'UploadApplicationImageIcon',
};
</script>

<style scoped></style>
