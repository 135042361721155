<template>
  <div class="main">
    <div class="content">
      <div class="button-back">
        <header-control-button
          :title="title"
          @openSidebar="openSidebarMenu"
          :position="{ position: position }"
          :top="{ top: top + 'px' }"
        />
      </div>
      <div class="content--block">
        <div class="address">
          <location-icon />
          <p class="title">Head Office Address:</p>
          <p class="desc tools">
            522 Impala Road, Glenvista Johannesburg 2058 South Africa
          </p>
        </div>
        <div class="contact-information">
          <telephone-icon />
          <p class="title">Contact Information:</p>
          <div class="d-flex flex-column align-items-center gap-3">
            <div class="phone">
              <p>Phone:</p>
              <p class="desc">+27 (11) 682 0911</p>
            </div>
            <div class="phone">
              <p>Customer Service Center:</p>
              <p class="desc">+27 (11) 682 0911</p>
            </div>
          </div>
        </div>
        <div class="email-address">
          <email-address-icon />
          <p class="title">Email Address:</p>
          <p class="desc address--tools">{{ contact_us }}</p>
        </div>
        <div class="social-link d-flex justify-content-between mt-4">
          <div class="social-item" v-for="social in socials" :key="social?.id">
            <button class="btn btn-social-link">
              <a :href="social?.url" target="_blank">
                <img :src="publicPath + social?.img" alt="social-logo" />
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
    <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
  </div>
</template>

<script>
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import SlideMenu from '@/components/SlideMenu.vue';
import { mapGetters } from 'vuex';
import LocationIcon from '@/components/svg/contactUs/LocationIcon.vue';
import TelephoneIcon from '@/components/svg/contactUs/TelephoneIcon.vue';
import EmailAddressIcon from '@/components/svg/contactUs/EmailAddressIcon.vue';
import { socialLinkList } from '@/store/mock-data';
import { contact_us_email } from '@/helpers/enums';

export default {
  name: 'ContactUsTemplate.vue',
  components: {
    EmailAddressIcon,
    TelephoneIcon,
    LocationIcon,
    SlideMenu,
    HeaderControlButton,
  },
  data() {
    return {
      title: 'Contact us',
      isOpen: false,
      position: 'absolute',
      top: '88',
      socials: socialLinkList,
      contact_us: contact_us_email,
    };
  },
  methods: {
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
  },
  computed: {
    ...mapGetters(['getUser']),
    publicPath() {
      return process.env.BASE_URL;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.main {
  min-height: 100%;
  width: 100vw;
}
.content {
  background-image: url('@/assets/app_img/contact-us-background.png');
  background-size: cover;
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 30px;

  .button-back {
    padding: 0 25px;
  }

  &--block {
    margin-top: 75px;
    border-radius: 20px 20px 0 0;
    padding: 0 25px;
    @include d-flex-column;
    gap: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;

    .address,
    .contact-information,
    .email-address {
      width: 100%;
      min-height: 145px;
      height: auto;
      background: $color-white;
      border-radius: 20px;
      @include d-flex-column-center;
      gap: 10px;
      justify-content: center;
      padding: 10px;
    }

    .address,
    .contact-information,
    .email-address {
      .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #0095ab;
        margin: 0;
      }
      .desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 144%;
        display: flex;
        align-items: center;
        text-align: center;
        color: $color-black;
        opacity: 0.7;
        margin: 0;
      }
      .tools {
        width: calc(100% - 50px);
      }
    }

    .phone {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      gap: 15px;
    }
    .phone p:last-child {
      font-size: 12px;
    }
    .phone p {
      font-weight: 600;
      font-size: 13px;
      line-height: 144%;
      display: flex;
      align-items: center;
      color: $color-black;
      margin: 0;
    }
  }
}
.btn-social-link {
  width: 58px;
  height: 58px;
  background: $color-white;
  border-radius: 15px;
}
.btn:hover {
  color: $color-white;
  background-color: $color-white;
  border-color: $color-white;
}

@media only screen and (min-width: 1200px) {
  .content {
    background-size: contain;
  }
  .content--block {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 200px;
  }
  .content--block .address,
  .content--block .contact-information,
  .content--block .email-address {
    width: 30%;
  }
  .social-link {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .content--block .address .tools,
  .content--block .contact-information .tools,
  .content--block .email-address .tools {
    width: unset;
  }
}
</style>
