<template>
  <svg
    width="240"
    height="180"
    viewBox="0 0 240 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M188.293 28.083C188.598 26.4214 184.947 24.0079 183.689 25.4345C183.119 26.037 177.885 35.3638 177.63 36.3731C177.29 37.7187 180.996 39.7752 182.234 39.0215C183.081 38.5057 188.13 28.9869 188.293 28.083Z"
      fill="#043041"
    />
    <path
      d="M198.896 43.3837C200.347 41.9504 198.084 38.1876 195.983 38.5227C194.62 38.7407 186.97 42.0666 186.279 42.7193C185.101 43.8358 187.009 47.4858 188.672 47.2707C189.738 47.1369 198.048 44.2529 198.896 43.3837Z"
      fill="#043041"
    />
    <path
      d="M197.546 58.6523C199.041 57.8609 199.377 52.7036 197.759 52.6106C196.712 52.5474 191.61 52.6779 190.795 53.2074C189.347 54.1211 189.022 58.9022 190.931 58.8623C192.293 58.823 196.636 59.1354 197.546 58.6523Z"
      fill="#043041"
    />
    <circle cx="198.5" cy="154.5" r="7.5" fill="#14BED8" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M204.996 158.251C205.635 157.148 206 155.867 206 154.5C206 150.358 202.642 147 198.5 147C194.358 147 191 150.358 191 154.5C191 154.583 191.001 154.666 191.004 154.749C192.301 152.508 194.724 151 197.5 151C201.559 151 204.865 154.224 204.996 158.251Z"
      fill="url(#paint0_linear_590_3424)"
    />
    <circle cx="153.5" cy="8.5" r="4.5" fill="#14BED8" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M157.398 10.7507C157.781 10.0887 158 9.31994 158 8.5C158 6.01472 155.985 4 153.5 4C151.015 4 149 6.01472 149 8.5C149 8.54996 149.001 8.59973 149.002 8.64929C149.781 7.30463 151.235 6.4 152.9 6.4C155.335 6.4 157.319 8.33453 157.398 10.7507Z"
      fill="url(#paint1_linear_590_3424)"
    />
    <circle cx="21.5" cy="92.5" r="5.5" fill="#14BED8" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.2637 95.2509C26.732 94.4417 27 93.5021 27 92.5C27 89.4624 24.5376 87 21.5 87C18.4624 87 16 89.4624 16 92.5C16 92.5611 16.001 92.6219 16.003 92.6825C16.9541 91.039 18.7312 89.9333 20.7667 89.9333C23.7432 89.9333 26.1674 92.2978 26.2637 95.2509Z"
      fill="url(#paint2_linear_590_3424)"
    />
    <path
      d="M44.9041 141C44.1339 141 43.3952 141.306 42.8506 141.851C42.306 142.395 42 143.134 42 143.904C42 144.674 42.306 145.413 42.8506 145.958C43.3952 146.502 44.1339 146.808 44.9041 146.808C45.6744 146.808 46.413 146.502 46.9576 145.958C47.5022 145.413 47.8082 144.674 47.8082 143.904C47.8082 143.134 47.5022 142.395 46.9576 141.851C46.413 141.306 45.6744 141 44.9041 141V141Z"
      stroke="#EFC04D"
      stroke-width="2.08653"
      stroke-miterlimit="10"
    />
    <path
      d="M54.3038 32C53.9072 32 53.5267 32.1576 53.2463 32.438C52.9658 32.7185 52.8082 33.0989 52.8082 33.4956C52.8082 33.8922 52.9658 34.2726 53.2463 34.5531C53.5267 34.8335 53.9072 34.9911 54.3038 34.9911C54.7005 34.9911 55.0808 34.8335 55.3613 34.5531C55.6418 34.2726 55.7993 33.8922 55.7993 33.4956C55.7993 33.0989 55.6418 32.7185 55.3613 32.438C55.0808 32.1576 54.7005 32 54.3038 32V32Z"
      stroke="#EFC04D"
      stroke-width="1.07452"
      stroke-miterlimit="10"
    />
    <path
      d="M208.222 85.7261C207.825 85.7261 207.445 85.8836 207.164 86.1641C206.884 86.4446 206.726 86.825 206.726 87.2216C206.726 87.6183 206.884 87.9987 207.164 88.2791C207.445 88.5596 207.825 88.7172 208.222 88.7172C208.618 88.7172 208.999 88.5596 209.279 88.2791C209.56 87.9987 209.717 87.6183 209.717 87.2216C209.717 86.825 209.56 86.4446 209.279 86.1641C208.999 85.8836 208.618 85.7261 208.222 85.7261Z"
      stroke="#EFC04D"
      stroke-width="1.07452"
      stroke-miterlimit="10"
    />
    <circle cx="177.5" cy="164.5" r="2.5" fill="#14BED8" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M179.665 165.75C179.878 165.383 180 164.956 180 164.5C180 163.119 178.881 162 177.5 162C176.119 162 175 163.119 175 164.5C175 164.528 175 164.555 175.001 164.583C175.434 163.836 176.241 163.333 177.167 163.333C178.52 163.333 179.622 164.408 179.665 165.75Z"
      fill="url(#paint3_linear_590_3424)"
    />
    <rect
      x="80.85"
      y="52.85"
      width="86.3"
      height="59.3"
      rx="4.15"
      fill="#14BED8"
      stroke="#043041"
      stroke-width="1.7"
    />
    <rect x="80" y="121" width="63" height="12" rx="5" fill="#14BED8" />
    <rect x="149" y="121" width="19" height="12" rx="5" fill="#14BED8" />
    <circle
      cx="152.5"
      cy="63.5"
      r="21.6346"
      fill="white"
      stroke="#043041"
      stroke-width="1.73077"
    />
    <path
      d="M144.712 64.8848L150.619 69.3155C151.194 69.7464 152.002 69.6665 152.481 69.1315L162.019 58.481"
      stroke="#0095AB"
      stroke-width="2.76923"
      stroke-linecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_590_3424"
        x1="198.5"
        y1="147"
        x2="198.5"
        y2="156.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.6" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_590_3424"
        x1="153.5"
        y1="4"
        x2="153.5"
        y2="9.7"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.6" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_590_3424"
        x1="21.5"
        y1="87"
        x2="21.5"
        y2="93.9667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.6" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_590_3424"
        x1="177.5"
        y1="162"
        x2="177.5"
        y2="165.167"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.6" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'SuccessAttendEventIcon',
};
</script>

<style scoped></style>
