<template>
  <div class="main">
    <header-control-button
      :title="ucFirst(type)"
      :user="getUser"
      :position="{ position: position }"
      :top="{ top: top + 'px' }"
      @openSidebar="openSidebarMenu"
      class="header-section"
    />
    <div class="slot">
      <div class="content" v-for="article in articles" :key="article.id">
        <router-link
          :to="{ name: 'ArticleDetails', params: { id: article.id } }"
        >
          <div class="content--logo">
            <img :src="article?.image_url" alt="logo_article" />
          </div>
          <div class="content--description">
            <div class="title">
              {{
                getFullContent(
                  article.title,
                  this.MAX_COUNT_SYMBOLS_TO_TITLE,
                  this.SHOW_COUNT_SYMBOLS_TO_FULL_TITLE,
                )
              }}
            </div>
            <div
              class="description preview ql-editor"
              v-html="
                getFullContent(
                  article.content,
                  this.MAX_COUNT_SYMBOLS_TO_OPTION,
                  this.SHOW_COUNT_SYMBOLS_TO_FULL_CONTENT,
                )
              "
            ></div>
          </div>
        </router-link>
      </div>
      <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
    </div>
    <menu-template />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { articlesPWAList, baseErrHandler } from '@/services/api';
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import SlideMenu from '@/components/SlideMenu.vue';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import MenuTemplate from '@/components/MenuTemplate.vue';

export default {
  name: 'ArticlesNewsTemplate',
  components: { MenuTemplate, SlideMenu, HeaderControlButton },
  props: {
    type: {
      type: String,
      default: 'articles',
    },
  },
  inject: ['gtag'],
  data() {
    return {
      position: 'absolute',
      top: '88',
      articles: [],
      countArticles: null,
      isOpen: false,
    };
  },
  async mounted() {
    await this.getArticlesList();
    this.gtag.event('Show news');
  },
  methods: {
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
    ucFirst(str) {
      if (!str) return str;
      return str[0].toUpperCase() + str.slice(1);
    },
    async getArticlesList() {
      await baseErrHandler(async () => {
        const { list, total_count } = await articlesPWAList({
          type: this.type,
        });
        this.articles = list;
        this.countArticles = total_count;
      });
    },
    getFullContent(content = '', maxSymbol = 20, removeSymbol = 20) {
      if (content) {
        return content.length <= maxSymbol
          ? content
          : content.slice(0, removeSymbol) + '...';
      }
    },
  },
  computed: {
    ...mapGetters(['getUser']),
    MAX_COUNT_SYMBOLS_TO_TITLE() {
      return 16;
    },
    SHOW_COUNT_SYMBOLS_TO_FULL_TITLE() {
      return 20;
    },
    MAX_COUNT_SYMBOLS_TO_OPTION() {
      return 60;
    },
    SHOW_COUNT_SYMBOLS_TO_FULL_CONTENT() {
      return 70;
    },
  },
  watch: {
    type() {
      this.getArticlesList();
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins.scss';
@import '@/assets/_variables.scss';

.main {
  background-image: url('@/assets/app_img/article-background.png');
  overflow: hidden;
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-size: cover;
  .header-section {
    padding: 0 25px;
    margin-bottom: 70px;
  }
  .slot {
    padding: 0 25px 100px;
    @include d-flex-column;
    overflow-y: scroll;
    gap: 20px;
    & .content {
      @include d-flex-center;
      gap: 18px;
      border-radius: 10px;
      background: #1a7dae;
      padding: 5px 5px;
      a {
        @include d-flex-center;
        width: 100%;
        gap: 10px;
        text-decoration: none;
      }
      &--logo {
        width: auto;
        height: 122px;
        img {
          width: 122px;
          height: 122px;
          border-radius: 10px;
          background: #d9d9d9;
        }
      }
      &--description {
        height: 100px;
        width: 100%;
        .title {
          font-style: normal;
          font-weight: $fw-700;
          font-size: 18px;
          line-height: 129%;
          color: $color-white;
          text-align: start;
        }

        .description {
          font-style: normal;
          font-weight: $fw-400;
          font-size: 13px;
          line-height: 147.02%;
          color: $color-white;
          text-align: start;
        }
      }
    }
  }
}
//.content:nth-child(2) {
//  margin-top: 45px;
//}

@media only screen and (min-width: 992px) {
  .main {
    background-size: contain;
  }
  .main .slot {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: unset;
  }
  .main .slot .content {
    width: 31%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main {
    background-size: contain;
  }
  .main .slot {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: unset;
  }
  .main .slot .content {
    width: 48%;
  }
}
</style>
