<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8728 11.3095C14.9564 11.9116 16.0407 12.5139 17.1242 13.1161C17.5906 13.3751 17.7952 13.9246 17.6116 14.4258C16.6793 16.9727 13.9488 18.317 11.3797 17.3794C6.11895 15.4592 2.21947 11.5595 0.299094 6.29876C-0.638619 3.72965 0.705805 0.999167 3.25272 0.0669161C3.75388 -0.116705 4.30338 0.0878802 4.56298 0.554288C5.16454 1.63788 5.76682 2.72211 6.36898 3.80564C6.65126 4.31398 6.58491 4.91737 6.19856 5.35145C5.69259 5.92081 5.18672 6.49004 4.68075 7.0588C5.76078 9.68883 7.98957 11.9177 10.6196 12.9977C11.1884 12.4917 11.7576 11.9858 12.327 11.4799C12.7617 11.0935 13.3646 11.0272 13.8728 11.3094L13.8728 11.3095Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'ContactUsIcon',
};
</script>

<style scoped></style>
