<template>
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5899 2.39178L6.20434 9.77684C5.65743 10.3239 4.77025 10.3239 4.22283 9.77684L0.410339 5.96407C-0.13678 5.41706 -0.13678 4.52978 0.410339 3.98266C0.957561 3.43544 1.84467 3.43544 2.39165 3.98246L5.21388 6.80472L11.6083 0.410261C12.1555 -0.136961 13.0427 -0.136546 13.5897 0.410261C14.1367 0.957379 14.1367 1.84435 13.5899 2.39178Z"
      fill="#0095AB"
    />
  </svg>
</template>

<script>
export default {
  name: 'ParagraphIcon',
};
</script>

<style scoped></style>
