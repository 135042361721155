<template>
  <div class="stepper">
    <div class="stepper-progress">
      <div
        class="stepper-progress-bar"
        :style="'width:' + stepperProgress"
      ></div>
    </div>

    <div
      class="stepper-item"
      :class="{ approve: step < item }"
      v-for="item in 4"
      :key="item"
    >
      <div class="stepper-item-counter">
        <span :class="{ 'opacity-25': step < item }" class="number"
          >Step {{ item }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LineApplicationFormCompleted',
  props: {
    step: {
      type: Number,
      default() {
        return 1;
      },
    },
  },
  computed: {
    stepperProgress() {
      return (100 / 3) * (this.step - 1) + '%';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

$default: #d6ecff;
$success: #0058a2;
$green-1: #75cc65;
$transition: all 500ms ease;

.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 0;
  &-progress {
    position: absolute;
    background-color: #d6ecff;
    height: 2px;
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;

    &-bar {
      position: absolute;
      left: 0;
      height: 100%;
      width: 0%;
      background-color: $success;
      transition: $transition;
    }
  }
}

.stepper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $default;
  transition: $transition;

  &-counter {
    height: 11px;
    width: 11px;
    display: grid;
    place-items: center;
    background: $success;
    border-radius: 100%;
    border: 1px solid $success;
    position: relative;

    .icon-success {
      position: absolute;
      opacity: 0;
      transform: scale(0);
      width: 24px;
      transition: $transition;
    }

    .number {
      font-size: 11px;
      position: relative;
      top: 20px;
      left: -15px;
      width: 40px;
      font-weight: 600;
      line-height: 13px;
      text-align: center;
      color: $color-black;
    }
  }
}

.stepper-item.approve {
  .stepper-item-counter {
    border-color: $default;
    background-color: $default;
    color: #fff;
    font-weight: 600;
  }
}

.stepper-item.current {
  background: $success;
  border-radius: 40px;
}
</style>
