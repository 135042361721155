import { MAX_10_MB, isCheckFileType } from '@/helpers/enums';

export const isInValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]{1,40}\.)+[a-zA-Z]{2,6}))$/;
  return !re.test(email);
};

export const isValidFileSize = (fileSize) => {
  return fileSize > MAX_10_MB;
};

export const isValidFileType = (fileExtension) => {
  return isCheckFileType.includes(fileExtension);
};

export const rules = () => {
  return [
    {
      message: 'Password must have at least one small letter.',
      regex: /[a-z]+/,
    },
    {
      message: 'Password must have at least one capital letter.',
      regex: /[A-Z]+/,
    },
    {
      message: 'Password must have at least one digit.',
      regex: /[0-9]/,
    },
    {
      message: 'Password must be at least 8 characters.',
      regex: /.{8,}/,
    },
    {
      message: 'Password must have at least one special character.',
      regex: /(?=.*\W)+/,
    },
  ];
};
