<template>
  <div class="container_page">
    <div class="control-button">
      <back-icon class="control-button__back" @click="$router.go(-1)" />
      <p class="control-button__skip" @click="skipCheckAuthUser">Skip</p>
    </div>
    <div class="login_form_body">
      <div class="welcome_block">
        <div class="title_welcome text-center">Sign In</div>
        <p>Please sign in to continue.</p>
      </div>

      <form action="#" @submit.prevent autocomplete="off" class="form-pwa_usa">
        <div class="form_block">
          <div class="input_block">
            <div class="input_icon">
              <email-icon />
            </div>
            <input
              v-model="formLogin.email"
              type="email"
              autocomplete="off"
              class="form__input"
              placeholder="Email address"
            />
          </div>

          <div class="input_block">
            <div class="input_icon">
              <password-lock />
            </div>
            <input
              v-model="formLogin.password"
              :type="view_password ? 'text' : 'password'"
              autocomplete="off"
              class="form__input"
              placeholder="Password"
            />
            <div class="show_pass" @click="showPassword">
              <eye-icon v-if="!view_password" />
              <eye-open-icon v-else />
            </div>
          </div>
        </div>
        <div class="press_here">
          <span @click="goToForgotPassword">Forgot password? </span>
        </div>
      </form>

      <div class="sign-and-social">
        <div class="btn">
          <button class="btn__sign-in" @click="logIn">Sign In</button>
        </div>

        <div class="or_row d-none">or</div>

        <div class="social-button d-none">
          <button class="social-button__google" @click="authWith('google')">
            <div class="social-button__google--title">
              <google-auth-icon />
              <p>Google</p>
            </div>
          </button>
          <button class="social-button__apple" @click="authWith('apple')">
            <div class="social-button__apple--title">
              <apple-auth-icon />
              <p>Apple</p>
            </div>
          </button>
        </div>
      </div>

      <div class="register-block">
        Don’t have an account?
        <span class="register-block__link" @click="goToRegister">Register</span>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordLock from '@/components/svg/PasswordLock.vue';
import BackIcon from '@/components/svg/BackIcon.vue';
import { isInValidEmail } from '@/helpers/validate.helper';
import EmailIcon from '@/components/svg/EmailIcon.vue';
import EyeIcon from '@/components/svg/EyeCloseIcon.vue';
import EyeOpenIcon from '@/components/svg/EyeOpenIcon.vue';
import { baseErrHandler, loginPWA } from '@/services/api';
import { pwa_jwt_key } from '@/helpers/enums';
import { mapGetters, mapMutations } from 'vuex';
import { errorMessage } from '@/helpers/messages';
import GoogleAuthIcon from '@/components/svg/GoogleAuthIcon.vue';
import AppleAuthIcon from '@/components/svg/AppleAuthIcon.vue';

export default {
  name: 'LoginTemplate',
  components: {
    AppleAuthIcon,
    GoogleAuthIcon,
    EyeOpenIcon,
    EyeIcon,
    EmailIcon,
    PasswordLock,
    BackIcon,
  },
  inject: ['gtag'],
  async mounted() {
    await this.redirectAuthUser();
  },
  data() {
    return {
      formLogin: {
        email: '',
        password: '',
      },
      view_password: false,
    };
  },
  methods: {
    ...mapMutations(['setUser']),
    handleBuy(user) {
      const { name = '', surname = '' } = user;
      this.gtag.event('login-user', {
        'event-label': 'new-login-user',
        value: `${name} ${surname}`,
      });
    },
    async redirectAuthUser() {
      if (this.isLogged) {
        await this.skipCheckAuthUser();
      }
    },
    async skipCheckAuthUser() {
      await this.$router.push('/dashboard');
    },
    async goToForgotPassword() {
      await this.$router.push('/auth/forgot-password');
    },
    async goToRegister() {
      await this.$router.push('/auth/registration');
    },
    showPassword() {
      this.view_password = !this.view_password;
    },
    async logIn() {
      if (!this.isValidFormRegistration()) {
        return;
      }
      await baseErrHandler(async () => {
        const { user, token } = await loginPWA(this.formLogin);
        if (user && token) {
          localStorage.setItem(pwa_jwt_key, token);
          this.setUser(user);
          this.handleBuy(user);
          return await this.$router.push('/dashboard');
        }
      });
    },
    isValidFormRegistration() {
      let is_valid = true;
      if (!this.formLogin.email || !this.formLogin.password) {
        return errorMessage('Please fill in all required fields.');
      }
      if (this.formLogin.email || this.formLogin.password) {
        if (isInValidEmail(this.formLogin.email)) {
          return errorMessage('Incorrect email or password. Please, try again');
        }
        if (this.formLogin.password.length < 6) {
          return errorMessage('Minimum password length is 6 characters.');
        }
      }
      return is_valid;
    },
    clearForm() {
      this.formLogin.email = '';
      this.formLogin.password = '';
    },
  },
  computed: {
    ...mapGetters(['isLogged']),
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/_variables.scss';
@import '../../assets/mixins.scss';

.control-button {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  color: white;
  height: 80px;
}
.control-button__skip,
.control-button__back {
  cursor: pointer;
}
.container_page {
  @include container;
  min-height: 100vh;
  background-size: cover;
  background-image: url('../../assets/app_img/sign-in-background.png');
  .login_form_body {
    min-height: 100vh;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .control-button {
      &__skip {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 147.02%;
        color: $color-white;
        margin: 0;
        height: 40px;
      }
    }
    .welcome_block {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .welcome_block > div {
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      color: $color-white;
    }
    .welcome_block > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 147.02%;
      text-align: center;
      opacity: 0.4;
      color: $color-white;
    }
    .form-pwa_usa {
      margin-bottom: 10px;
    }
    .form_block {
      .input_block {
        width: 100%;
        position: relative;
        margin-bottom: 17px;

        .form-label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #222626;
          margin-bottom: 4px;
        }

        input {
          padding-left: 40px;
          width: 100%;
          height: 58px;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.18);
          border-radius: 17px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 147.02%;
          color: $color-white;
          text-align: start;

          &::placeholder {
            text-align: start;
            font-weight: 500;
            font-size: 16px;
            line-height: 147.02%;
            color: $color-white;
          }
        }

        .input_icon {
          position: absolute;
          left: 16px;
          top: 16px;
          min-width: 16px;
        }

        .show_pass {
          position: absolute;
          right: 16px;
          top: 16px;
          min-width: 16px;
        }
      }
    }

    .press_here {
      font-weight: 500;
      font-size: 14px;
      line-height: 147.02%;
      text-align: right;
      color: $color-white;
      span {
        cursor: pointer;
        color: $color-white;
      }
    }
    .sign-and-social {
      position: relative;
      top: -30px;
      display: flex;
      gap: 15px;
      flex-direction: column;
      .btn {
        width: 100%;
        padding: 0;
        &__sign-in {
          width: 100%;
          height: 58px;
          border-radius: 15px;
          text-align: center;
          align-items: center;
          display: flex;
          justify-content: center;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #0058a2;
          border: none;
          background: $color-white;
        }
      }
      .or_row {
        font-weight: 400;
        font-size: 16px;
        line-height: 147.02%;
        color: $color-white;
        opacity: 0.8;
        text-transform: uppercase;
        text-align: center;
      }
      .social-button {
        display: flex;
        gap: 8px;

        &__google,
        &__apple {
          height: 58px;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 13px;
          width: 50%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          &--title {
            display: flex;
            align-items: center;
            gap: 7px;
            color: $color-white;
            p {
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              margin: 0;
            }
          }
        }
      }
    }
    .register-block {
      line-height: 147.02%;
      text-align: center;
      color: $color-white;
      opacity: 0.8;
      position: relative;
      bottom: 30px;
      &__link {
        opacity: 1;
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .container_page {
    background-size: contain;
    width: 100vw;
    height: 100vh;
  }
  .container_page .login_form_body {
    min-height: 100vh;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
    gap: 80px;
  }
  .container_page .login_form_body .welcome_block > p {
    width: 100%;
  }
  .c-slideout__container {
    width: 30% !important;
  }
}

@media only screen and (max-width: 992px) {
  .container_page {
    background-size: contain;
  }
  .container_page .login_form_body {
    min-height: 100vh;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 540px;
    margin: 0 auto;
    gap: 80px;
  }
  .container_page .login_form_body .welcome_block > p {
    max-width: 400px;
    margin: 0 auto;
  }
  .c-slideout__container {
    width: 45% !important;
  }
}

@media only screen and (max-width: 768px) {
  .container_page {
    background-size: contain;
  }
  .container_page .login_form_body {
    max-width: 440px;
  }
  .container_page .login_form_body .welcome_block > p {
    max-width: 300px;
  }
}

@media only screen and (max-width: 480px) {
  .container_page {
    background-size: cover;
  }
}
</style>
