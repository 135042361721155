<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.75 13.5C15.3637 13.5 15.0055 13.6067 14.688 13.7789L13.5161 12.607C14.3087 11.6171 14.7857 10.364 14.7857 9C14.7857 7.63578 14.3087 6.38294 13.5161 5.39297L14.688 4.22106C15.0055 4.3934 15.3643 4.50001 15.75 4.50001C16.9906 4.50001 18 3.49065 18 2.25001C18 1.00936 16.9906 0 15.75 0C14.5093 0 13.5 1.00936 13.5 2.25001C13.5 2.63631 13.6067 2.99448 13.7789 3.31201L12.607 4.48392C11.6171 3.69126 10.364 3.2143 9 3.2143C7.63578 3.2143 6.38294 3.69126 5.39297 4.48392L4.22106 3.31201C4.3934 2.99446 4.50001 2.63572 4.50001 2.25001C4.50001 1.00936 3.49065 0 2.25001 0C1.00936 0 0 1.00936 0 2.25001C0 3.49065 1.00936 4.50001 2.25001 4.50001C2.63631 4.50001 2.99448 4.39326 3.31201 4.22106L4.48392 5.39297C3.69126 6.38294 3.2143 7.63596 3.2143 9C3.2143 10.3642 3.69126 11.6171 4.48392 12.607L3.31201 13.7789C2.99446 13.6066 2.63572 13.5 2.25001 13.5C1.00936 13.5 0 14.5093 0 15.75C0 16.9906 1.00936 18 2.25001 18C3.49065 18 4.50001 16.9906 4.50001 15.75C4.50001 15.3637 4.39326 15.0055 4.22106 14.688L5.39297 13.5161C6.38294 14.3087 7.63596 14.7857 9 14.7857C10.3642 14.7857 11.6171 14.3087 12.607 13.5161L13.7789 14.688C13.6066 15.0055 13.5 15.3643 13.5 15.75C13.5 16.9906 14.5093 18 15.75 18C16.9906 18 18 16.9906 18 15.75C18 14.5093 16.9906 13.5 15.75 13.5ZM8.99997 5.78567C10.0633 5.78567 10.9285 6.65096 10.9285 7.71425C10.9285 8.77754 10.0633 9.64283 8.99997 9.64283C7.93668 9.64283 7.07139 8.77754 7.07139 7.71425C7.07139 6.65096 7.93668 5.78567 8.99997 5.78567ZM11.2422 12.8957C10.7916 12.0864 9.93913 11.5714 8.99997 11.5714C8.06081 11.5714 7.2083 12.0862 6.75771 12.8957C6.37846 12.6764 6.03507 12.4039 5.7356 12.0883C6.43441 10.978 7.66161 10.2857 8.99997 10.2857C10.3383 10.2857 11.5649 10.9781 12.2643 12.0883C11.9654 12.4045 11.6215 12.6765 11.2422 12.8957ZM8.35711 7.71425C8.35711 7.36011 8.64582 7.07139 8.99997 7.07139C9.35412 7.07139 9.64283 7.36011 9.64283 7.71425C9.64283 8.0684 9.35412 8.35711 8.99997 8.35711C8.64582 8.35711 8.35711 8.0684 8.35711 7.71425Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'AffiliationIcon',
};
</script>

<style scoped></style>
