<template>
  <parent-component>
    <div class="partners">
      <div
        class="item d-flex align-items-center justify-content-between flex-row flex-wrap"
      >
        <div
          v-for="(partner, i) in pages"
          :key="i"
          class="d-flex align-center block"
        >
          <img :src="partner.image_url" alt="logo" />
          <p v-if="partner?.title" class="title">{{ partner?.title }}</p>
        </div>
      </div>
    </div>
  </parent-component>
</template>

<script>
import ParentComponent from '@/components/ParentComponent.vue';
import { baseErrHandler, loadPageInform } from '@/services/api';

export default {
  name: 'PartnersTemplate',
  components: { ParentComponent },
  created() {
    this.getPartnersData();
  },
  data() {
    return {
      pages: [],
    };
  },
  methods: {
    async getPartnersData() {
      await baseErrHandler(async () => {
        const { page } = await loadPageInform({ pageName: 'partners' });
        this.pages = page?.blocks[0]?.images || [];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins.scss';

.partners {
  @include d-flex-column;
  gap: 30px;
  padding-top: 40px;

  .item {
    min-height: 75px;
  }
  .block {
    width: 150px;
    flex-direction: column;
    margin-bottom: 20px;
    justify-content: center;
    gap: 10px;
    height: 100%;
  }

  .title {
    margin: 0;
    text-align: center;
  }
}

@media only screen and (min-width: 992px) {
  .partners .item {
    min-height: 75px;
    width: 900px;
    margin: 0 auto;
  }
  .partners .block {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .partners .item {
    min-height: 75px;
    width: 700px;
    margin: 0 auto;
  }
  .partners .block {
    width: 160px;
  }
}

@media only screen and (max-width: 320px) {
  .partners {
    .block {
      width: 135px;
    }
  }
}
</style>
