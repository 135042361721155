<template>
  <form
    action="#"
    @submit.prevent
    autocomplete="off"
    class="form-pwa"
    id="user_form"
  >
    <div class="form_block">
      <div
        class="input_block"
        :class="{
          'align-items-baseline': blockedForm,
        }"
      >
        <label for="inputFirstName" class="form-label">First Name:</label>
        <input
          v-model="name"
          @focus="onTheWayInputError"
          type="text"
          id="inputFirstName"
          ref="name"
          autocomplete="off"
          class="form__input"
          :class="[
            { 'is-invalid': validForm.name },
            { 'border-0': blockedForm },
            { 'p-0': blockedForm },
          ]"
          :disabled="blockedForm"
          placeholder="Enter first name"
        />
        <div ref="error_name" class="invalid-feedback">
          {{ validForm.name }}
        </div>
      </div>
      <div
        v-show="getUser?.middlename || !blockedForm"
        class="input_block"
        :class="{
          'align-items-baseline': blockedForm,
        }"
      >
        <label for="inputMiddleName" class="form-label">Middle Name:</label>
        <input
          v-model="middlename"
          type="text"
          id="inputMiddleName"
          ref="middlename"
          autocomplete="off"
          class="form__input"
          placeholder="Middle name"
          :disabled="blockedForm"
          :class="[{ 'border-0': blockedForm }, { 'p-0': blockedForm }]"
        />
      </div>
      <div
        class="input_block"
        :class="{
          'align-items-baseline': blockedForm,
        }"
      >
        <label for="inputSurName" class="form-label">Surname:</label>
        <input
          v-model="surname"
          @focus="onTheWayInputError"
          type="text"
          id="inputSurName"
          ref="surname"
          autocomplete="off"
          class="form__input"
          :class="[
            { 'is-invalid': validForm.surname },
            { 'border-0': blockedForm },
            { 'p-0': blockedForm },
          ]"
          placeholder="Surname"
          :disabled="blockedForm"
        />
        <div ref="error_surname" class="invalid-feedback">
          {{ validForm.surname }}
        </div>
      </div>
      <div
        class="input_block"
        :class="{
          'align-items-baseline': blockedForm,
        }"
      >
        <label for="inputEmail" class="form-label">Email Address:</label>
        <input
          v-model="email"
          @focus="onTheWayInputError"
          type="email"
          id="inputEmail"
          ref="email"
          autocomplete="off"
          class="form__input"
          :class="[
            { 'is-invalid': validForm.email },
            { 'border-0': blockedForm },
            { 'p-0': blockedForm },
          ]"
          placeholder="Enter email here"
          :disabled="blockedForm"
        />
        <div ref="error_email" class="invalid-feedback">
          {{ validForm.email }}
        </div>
      </div>
      <!--      <div class="input_block">-->
      <!--        <label for="inputIdCard" class="form-label">ID/Passport number:</label>-->
      <!--        <input-->
      <!--          v-model="id_card"-->
      <!--          @focus="onTheWayInputError"-->
      <!--          type="text"-->
      <!--          id="inputIdCard"-->
      <!--          ref="id_card"-->
      <!--          autocomplete="off"-->
      <!--          class="form__input"-->
      <!--          :class="{ 'is-invalid': validForm.id_card }"-->
      <!--          placeholder="ID number"-->
      <!--        />-->
      <!--        <div ref="error_idcard" class="invalid-feedback">-->
      <!--          {{ validForm.id_card }}-->
      <!--        </div>-->
      <!--      </div>-->
      <div
        class="input_block"
        :class="{
          'align-items-baseline': blockedForm,
        }"
      >
        <label for="inputPhone" class="form-label">Contact Number:</label>
        <input
          v-model="phone"
          @focus="onTheWayInputError"
          type="text"
          autocomplete="off"
          id="inputPhone"
          ref="phone"
          class="form__input"
          :class="[
            { 'is-invalid': validForm.phone },
            { 'border-0': blockedForm },
            { 'p-0': blockedForm },
          ]"
          placeholder="Add number"
          :disabled="blockedForm"
        />
        <div ref="error_phone" class="invalid-feedback">
          {{ validForm.phone }}
        </div>
      </div>
      <div class="input_block" v-if="!blockedForm">
        <label for="inputCountry" class="form-label text-in-form"
          >Country:</label
        >
        <VueSelect
          v-model="country"
          class="style-chooser form-control-vs"
          :options="countries"
          label="country"
          id="inputCountry"
          placeholder="Select Country"
        >
          <template v-slot:option="option">
            <country-flag :country="option.abbreviation" />
            {{ option.country }}
          </template>
          <template v-slot:selected-option="option">
            <country-flag :country="option.abbreviation" />
            {{ option.country }}
          </template>
        </VueSelect>
      </div>
      <div v-if="blockedForm" class="input_block d-flex align-items-baseline">
        <label for="country_show" class="form-label">Country:</label>
        <input
          :value="getUser?.country"
          @focus="onTheWayInputError"
          type="text"
          autocomplete="off"
          id="country_show"
          class="form__input border-0"
          :class="{ 'p-0': blockedForm }"
          disabled
        />
      </div>

      <div class="input_block" v-if="!blockedForm">
        <label for="inputState" class="form-label text-in-form"
          >City / Town:</label
        >
        <VueSelect
          class="style-chooser form-control-vs vs-state"
          :class="{ 'p-0': blockedForm }"
          :options="statesCountry"
          :disabled="!statesCountry.length"
          v-model="state"
          id="inputState"
          label="country"
          placeholder="Select City"
        />
      </div>

      <div v-if="blockedForm" class="input_block d-flex align-items-baseline">
        <label for="country_show" class="form-label">City:</label>
        <input
          :value="getUser?.state"
          @focus="onTheWayInputError"
          type="text"
          autocomplete="off"
          id="country_show"
          class="form__input border-0"
          :class="{ 'p-0': blockedForm }"
          disabled
        />
      </div>

      <div
        class="input_block"
        :class="{ 'mb-4': !getUser?.highest_qualification && blockedForm }"
      >
        <label for="inputBio" class="form-label text-in-form"
          >More About Me (Bio)</label
        >
        <textarea
          v-if="!blockedForm"
          type="text"
          id="inputBio"
          ref="bio"
          @focus="onTheWayInputError"
          class="form__input"
          :class="{ 'is-invalid': isError }"
          placeholder=""
          v-model="bio"
        ></textarea>

        <textarea
          v-show="blockedForm"
          type="text"
          id="inputBio"
          ref="bio"
          @focus="onTheWayInputError"
          class="form__input"
          :class="[{ 'is-invalid': isError }, { 'd-none': !bio?.length }]"
          placeholder=""
          v-model="bio"
          disabled
        ></textarea>
      </div>
      <div
        v-show="getUser?.highest_qualification || !blockedForm"
        class="input_block mb-4"
      >
        <label for="inputPhone" class="form-label"
          >Highest Qualification:</label
        >
        <input
          v-model="highest_qualification"
          @focus="onTheWayInputError"
          type="text"
          autocomplete="off"
          id="inputQualification"
          class="form__input"
          placeholder="Highest Qualification"
          :disabled="blockedForm"
          :class="[{ 'border-0': blockedForm }, { 'p-0': blockedForm }]"
        />
      </div>
      <div class="input_checked" v-show="!blockedForm">
        <input
          type="checkbox"
          v-model="agreement"
          class="form__input"
          name="agreement"
          id="inputAgreement"
        />
        <label for="inputAgreement"
          >By pressing the Register button, you confirm that you have read and
          accept the
          <a :href="`${publicPath}privacy`" class="terms-condition__link"
            >Group Privacy Notice</a
          ></label
        >
      </div>
      <div class="confirm" v-show="!blockedForm">
        <button
          @click="saveChange"
          :disabled="!agreement"
          :class="{
            disabled: !agreement,
          }"
          type="button"
          class="btn btn-enter"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import VueSelect from 'vue-select';
import CountryFlag from 'vue-country-flag-next';
import getCountries from '@/store/countries.json';
import { errorMessage, successMessage } from '@/helpers/messages';
import { isInValidEmail } from '@/helpers/validate.helper';
import { baseErrHandler, updateUserProfile } from '@/services/api';
import {
  base_url,
  incorrect_email_error,
  max_size_description_error,
  pwa_jwt_key,
  require_input_error,
} from '@/helpers/enums';

export default {
  name: 'UserProfileTemplate',
  components: {
    VueSelect,
    CountryFlag,
  },
  emits: ['blocked-form'],
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    blockedForm: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      showEdit: false,
      photo: null,
      name: null,
      middlename: null,
      surname: null,
      email: null,
      // id_card: null,
      phone: null,
      country: {
        country: {},
      },
      state: null,
      bio: null,
      highest_qualification: null,
      agreement: true,
      showSidebar: false,
      isOpen: false,
      countries: getCountries,
      queryData: {
        name: null,
        middlename: null,
        surname: null,
        email: null,
        // id_card: null,
        phone: null,
        state: null,
        country: null,
        bio: null,
        highest_qualification: null,
      },
      validForm: {
        name: null,
        surname: null,
        email: null,
        // id_card: null,
        phone: null,
        bio: null,
      },
      isError: false,
    };
  },
  methods: {
    ...mapMutations(['setUser']),
    isValidForm() {
      let is_valid = true;
      if (!this.name) {
        this.validForm.name = require_input_error;
        errorMessage(require_input_error);
        is_valid = false;
      }
      if (!this.surname) {
        this.validForm.surname = require_input_error;
        errorMessage(require_input_error);
        is_valid = false;
      }
      if (!this.email) {
        this.validForm.email = require_input_error;
        errorMessage(require_input_error);
        is_valid = false;
      }
      // if (!this.id_card) {
      //   this.validForm.id_card = require_input_error;
      //   errorMessage(require_input_error);
      //   is_valid = false;
      // }
      if (!this.phone) {
        this.validForm.phone = require_input_error;
        errorMessage(require_input_error);
        is_valid = false;
      }
      if (this.email) {
        if (isInValidEmail(this.email)) {
          this.validForm.email = incorrect_email_error;
          errorMessage(incorrect_email_error);
          is_valid = false;
        }
      }
      if (this.bio && this.bio?.length > 600) {
        this.validForm.bio = max_size_description_error;
        errorMessage(max_size_description_error);
        is_valid = false;
      }
      return is_valid;
    },
    onTheWayInputError() {
      this.clearValidForm();
    },
    async saveChange() {
      if (!this.isValidForm()) {
        return;
      }
      this.queryData.name = this.name;
      this.queryData.middlename = this.middlename || null;
      this.queryData.surname = this.surname;
      this.queryData.email = this.email;
      // this.queryData.id_card = this.id_card;
      this.queryData.phone = this.phone;
      this.queryData.state = this.state || null;
      this.queryData.bio = this.bio || null;
      this.queryData.highest_qualification = this.highest_qualification || null;
      if (this.country !== null && this.country !== undefined) {
        this.queryData.country = this.country.country;
      }
      await baseErrHandler(async () => {
        const { token, user } = await updateUserProfile(this.queryData);
        if (user && token) {
          localStorage.setItem(pwa_jwt_key, token);
          this.setUser(user);
          successMessage('Success! You profile has been updated.');
          this.$emit('blocked-form', true);
        }
        this.clearQueryData();
      });
    },
    clearQueryData() {
      this.queryData.name = null;
      this.queryData.middlename = null;
      this.queryData.surname = null;
      this.queryData.email = null;
      // this.queryData.id_card = null;
      this.queryData.phone = null;
      this.queryData.state = null;
      this.queryData.bio = null;
      this.queryData.country = null;
      this.queryData.highest_qualification = null;
    },
    formInit(value) {
      this.name = value?.name || null;
      this.middlename = value?.middlename || null;
      this.surname = value?.surname || null;
      this.email = value?.email || null;
      // this.id_card = value?.id_card || null;
      this.phone = value?.phone || null;
      this.bio = value?.bio || null;
      this.highest_qualification = value?.highest_qualification || null;

      this.country = this.countries.find((c) => c.country === value.country);
      this.$nextTick(() => {
        this.state = value?.state || null;
      });
    },
    clearValidForm() {
      this.validForm.name = null;
      this.validForm.surname = null;
      this.validForm.email = null;
      // this.validForm.id_card = null;
      this.validForm.phone = null;
    },
    async toBlob(img) {
      return fetch(img).then((res) => res.blob());
    },
  },
  watch: {
    user(value) {
      if (value) {
        this.formInit(value);
      }
    },
    country(new_val, old) {
      if (new_val !== old) {
        this.state = null;
      }
    },
  },
  computed: {
    ...mapGetters(['getUser']),
    statesCountry() {
      return this.country?.states || [];
    },
    publicPath() {
      return base_url;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';

.is-invalid {
  border: 1px solid #f64e4e !important;
}
.invalid-feedback {
  color: #f64e4e;
}

@media only screen and (min-width: 768px) {
  .form_block .input_checked {
    gap: 20px;
  }
}

.form_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 33px;
  & .input_block {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    & label {
      text-align: start;
      font-weight: 600;
      font-size: 12px;
      line-height: 147.02%;
      color: #bababa;
    }
    & input {
      height: 47px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.18);
      border-radius: 10px;
      padding-left: 14px;
      color: $color-black;
      &::placeholder {
        font-weight: 600;
        font-size: 12px;
        line-height: 147.02%;
      }
    }
  }

  & .input_checked {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 4px;
    & input[type='checkbox'] {
      min-width: 20px;
      height: 20px;
      background: #0070bb;
      border-radius: 3px;
      margin-top: 5px;
    }
  }

  .confirm {
    width: 100%;
    height: 58px;
    margin-bottom: 63px;

    .disabled {
      opacity: 0.6;
    }

    button {
      width: 100%;
      height: 58px;
      background: #0058a2;
      border-radius: 15px;
      border: none;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  textarea {
    height: 133px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 10px;
    padding: 10px;
  }

  .terms-condition {
    font-weight: $fw-400;
    font-size: 12px;
    line-height: 147.02%;
    text-align: center;
    color: rgba(255, 255, 255, 0.53);
    &__link {
      font-weight: $fw-700;
      color: #2e6d93;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.vs__dropdown-toggle {
  height: 47px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(0, 0, 0, 0.18) !important;
  border-radius: 10px !important;
}
.form-control-vs {
  --vs-search-input-placeholder-color: #bababa;
  --vs-font-size: 12px;
  --vs-line-height: 147.02%;
  --vs-dropdown-max-height: 200px;
}
.vs__selected-options {
  z-index: 0;
}
.vs__selected {
  gap: 5px;
  span:first-child {
    margin-top: -10px;
  }
}

@media only screen and (min-width: 768px) {
  #user_form {
    width: 700px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  #user_form {
    width: calc(100% - 80px);
  }
}
</style>
