<template>
  <svg
    width="20"
    height="13"
    viewBox="0 0 20 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.209105 5.92662C-0.0697017 6.2054 -0.0697017 6.65746 0.209105 6.93624L3.41739 10.1445C7.04179 13.769 12.9182 13.769 16.5426 10.1445L19.7509 6.93624C20.0297 6.65746 20.0297 6.2054 19.7509 5.92662L16.5426 2.71833C12.9182 -0.906108 7.04179 -0.906115 3.41739 2.71833L0.209105 5.92662ZM4.42702 9.1349L1.72356 6.43143L4.42703 3.72796C7.49384 0.661131 12.4662 0.661131 15.533 3.72796L18.2365 6.43143L15.533 9.1349C12.4662 12.2018 7.49384 12.2018 4.42702 9.1349ZM7.82339 6.43143C7.82339 5.24854 8.78225 4.28967 9.96515 4.28967C11.148 4.28967 12.1069 5.24854 12.1069 6.43143C12.1069 7.61432 11.148 8.57319 9.96515 8.57319C8.78225 8.57319 7.82339 7.61432 7.82339 6.43143ZM9.96515 2.86183C7.99373 2.86183 6.39555 4.46001 6.39555 6.43143C6.39555 8.40285 7.99373 10.001 9.96515 10.001C11.9366 10.001 13.5347 8.40285 13.5347 6.43143C13.5347 4.46001 11.9366 2.86183 9.96515 2.86183Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'EyeIcon',
};
</script>

<style scoped></style>
