<template>
  <div class="main">
    <div class="slot">
      <div class="button-back">
        <header-control-button
          :is-visible-link-back-to-event-page="true"
          :title="title"
          @openSidebar="openSidebarMenu"
          class="control_btn"
        />
      </div>
      <div class="dynamic-component">
        <button
          v-for="tab in tabs"
          :key="tab"
          class="tab-button"
          :class="{
            active: currentTab === tab.split(' ').join(''),
          }"
          @click="getInfoForCurrentTab(tab.split(' ').join(''))"
        >
          {{ tab }}
        </button>
      </div>

      <div v-show="currentTab === 'RecentEvents'">
        <recent-events :data="recentEventLists" :type="`recent`" />
      </div>
      <div v-show="currentTab === 'UpcomingEvents'">
        <upcoming-events
          ref="upcoming"
          :data="upcomingListsEvent"
          :calendar-dates="calendarDates"
          :type="`upcoming`"
          :date="request"
          @item-month="monthChangedHandler"
          @item-day="dayChangeHandler"
        />
      </div>
    </div>
    <menu-template />
    <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
  </div>
</template>

<script>
import MenuTemplate from '@/components/MenuTemplate.vue';
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import SlideMenu from '@/components/SlideMenu.vue';
import { mapGetters } from 'vuex';
import {
  baseErrHandler,
  eventsPWAList,
  recentEventsPWAList,
} from '@/services/api';
import RecentEvents from '@/components/events/RecentEvents.vue';
import { startOfMonth, formatFullDate } from '@/helpers/tools';
import UpcomingEvents from '@/components/events/UpcomingEvents.vue';
import { addMonths, startOfMonth as startOfMonthFNS } from 'date-fns';

export default {
  name: 'EventCalendarView',
  components: {
    UpcomingEvents,
    SlideMenu,
    HeaderControlButton,
    MenuTemplate,
    RecentEvents,
  },
  inject: ['gtag'],
  mounted() {
    this.loadTabData();
    this.scrollEventHandler();
    this.gtag.event('Show event calendar');
  },
  beforeUnmount() {
    document.removeEventListener('scroll', this.scrollEventHandler);
  },
  data() {
    return {
      title: 'Event Calendar',
      isOpen: false,
      recent_events: [],
      upcoming_events: [],
      calendarDates: [],
      events_total: null,
      currentTab: 'RecentEvents',
      tabs: ['Recent Events', 'Upcoming Events'],
      request: {
        date_from: startOfMonth(
          new Date().getFullYear(),
          new Date().getMonth(),
        ),
        date_to: formatFullDate(addMonths(startOfMonthFNS(new Date()), 1)),
      },
    };
  },
  methods: {
    async clearQueryParamThisRoute() {
      const router = this.$route;
      const { query, path } = router;
      const newQuery = Object.assign({}, query, {});
      await this.$router.push({ path, query: newQuery });
    },
    changeSelectDayCurrentMonth() {
      const { date_from } = this.request;
      const firstDayCurrentMonth = formatFullDate(
        new Date(
          new Date(date_from).getFullYear(),
          new Date(date_from).getMonth(),
          1,
        ),
      );

      if (date_from !== firstDayCurrentMonth) {
        setTimeout(() => {
          this.$refs.upcoming?.setOldFilterDateForCalendar(date_from);
        }, 300);
      }
    },
    async loadTabData() {
      if (this.currentTab === 'RecentEvents') {
        // await this.clearQueryParamThisRoute();
        await this.setNewParamForRoute(this.request);
        await this.recentEventList();
      }
      if (this.currentTab === 'UpcomingEvents') {
        this.changeSelectDayCurrentMonth();
        await this.upcomingEventList();
      }
    },
    async scrollEventHandler() {
      document.addEventListener('scroll', () => {
        const scrollTop = document.documentElement.scrollTop;
        const viewportHeight = window.innerHeight;
        const totalHeight = document.documentElement.offsetHeight;

        const atTheBottom = scrollTop + viewportHeight === totalHeight;
        if (atTheBottom && this.recent_events.length < this.events_total) {
          this.recentEventList(this.recent_events?.length);
        }
      });
    },
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
    async getInfoForCurrentTab(activeTab) {
      this.currentTab = activeTab;
    },
    monthChangedHandler: async function (date) {
      if (!date) {
        return;
      }
      const { date_from, date_to } = date;
      const { date_from: request_date_from, date_to: request_date_to } =
        this.request;

      if (request_date_from !== date_from || request_date_to !== date_to) {
        this.request = date;
        await this.upcomingEventList();
      }

      const router = this.$route;
      const { query } = router;
      const { date_from: date_from_query, date_to: date_to_query } = query;
      if (date_from_query !== undefined && date_to_query !== undefined) {
        this.request.date_from = date_from_query;
        this.request.date_to = date_to_query;
        setTimeout(() => {
          this.$refs.upcoming?.setOldFilterMonthForCalendar(date_from_query);
        }, 400);
      }
    },
    async dayChangeHandler(date) {
      this.request.date_to = null;
      this.request.date_from = date;
    },
    async recentEventList(offset = 0) {
      const previousDay = formatFullDate(
        new Date(new Date().setDate(new Date().getDate() - 1)),
      );
      await baseErrHandler(async () => {
        const { list, total_count } = await recentEventsPWAList({
          offset: offset,
          date_to: previousDay,
        });

        if (
          !this.isEqualsArrayOfNumbers(
            list.map((el) => el.id),
            this.recent_events.map((el) => el.id),
          )
        ) {
          this.recent_events = this.recent_events.concat(list);
          this.events_total = total_count;
        }
      });
    },
    async upcomingEventList() {
      await baseErrHandler(async () => {
        const { dates, list } = await eventsPWAList(this.request);
        if (
          !this.isEqualsArrayOfNumbers(
            list.map((el) => el.id),
            this.upcoming_events.map((el) => el.id),
          )
        ) {
          this.calendarDates = dates;
          this.upcoming_events = list;
        }
      });
    },
    isEqualsArrayOfNumbers(arr1, arr2) {
      for (let i = 0; i < arr1.length; i++) {
        if (!arr2.includes(arr1[i])) {
          return false;
        }
      }
      return true;
    },
    async setNewParamForRoute(date) {
      if (!date) {
        return;
      }
      const router = this.$route;
      const { query, path } = router;
      const { date_from, date_to } = date;
      if (date.date_to !== null) {
        const newQuery = Object.assign({}, query, { date_from, date_to });
        await this.$router.push({ path, query: newQuery });
      } else {
        const newQuery = Object.assign({}, query, { date_from });
        delete newQuery.date_to;
        await this.$router.push({ path, query: newQuery });
      }
    },
    currentDate(filterDate) {
      return formatFullDate(new Date(filterDate));
    },
  },
  computed: {
    ...mapGetters(['getUser']),
    recentEventLists() {
      return this.recent_events || [];
    },
    upcomingListsEvent() {
      const { date_from, date_to } = this.request;
      if (date_from && !date_to) {
        return (
          this.upcoming_events.filter(
            (ev) => ev.date_from <= date_from && ev.date_to >= date_from,
          ) || []
        );
      }

      // if (false && date_from && date_to) {
      //   console.log('if (date_from && date_to)');
      //   return (
      //     this.upcoming_events.filter(
      //       (ev) => ev.date_from <= date_from && ev.date_to <= date_to,
      //     ) || []
      //   );
      // }

      return this.upcoming_events.filter(
        (event) =>
          (event.date_from < this.currentDate(date_from) &&
            event.date_to >= this.currentDate(date_from)) ||
          event.date_from >= this.currentDate(date_from),
      );
    },
  },
  watch: {
    currentTab() {
      this.loadTabData();
    },
    async ['request.date_from']() {
      await this.setNewParamForRoute(this.request);
      await this.upcomingListsEvent;
    },
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (from.query.type === 'recent') {
        vm.currentTab = 'RecentEvents';
      }
      if (from.query.type === 'upcoming' || to.query.type === 'upcoming') {
        const { date_from, date_to } = to.query;
        vm.currentTab = 'UpcomingEvents';
        /* Redirect to current tab - date calendar filter selection */
        if (date_from && !date_to) {
          setTimeout(() => {
            vm.$refs.upcoming?.setOldFilterDateForCalendar(date_from);
          }, 300);
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.main::-webkit-scrollbar,
.slot::-webkit-scrollbar {
  display: none;
}

.main {
  background: #0074a6;
  position: relative;
  height: 100%;
  width: 100vw;
  .slot {
    min-height: 100vh;
    overflow-y: auto;
    @include d-flex-column;
    padding-bottom: 80px;
    .button-back {
      padding: 0 25px;
    }
    .event-control {
      @include d-flex-row-around;
      margin-top: 30px;
    }
    .event-tasks {
      @include d-flex-column-center;
      gap: 20px;
      background: $color-white;
      border-radius: 25px 25px 0 0;
      padding: 0 25px 25px;
    }
    .dynamic-component {
      display: flex;
      justify-content: space-between;
      background: inherit;
      padding: 0 35px;

      .tab-button,
      .active {
        @include font-Inter;
        font-weight: $fw-400;
        font-size: 16px;
        line-height: 19px;
        color: $color-white;
        opacity: 0.7;
        background: inherit;
        padding-bottom: 10px;
      }
      .active {
        opacity: 1;
        border-bottom: 5px solid #0095ab;
        font-weight: $fw-700;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .main {
    width: 100%;
  }
  .main .slot .dynamic-component {
    justify-content: space-evenly;
  }
  .events-block {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: calc(100vh - 194px);
  }
}
</style>
