<template id="slideout-menu">
  <div class="c-slideout">
    <transition name="overlay">
      <div class="c-slideout__overlay" v-if="show">
        <transition name="slide">
          <div
            class="c-slideout__container u-px2 u-py2"
            v-if="show"
            @click.stop
          >
            <div class="content">
              <div class="content__header">
                <!-- CLOSE BUTTON SLIDE MENU -->
                <a
                  @click="$emit('close')"
                  role="button"
                  class="navbar-burger"
                  aria-label="menu"
                  aria-expanded="false"
                >
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
                <div class="content__header--profile">
                  <div class="content__header--profile__avatar">
                    <img :src="userAvatar()" alt="avatar" />
                  </div>
                  <div class="content__header--profile__name">
                    Hi {{ getUserFullName }}
                    <p @click="redirectToUserProfileOrSignIn()">
                      Manage Profile
                    </p>
                  </div>
                </div>
              </div>
              <div class="content__sidebar">
                <ul>
                  <li>
                    <router-link to="/dashboard">
                      <div class="content__sidebar--home-link">
                        <home-icon />
                        <p>Home</p>
                      </div>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about-us">
                      <div class="content__sidebar--home-link">
                        <about-us-icon />
                        <p>About Us</p>
                      </div>
                    </router-link>
                  </li>
                  <li>
                    <div class="content__sidebar--home-link aselect">
                      <div class="selector" @click="toggle()">
                        <div class="label">
                          <hub-icon />
                          <p>Knowledge Hub</p>
                        </div>
                        <div>
                          <arrow-down-icon
                            class="arrow"
                            :class="{ expanded: visible }"
                          />
                        </div>
                        <div :class="{ hidden: !visible, visible }">
                          <ul
                            :class="{
                              auto_height: visible,
                              hide_height: !visible,
                            }"
                          >
                            <li>
                              <router-link to="/articles">
                                Articles
                              </router-link>
                            </li>
                            <li>
                              <a
                                href="https://waterwise.co.za/site/home.html"
                                target="_blank"
                              >
                                Water Wise
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <router-link to="/news">
                      <div class="content__sidebar--home-link">
                        <news-icon />
                        <p>News</p>
                      </div>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/events">
                      <div class="content__sidebar--home-link">
                        <event-icon />
                        <p>Events</p>
                      </div>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/partners">
                      <div class="content__sidebar--home-link">
                        <partner-icon />
                        <p>Partner & Stakeholders</p>
                      </div>
                    </router-link>
                  </li>
                  <li class="d-none">
                    <router-link to="/accreditations">
                      <div class="content__sidebar--home-link">
                        <accreditation-icon />
                        <p>Accreditations</p>
                      </div>
                    </router-link>
                  </li>
                  <li class="d-none">
                    <router-link to="/affiliations">
                      <div class="content__sidebar--home-link">
                        <affiliation-icon />
                        <p>Affiliations</p>
                      </div>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/terms-conditions">
                      <div class="content__sidebar--home-link">
                        <terms-icon />
                        <p>Terms & Conditions</p>
                      </div>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/contact-us">
                      <div class="content__sidebar--home-link">
                        <contact-us-icon />
                        <p>Contact Us</p>
                      </div>
                    </router-link>
                  </li>
                  <li v-if="user">
                    <div
                      @click.prevent="openModal"
                      href="#"
                      class="content__sidebar--home-link"
                    >
                      <logout-icon />
                      <p>Logout</p>
                    </div>
                  </li>
                  <li v-if="!user" class="sign-in">
                    <router-link to="/auth/log-in">
                      <div class="content__sidebar--home-link">
                        <sign-in-icon />
                        <p>Sign In</p>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <!-- Modals -->
    <logout-modal ref="logoutModal" @logout="logout" />
  </div>
</template>

<script>
import homeIcon from '@/components/svg/Sidebar/HomeIcon.vue';
import aboutUsIcon from '@/components/svg/Sidebar/AboutUsIcon.vue';
import hubIcon from '@/components/svg/Sidebar/HubIcon.vue';
import newsIcon from '@/components/svg/Sidebar/NewsIcon.vue';
import eventIcon from '@/components/svg/Sidebar/EventIcon.vue';
import ContactUsIcon from '@/components/svg/Sidebar/ContactUsIcon.vue';
import TermsIcon from '@/components/svg/Sidebar/TermsIcon.vue';
import AffiliationIcon from '@/components/svg/Sidebar/AffiliationIcon.vue';
import AccreditationIcon from '@/components/svg/Sidebar/AccreditationIcon.vue';
import PartnerIcon from '@/components/svg/Sidebar/PartnerIcon.vue';
import ArrowDownIcon from '@/components/svg/ArrowDownIcon.vue';
import LogoutIcon from '@/components/svg/LogoutIcon.vue';
import { mapGetters, mapMutations } from 'vuex';
import LogoutModal from '@/components/modal/LogoutModal.vue';
import SignInIcon from '@/components/svg/SignInIcon.vue';

export default {
  name: 'SlideMenu',
  components: {
    SignInIcon,
    LogoutModal,
    LogoutIcon,
    ArrowDownIcon,
    PartnerIcon,
    AccreditationIcon,
    AffiliationIcon,
    TermsIcon,
    ContactUsIcon,
    eventIcon,
    newsIcon,
    hubIcon,
    aboutUsIcon,
    homeIcon,
  },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      visible: false,
    };
  },
  methods: {
    ...mapMutations(['setUser']),
    openModal() {
      this.$refs.logoutModal.show();
    },
    async logout() {
      this.setUser(null);
      this.$emit('close');
      await this.$router.push('/auth/log-in');
    },
    toggle() {
      this.visible = !this.visible;
    },
    select(option) {
      this.value = option;
    },
    userAvatar() {
      return this.user?.avatar_url
        ? this.user?.avatar_url
        : require('@/assets/default-ava.png');
    },
    async redirectToUserProfileOrSignIn() {
      if (this.getUser) {
        await this.$router.push({ path: '/profile' });
      } else {
        await this.$router.push({ path: '/auth/log-in' });
      }
    },
  },
  computed: {
    ...mapGetters(['getUser']),
    getUserFullName() {
      return this.user?.name || 'Guest';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.navbar-burger {
  cursor: pointer;
  display: block;
  height: 3.25rem;
  width: 3.25rem;
  position: absolute;
  top: 0;
  right: 0;
  color: $color-white;
}
.navbar-burger span {
  background-color: currentColor;
  display: block;
  height: 1px;
  left: calc(50% - 8px);
  position: absolute;
  transform-origin: center;
  transition-duration: 86ms;
  transition-property: background-color, opacity, transform;
  transition-timing-function: ease-out;
  width: 18px;
}
.navbar-burger span:nth-child(1) {
  top: calc(50% - 6px);
}
.navbar-burger span:nth-child(2) {
  top: calc(50% - 1px);
  display: none;
}
.navbar-burger span:nth-child(3) {
  top: calc(50% + 4px);
}
.navbar-burger span:nth-child(1) {
  transform: translateY(5px) rotate(45deg);
}
.navbar-burger span:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
}

.c-slideout {
  &__overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
  }
  &__container::-webkit-scrollbar {
    display: none;
  }
  &__container {
    width: calc(100% - 68px);
    left: 0;
    top: 0;
    background-image: url('@/assets/app_img/slide-menu-background.png');
    height: 100vh;
    z-index: 9999;
    position: fixed;
    box-sizing: border-box;
    background-size: contain;
    background-repeat: round;
    overflow-y: auto;
    .content {
      @include d-flex-column;
      height: auto;
      gap: 15px;

      &__header {
        @include d-flex-row-center;
        height: 162px;
        background: #0170a6;
        padding-left: 25px;

        &--profile {
          text-align: left;
          @include d-flex-center;
          gap: 15px;

          &__avatar {
            @include d-flex-center;
            justify-content: center;
            width: 83px;
            height: 83px;

            & img {
              width: 100%;
              height: 100%;
              -o-object-fit: cover;
              object-fit: cover;
              border-radius: 40px;
              background: #6c63ff;
            }
          }

          &__name {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            color: $color-white;
            & p {
              padding: 0;
              margin: 0;
              font-weight: 600;
              font-size: 15px;
              line-height: 140.9%;
              color: $color-white;
            }
          }
        }
      }

      &__sidebar {
        padding-left: 25px;
        font-family: 'Montserrat', serif;
        font-style: normal;
        height: 100vh;
        overflow-y: auto;
        & ul {
          list-style: none;
          @include d-flex-column;
          padding: 0;
          gap: 15px;

          & li {
            width: fit-content;
          }

          & a {
            text-decoration: none;
          }
        }

        &--home-link {
          cursor: pointer;
          @include d-flex-row-center;
          gap: 10px;

          & svg {
            width: 20px;
            height: 20px;
            background: #197dae;
            border-radius: 40px;
            padding: 7px;
            box-sizing: unset;
          }
          & p {
            margin: 0;
            font-weight: 600;
            font-size: 14px;
            line-height: 140.9%;
            color: $color-white;
          }
        }
      }
      .aselect {
        .selector {
          position: relative;
          z-index: 1;
          .arrow {
            position: absolute;
            left: 240px;
            top: 5px;
            width: 13px;
            height: 7px;
            transform: rotateZ(0deg) translateY(0px);
            transition-duration: 0.3s;
            background: none;
          }
          .expanded {
            transform: rotateZ(180deg) translateY(2px);
          }
          .label {
            cursor: pointer;
            @include d-flex-center;
            gap: 10px;
          }
        }
        ul {
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 140.9%;
          color: $color-white;
          width: 100%;
          list-style-type: disc;
          display: flex;
          gap: 2px;
        }
        ul li::marker {
          color: $color-white;
          font-size: 1.5em;
        }
        ul li a {
          color: $color-white;
        }
        .hidden {
          visibility: hidden;
        }
        .visible {
          visibility: visible;
          position: relative;
          left: 40%;
        }
        .auto_height {
          height: auto;
        }
        .hide_height {
          height: 0;
        }
      }
    }
  }
}

.logout {
  position: relative;
  top: 5%;
  text-decoration: none;
}

// TRANSITIONS

.overlay {
  &-enter-active,
  &-leave-active {
    transition: all 0.3s ease-out;
  }

  &-enter,
  &-leave-to {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.slide {
  &-enter-active,
  &-leave-active {
    transition: all 0.3s ease-out;
  }

  &-enter,
  &-leave-to {
    transform: translateX(-100%);
  }
}

@media only screen and (min-width: 1600px) {
  .c-slideout__container {
    width: 25%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1600px) {
  .c-slideout__container {
    width: 30%;
  }
}

@media only screen and (max-width: 992px) {
  .c-slideout__container {
    width: 45%;
  }
}

@media only screen and (max-width: 480px) {
  .c-slideout__container {
    width: calc(100% - 68px);
  }
}
</style>
