<template>
  <parent-component>
    <div class="partners">
      <section
        id="terms"
        class="generate-title"
        v-for="(block, index) of pages"
        :key="index"
      >
        <div :class="{ title_page: index === 0 }" class="title">
          {{ block?.title }}
        </div>
        <div
          :class="{ desc_page: index === 0 }"
          class="description preview ql-editor"
          v-html="block?.html"
        ></div>
      </section>
    </div>
  </parent-component>
</template>

<script>
import ParentComponent from '@/components/ParentComponent.vue';
import { baseErrHandler, loadPageInform } from '@/services/api';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: 'TermsConditions',
  components: { ParentComponent },
  created() {
    this.getTermsData();
  },
  data() {
    return {
      pages: [],
    };
  },
  methods: {
    async getTermsData() {
      await baseErrHandler(async () => {
        const { page } = await loadPageInform({ pageName: 'terms' });
        this.pages = page?.blocks;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins.scss';

.partners {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  @include d-flex-column;
  gap: 30px;
  padding: 40px 0 40px;

  .generate-title {
    div:first-child {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
      text-align: left;
      padding-bottom: 10px;
    }
    div:last-child {
      font-weight: 400;
      text-align: left;
      font-size: 14px;
      line-height: 147.02%;
      color: #000000;
      opacity: 0.6;
    }
  }
}

.title_page {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  color: #0058a2 !important;
}
.desc_page {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 147.02% !important;
  color: #000000 !important;
  opacity: unset !important;
}
</style>

<style>
#terms > .ql-editor ol,
#terms > .ql-editor ul {
  padding-left: 0;
}
#terms > .ql-editor ol li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 1em;
  text-align: right;
  content: '';
  border-right: 2px solid #0095ab;
  border-bottom: 3px solid #0095ab;
  transform: translateY(-50%) rotate(45deg);
  width: 8px;
  height: 12px;
  position: relative;
  top: 5px;
}
</style>
