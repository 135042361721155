<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0895 1.31106V2.1962C13.0895 3.16648 12.3065 3.9494 11.3363 3.9494H11.2001C10.2469 3.9494 9.4639 3.16635 9.4639 2.1962V1.29406L6.70647 1.29395V2.19608C6.70647 3.16636 5.92342 3.94928 4.95326 3.94928H4.81708C3.86391 3.94928 3.08087 3.16624 3.08087 2.19608V1.31094C1.36174 1.41316 0 2.84292 0 4.57904V12.7153C0 14.5195 1.46384 16.0004 3.2681 16.0004H12.8852C14.6894 16.0004 16.1533 14.5365 16.1533 12.7323L16.1534 4.57904C16.1704 2.84283 14.8087 1.41306 13.0896 1.31094L13.0895 1.31106ZM14.1448 12.7154C14.1448 13.3962 13.5831 13.975 12.8852 13.975L3.2681 13.9749C2.58731 13.9749 2.00849 13.4132 2.00849 12.7153V5.97479H14.1277L14.1278 12.7153L14.1448 12.7154Z"
      fill="white"
    />
    <path
      d="M3.6427 9.36219H5.48101C5.60022 9.36219 5.70232 9.26008 5.70232 9.14087V7.60901C5.70232 7.4898 5.60021 7.3877 5.48101 7.3877H3.6427C3.52349 7.3877 3.42139 7.4898 3.42139 7.60901V9.14087C3.43848 9.26008 3.52361 9.36219 3.6427 9.36219Z"
      fill="white"
    />
    <path
      d="M10.6722 9.36219H12.5106C12.6298 9.36219 12.7319 9.26008 12.7319 9.14087V7.60901C12.7319 7.4898 12.6298 7.3877 12.5106 7.3877H10.6722C10.553 7.3877 10.4509 7.4898 10.4509 7.60901V9.14087C10.4509 9.26008 10.553 9.36219 10.6722 9.36219Z"
      fill="white"
    />
    <path
      d="M10.6722 12.5785H12.5106C12.6298 12.5785 12.7319 12.4764 12.7319 12.3572V10.8253C12.7319 10.7061 12.6298 10.604 12.5106 10.604H10.6722C10.553 10.604 10.4509 10.7061 10.4509 10.8253V12.3572C10.4509 12.4764 10.553 12.5785 10.6722 12.5785Z"
      fill="white"
    />
    <path
      d="M7.16614 9.36219H9.00445C9.12365 9.36219 9.22576 9.26008 9.22576 9.14087V7.60901C9.22576 7.4898 9.12365 7.3877 9.00445 7.3877H7.16614C7.04693 7.3877 6.94482 7.4898 6.94482 7.60901V9.14087C6.94482 9.26008 7.04693 9.36219 7.16614 9.36219Z"
      fill="white"
    />
    <path
      d="M3.6427 12.5785H5.48101C5.60022 12.5785 5.70232 12.4764 5.70232 12.3572V10.8253C5.70232 10.7061 5.60021 10.604 5.48101 10.604H3.6427C3.52349 10.604 3.42139 10.7061 3.42139 10.8253V12.3572C3.43848 12.4764 3.52361 12.5785 3.6427 12.5785Z"
      fill="white"
    />
    <path
      d="M7.16614 12.5785H9.00445C9.12365 12.5785 9.22576 12.4764 9.22576 12.3572V10.8253C9.22576 10.7061 9.12365 10.604 9.00445 10.604H7.16614C7.04693 10.604 6.94482 10.7061 6.94482 10.8253V12.3572C6.94482 12.4764 7.04693 12.5785 7.16614 12.5785Z"
      fill="white"
    />
    <path
      d="M4.83402 3.1319H4.9702C5.48088 3.1319 5.90641 2.70637 5.90641 2.1957V1.00423C5.90641 0.442525 5.46389 0 4.90218 0C4.34047 0 3.89795 0.442525 3.89795 1.00423V2.1957C3.89783 2.72333 4.3064 3.1319 4.83403 3.1319H4.83402Z"
      fill="white"
    />
    <path
      d="M11.2001 3.1319H11.3363C11.847 3.1319 12.2725 2.70637 12.2725 2.1957V1.00423C12.2895 0.459608 11.8299 0 11.2852 0C10.7235 0 10.281 0.442525 10.281 1.00423V2.1957C10.2639 2.72333 10.6895 3.1319 11.2001 3.1319H11.2001Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'NewsIcon',
};
</script>

<style scoped></style>
