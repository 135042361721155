<template>
  <button
    type="button"
    class="text-btn invite-btn next d-flex opacity-50"
    :class="{ 'opacity-100': formInput }"
    :disabled="!formInput"
    @click="next"
  >
    {{ title }}
  </button>
</template>

<script>
export default {
  name: 'BaseButtonForm',
  props: {
    formInput: {
      type: Boolean,
      default() {
        return false;
      },
    },
    title: {
      type: String,
      default() {
        return 'Next';
      },
    },
  },
  emits: ['next-step'],
  methods: {
    next() {
      this.$emit('next-step', true);
    },
  },
};
</script>

<style scoped></style>
