<template>
  <svg
    width="25"
    height="19"
    viewBox="0 0 25 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5672 0.932157L23.4167 8.10261C23.8264 8.49869 24.0313 9.03819 24.0313 9.63231C24.0313 10.2264 23.8264 10.7591 23.4167 11.162L16.5672 18.3598C15.7545 19.2134 14.4297 19.2134 13.617 18.3598C12.8044 17.5062 12.8044 16.1267 13.617 15.2731L16.9086 11.8313L2.08968 11.8313C0.935573 11.8313 -3.56042e-07 10.8547 -4.08878e-07 9.64597C-4.61713e-07 8.43724 0.935573 7.46069 2.08968 7.46069L16.9086 7.46069L13.617 4.01887C12.8044 3.16524 12.8044 1.78578 13.617 0.932157C14.4297 0.0785316 15.7477 0.0785315 16.5672 0.932157Z"
      fill="url(#paint0_linear_570_908)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_570_908"
        x1="-4.08878e-07"
        y1="9.64597"
        x2="24.0313"
        y2="9.64597"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0058A2" />
        <stop offset="1" stop-color="#0095AB" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'NextSliderButtonTemplate',
};
</script>

<style lang="scss" scoped>
svg > g {
  cursor: pointer;
}
</style>
