<template>
  <div class="main">
    <div class="slot">
      <div class="fix-menu">
        <header-control-button :title="title" @openSidebar="openSidebarMenu" />
      </div>
      <div class="content">
        <div class="position-relative logo-course">
          <div class="w-100 ps-3" v-if="isCheckCompletedCourse">
            <div class="applied_course_status">
              <span><applied-status-icon /></span> Applied
            </div>
          </div>
          <img id="logo_course" :src="course?.image_url" alt="image" />
          <div class="img-info">
            <p>{{ course?.title }}</p>
            <div class="date-inform">
              <div>
                <white-clock-icon /> Duration:
                <span>{{ durationTime(course) }}</span>
              </div>
              <div>
                <badge-with-star-icon /> Level: <span>{{ course?.level }}</span>
              </div>
            </div>
          </div>
          <div class="shadow-item"></div>
        </div>
        <div class="information">
          <div v-if="course.full_description" class="prerequisite">
            <div class="title">Long description</div>
            <div
              class="desc preview ql-editor"
              v-html="course.full_description"
            ></div>
            <hr class="line" />
          </div>
          <div v-if="course.prerequisites" class="prerequisite">
            <div class="title">Prerequisite courses</div>
            <div
              class="desc preview ql-editor"
              v-html="course.prerequisites"
            ></div>
            <hr class="line" />
          </div>
          <div v-if="course.curriculum" class="objectives">
            <div class="title">Course curriculum</div>
            <div
              class="desc preview ql-editor"
              v-html="course.curriculum"
            ></div>
            <hr class="line" />
          </div>
          <div v-if="course.requirements_and_instructions" class="objectives">
            <div class="title">Application requirements and instructions</div>
            <div
              class="desc preview ql-editor"
              v-html="course.requirements_and_instructions"
            ></div>
            <hr class="line" />
          </div>
          <div v-if="course.tuition_and_financing" class="objectives">
            <div class="title">Tuition & Financing</div>
            <div
              class="desc preview ql-editor"
              v-html="course.tuition_and_financing"
            ></div>
            <hr class="line" />
          </div>
          <div v-if="instructors_count" class="objectives">
            <div class="title">Instructors</div>
            <div class="d-flex flex-column">
              <div
                class="d-flex gap-3 align-items-center"
                v-for="instructor in course?.instructors"
                :key="instructor?.id"
              >
                <div class="logo">
                  <img
                    class="instructor-img"
                    :src="getImgInstructor(instructor)"
                    alt="avatar"
                  />
                </div>
                <div class="d-flex gap-2 flex-column w-100 instructor">
                  <div>{{ instructor?.name }}</div>
                  <textarea
                    type="text"
                    class="description"
                    :value="instructor?.bio"
                    rows="3"
                    disabled
                  ></textarea>
                </div>
              </div>
            </div>
            <hr class="line" />
          </div>
          <div class="notes" :class="{ 'mb-4': !!isCheckCompletedCourse }">
            <span>Notes:</span>
            <span>{{ course?.notes }}</span>
          </div>
          <div class="action-button" v-if="!isCheckCompletedCourse">
            <button
              v-if="!applyLinkRedirect"
              class="apply"
              @click="getUrlLinkToApplyCourse"
            >
              Apply
            </button>
            <a
              v-else
              id="applyCourse"
              :href="applyLinkRedirect"
              target="_blank"
              class="apply"
              >Apply</a
            >
            <button class="enquire" @click="getEnquireToCourse">Enquire</button>
          </div>
        </div>
        <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
      </div>
    </div>
    <!-- Modals -->
    <application-form-modal
      ref="inviteModal"
      @close-apply="this.$refs.inviteSuccessModal.show()"
    />
    <enquire-form-modal
      ref="enquireModal"
      @close-enquire="this.$refs.enquireModal.show()"
    />
    <success-attend-event-modal
      ref="inviteSuccessModal"
      :subTitle="subTitle"
      @close-modal="redirectSuccessAttend"
    />
  </div>
</template>

<script>
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import SlideMenu from '@/components/SlideMenu.vue';
import { mapGetters } from 'vuex';
import { getCourseInform } from '@/services/api';
import ApplicationFormModal from '@/components/modal/ApplicationFormModal.vue';
import { formatDateYearAndMonth, isCheckURL } from '@/helpers/tools';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import WhiteClockIcon from '@/components/svg/WhiteClockIcon.vue';
import BadgeWithStarIcon from '@/components/svg/BadgeWithStarIcon.vue';
import {
  course_status_free,
  course_status_sponsored,
  https_protocol,
  partner_link_free,
  partner_link_sponsored,
} from '@/helpers/enums';
import SuccessAttendEventModal from '@/components/modal/SuccessAttendEventModal.vue';
import { courseTitleApply } from '@/store/mock-data';
import AppliedStatusIcon from '@/components/svg/AppliedStatusIcon.vue';
import EnquireFormModal from '@/components/modal/EnquireFormModal.vue';

export default {
  name: 'CourseDetailsTemplate',
  components: {
    EnquireFormModal,
    AppliedStatusIcon,
    SuccessAttendEventModal,
    BadgeWithStarIcon,
    WhiteClockIcon,
    ApplicationFormModal,
    SlideMenu,
    HeaderControlButton,
  },
  data() {
    return {
      subTitle: courseTitleApply,
      title: ' ',
      isOpen: false,
      course: [],
      instructors_count: null,
      inviteModal: null,
      applyLinkRedirect: null,
    };
  },
  async mounted() {
    await this.getCourseInformation();
    this.redirectToPartnersLink();
  },
  methods: {
    async redirectSuccessAttend() {
      const { funding_type } = this.course;
      const type_course = funding_type.toLowerCase().replace(/ /g, '-');
      /* Redirect to course page - selection category */
      await this.$router.push({
        path: `/course/${type_course}`,
      });
    },
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
    async getCourseInformation() {
      const id = this.$route.params.id;
      const { course } = await getCourseInform(id);
      this.course = course;
      this.instructors_count = JSON.parse(
        JSON.stringify(this.course?.instructors),
      ).length;
    },
    durationTime(course) {
      const { start_date = null, end_date = null } = course || {};
      if (start_date && end_date) {
        const start_day = new Date(start_date);
        const end_day = new Date(end_date);
        const result =
          end_day.getMonth() -
          start_day.getMonth() +
          12 * (end_day.getFullYear() - start_day.getFullYear());

        return formatDateYearAndMonth(result);
      }
    },
    async getUrlLinkToApplyCourse() {
      if (!this.isLogged) {
        await this.$router.push('/auth/log-in');
      }
      this.$refs.inviteModal.bindInfo(this.course);
      this.$refs.inviteModal.show();
    },
    async getEnquireToCourse() {
      const { title = '', id: course_id } = this.course;
      const user = this.getUser;
      if (user) {
        const { id: user_id, name, surname, email } = user;
        this.showModalEnquireToCourse({
          title,
          course_id,
          name,
          surname,
          user_id,
          email,
        });
      } else {
        this.showModalEnquireToCourse({
          title,
          course_id,
        });
      }
    },
    showModalEnquireToCourse(data) {
      this.$refs.enquireModal.bindToEnquireInfo(data);
      this.$refs.enquireModal.show();
    },
    redirectToPartnersLink() {
      const { apply_link } = this.course;

      switch (this.course.funding_type) {
        case course_status_sponsored:
          this.course?.apply_link !== null
            ? this.isCheckUrlToApplyLink(apply_link, partner_link_sponsored)
            : (this.applyLinkRedirect = partner_link_sponsored);
          break;
        case course_status_free:
          this.course?.apply_link !== null
            ? this.isCheckUrlToApplyLink(apply_link, partner_link_free)
            : (this.applyLinkRedirect = partner_link_sponsored);
          break;
        default:
      }
    },
    getImgInstructor(user) {
      const { image_url } = user;
      return image_url ? image_url : require('@/assets/default_photo_user.png');
    },
    isCheckUrlToApplyLink(applyLink, setRedirectToLink) {
      if (isCheckURL(applyLink)) {
        this.applyLinkRedirect = applyLink;
      }

      if (!applyLink?.trim()?.length) {
        this.applyLinkRedirect = setRedirectToLink;
      }

      if (!isCheckURL(applyLink) && !!applyLink?.trim()?.length) {
        this.applyLinkRedirect = https_protocol + applyLink;
      }
    },
  },
  computed: {
    ...mapGetters(['getUser', 'isLogged']),
    isCheckCompletedCourse() {
      const userIds =
        this.course?.applicants?.map(({ user_id }) => user_id) || [];
      return userIds.includes(this?.getUser?.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.main::-webkit-scrollbar,
.slot::-webkit-scrollbar {
  display: none;
}

.main .slot .control-button {
  z-index: 2;
}

.main {
  background: $color-white;
  @include vw-vh-scroll;
  .slot {
    height: 100%;
    overflow-y: scroll;
    @include d-flex-column;
    gap: 30px;

    .applied_course_status {
      @include appliedLabel;
      z-index: 1;
    }

    .logo-course {
      width: 100%;
    }

    #logo_course {
      position: relative;
      top: 0;
      object-fit: cover;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #03325a 90.58%
      );
      width: 100%;
      height: 100%;
      opacity: 1;
      border-radius: 0 0 20px 20px;
      min-height: 350px;
    }

    .fix-menu {
      width: 100%;
      z-index: 1;
      position: absolute;
      top: 0;
      padding: 0 25px;
    }

    .content {
      @include d-flex-column-center;
      height: 100%;
      position: relative;

      .img-info {
        @include d-flex-column;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 25px 20px;
        gap: 15px;
        height: auto;
        z-index: 1;
        p {
          font-weight: $fw-700;
          font-size: 26px;
          line-height: 31px;
          letter-spacing: 0.015em;
          color: #f9fafd;
          @include left-text-style-normal;
          word-wrap: break-word;
          margin: 0;
        }

        .date-inform {
          @include d-flex-row-around;
          width: 100%;
          min-height: 45px;
          background: #0095ab;
          border-radius: 140px;
          div {
            font-weight: $fw-600;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: -0.005em;
            color: $color-white;
            gap: 3px;
          }
          span {
            font-weight: $fw-600;
            font-size: 12px;
            line-height: 15px;
            @include d-flex-center;
            letter-spacing: -0.01em;
            color: $color-white;
            opacity: 0.6;
          }
        }
      }
      .shadow-item {
        opacity: 1;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px -130px 66px 23px inset;
      }
      .information {
        padding: 0 25px;
        @include d-flex-column-center;
        text-align: left;
        width: 100%;
        gap: 12px;
        margin-top: 60px;

        .prerequisite,
        .target-market,
        .objectives {
          @include d-flex-column;
          gap: 10px;
          width: 100%;
          text-align: left;
        }

        .notes {
          width: 100%;
          min-height: 77px;
          height: auto;
          background: #e4f1fb;
          border: 1px dashed #0058a2;
          border-radius: 10px;
          display: flex;
          gap: 7px;
          padding: 10px;
          span:first-child {
            font-weight: $fw-700;
            font-size: 20px;
            line-height: 24px;
            color: #0058a2;
          }
          span:last-child {
            font-weight: $fw-500;
            font-size: 14px;
            line-height: 147.02%;
            color: $color-black;
            opacity: 0.4;
            padding-top: 4px;
          }
        }

        .action-button {
          @include d-flex-column;
          gap: 10px;
          width: 100%;
          margin: 10px 0;

          .apply {
            background: #0058a2;
            border-radius: 15px;
            height: 58px;
            color: white;
            font-weight: $fw-700;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            border: none;
            text-decoration: none;
            @include d-flex-center;
            justify-content: center;
          }

          .enquire {
            height: 57px;
            background: $color-white;
            border: 1px solid #0095ab;
            border-radius: 15px;
            font-weight: $fw-700;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #0095ab;
            @include d-flex-center;
            justify-content: center;
            text-decoration-line: none;
          }
        }

        .title {
          font-weight: $fw-700;
          font-size: 20px;
          line-height: 24px;
          color: #0058a2;
        }
        .desc {
          font-weight: $fw-400;
          font-size: 14px;
          line-height: 147.02%;
          color: $color-black;
          opacity: 0.4;
        }
        .line {
          opacity: 0.1;
          border: 1px solid $color-black;
          width: 100%;
        }

        .logo {
          width: auto;
          height: 80px;
          .instructor-img {
            width: 80px;
            height: 80px;
            -o-object-fit: cover;
            object-fit: cover;
            background: none;
            opacity: unset;
            border-radius: 50%;
          }
        }
        .instructor {
          div:last-child {
            font-weight: $fw-700;
            font-size: 16px;
            color: $color-black;
          }
          .description {
            font-weight: $fw-400;
            font-size: 14px;
            color: $color-black;
            opacity: 0.4;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .main {
    background: #e4f1fb;
  }
  .main .slot .content {
    width: 900px !important;
    margin: 0 auto;
  }
  .main .slot .logo-course {
    width: 900px;
    height: 600px;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1200px) {
  .main {
    background: #e4f1fb;
  }
  .main .slot .content {
    width: 700px !important;
    margin: 0 auto;
  }
  .main .slot .logo-course {
    width: 700px;
    height: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 820px) {
  .main {
    background: #e4f1fb;
  }
  .main .slot .content {
    width: 600px;
    margin: 0 auto;
  }
  .main .slot .logo-course {
    width: 600px;
    height: 300px;
  }
  .main .slot .content .information .title {
    font-size: 24px;
    line-height: 24px;
  }
  .main .slot .content .information .desc {
    font-size: 17px;
    line-height: 150%;
  }
}
</style>
<style>
.ql-editor {
  padding: 0;
}
</style>
