<template>
  <div class="main">
    <div class="button-back">
      <header-control-button
        :title="title"
        @openSidebar="openSidebarMenu"
        class="control_btn"
      />
    </div>
    <div class="main--header">
      <img
        v-if="!isCheckPdfFile"
        :src="getArticleLogo"
        class="article_logo"
        alt="article_logo"
      />
      <div class="main--header__content">
        <div v-if="!isCheckPdfFile" class="title">{{ article?.title }}</div>
        <div
          v-if="!isCheckPdfFile"
          class="description preview ql-editor"
          v-html="article.content"
        ></div>
        <iframe
          v-if="isCheckPdfFile"
          class="pdf-file"
          :src="getFullUrlPdfFile()"
        ></iframe>
      </div>
    </div>
  </div>
  <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
</template>
<script>
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import { mapGetters } from 'vuex';
import SlideMenu from '@/components/SlideMenu.vue';
import { getArticleInform } from '@/services/api';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: 'ArticleDetailsTemplate',
  components: { SlideMenu, HeaderControlButton },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  inject: ['gtag'],
  data() {
    return {
      article: [],
      title: ' ',
      article_logo: null,
      isOpen: false,
    };
  },
  async mounted() {
    await this.getArticleInformation();
    this.gtag.event('Show article');
  },
  methods: {
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
    async getArticleInformation() {
      const { article } = await getArticleInform(this.id);
      this.article = article;
    },
    getFullUrlPdfFile() {
      if (this.article.pdf_url !== null) {
        return `https://drive.google.com/viewerng/viewer?embedded=true&url=${this.article.pdf_url}#toolbar=0&scrollbar=0`;
      }
    },
  },
  computed: {
    ...mapGetters(['getUser']),
    getArticleLogo() {
      return this.article?.image_url;
    },
    isCheckPdfFile() {
      return this.article?.pdf_url !== null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.main {
  position: relative;
  width: 100vw;
  background: linear-gradient(180deg, #0058a2 0%, #0095ab 100%);
  .button-back {
    padding: 0 25px;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  &--header {
    position: relative;
    @include d-flex-column;
    & img {
      position: relative;
      top: 0;
      width: 100%;
      height: auto;
      opacity: 0.8;
      border-radius: 0 0 18px 18px;
    }
    & .control_btn {
      position: absolute;
      top: 0;
      width: calc(100% - 50px);
    }
    &__content {
      @include d-flex-align-column;
      height: 100%;
      margin-top: 80px;
      gap: 15px;

      .title {
        @include left-text-style-normal;
        font-weight: $fw-700;
        font-size: 22px;
        line-height: 24px;
        color: $color-white;
        background: #0095ab;
        border-radius: 10px;
        padding: 5px 10px;
        margin: 0 25px;
      }
      .description {
        font-weight: $fw-500;
        font-size: 14px;
        line-height: 24px;
        color: $color-white;
        opacity: 0.7;
        @include left-text-style-normal;
        margin: 0 25px;
      }
      .pdf-file {
        width: 100%;
        height: 90vh;
        padding: 0 25px 25px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .main--header {
    width: 900px;
    margin: 0 auto;
    min-height: 100vh;
  }
  .main--header img {
    width: 80%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .main--header {
    width: 800px;
    margin: 0 auto;
    min-height: 100vh;
  }
  .main--header img {
    width: 70%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main--header {
    width: 700px;
    margin: 0 auto;
    min-height: 100vh;
  }
  .main--header img {
    width: 70%;
    margin: 0 auto;
  }
}
</style>
<style>
.ql-editor {
  padding: 0;
}
</style>
