<template>
  <main-template>
    <header-control-button
      :title="title"
      :user="getUser"
      :line_style="{ background: background }"
      :color="{ color: color }"
      :is-visible-link-back-to-event-page="true"
      @openSidebar="openSidebarMenu"
      class="control-header"
    />
    <div class="content-main">
      <div
        class="item"
        v-for="(course_category, index) in list_category_courses"
        :key="index"
      >
        <router-link :to="course_category.path">
          <div class="category">
            <img :src="course_category.logo" alt="category" loading="eager" />
            <span class="title">{{ course_category.name }}</span>
          </div>
        </router-link>
      </div>
    </div>
    <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
  </main-template>
</template>

<script>
import MainTemplate from '@/components/MainTemplate.vue';
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import SlideMenu from '@/components/SlideMenu.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CoursesView',
  components: {
    SlideMenu,
    HeaderControlButton,
    MainTemplate,
  },
  inject: ['gtag'],
  mounted() {
    this.gtag.event('Show course');
  },
  data() {
    return {
      title: 'Our Courses',
      isOpen: false,
      color: '#182337',
      background: '#64748B',
      list_category_courses: [
        {
          name: 'Sponsored Courses',
          logo: require('@/assets/category_course/sponsored_courses.jpg'),
          path: '/course/sponsored',
        },
        {
          name: 'Self-Funded Courses',
          logo: require('@/assets/category_course/non_funded_courses.jpg'),
          path: '/course/self-funded',
        },
        {
          name: 'Free Courses',
          logo: require('@/assets/category_course/free_courses.jpg'),
          path: '/course/free',
        },
      ],
    };
  },
  methods: {
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
  },
  computed: {
    ...mapGetters(['getUser']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.main {
  background: #e4f1fb;
  overflow: unset;
}

.control-header {
  padding: 0 25px;
  position: fixed;
  z-index: 1;
  background: #e4f1fb;
}

.content-main {
  @include d-flex-column;
  gap: 20px;
  padding: 80px 25px 20px;
  overflow-y: scroll;
  .item {
    width: 100%;
    position: relative;
    .category {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: 18px;
      }
      span {
        @include p-absolut-center;
        top: calc(50% - 18px);
        height: 35px;
      }
      span.title {
        font-weight: $fw-600;
        font-size: 26px;
        line-height: 31px;
        text-transform: capitalize;
        color: $color-white;
        text-shadow: 0 4px 7px $color-black;
        text-align: center;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .content-main {
    display: flex;
    gap: 20px;
    padding: 80px 25px 20px;
    overflow-y: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 80px);
  }
}
</style>
