<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2176 2.90836L7.53293 0.223317C7.38872 0.0794471 7.19648 0 6.98796 0C6.78274 0 6.59049 0.0794471 6.4464 0.223317L3.76249 2.90722C3.6184 3.05132 3.53906 3.24356 3.53906 3.44867C3.53906 3.65366 3.6184 3.84579 3.76249 3.99L4.22108 4.4487C4.3654 4.5928 4.55742 4.67213 4.76253 4.67213C4.96763 4.67213 5.15464 4.5928 5.29874 4.4487L5.90597 3.83634V9.29224C5.90597 9.71429 6.24937 10.0578 6.67153 10.0578H7.33056C7.75272 10.0578 8.09339 9.71418 8.09339 9.29213V3.86958L8.67479 4.44984C8.819 4.59382 9.01192 4.67327 9.21703 4.67327C9.42191 4.67327 9.61461 4.59382 9.7587 4.44984L10.2174 3.99102C10.3616 3.84681 10.4411 3.65468 10.4411 3.44969C10.4409 3.2447 10.3618 3.05246 10.2176 2.90836Z"
      fill="white"
    />
    <path
      d="M12.9063 4.26123H11.9212C11.5022 4.26123 11.1563 4.6151 11.1563 5.03419V5.68286C11.1563 6.0686 11.4844 6.38866 11.8126 6.44102V11.808H2.18753V6.44182C2.40629 6.41973 2.50224 6.34427 2.62267 6.22373C2.76745 6.07884 2.84371 5.88671 2.84371 5.68297V5.03419C2.84371 4.6151 2.49735 4.26123 2.07826 4.26123H1.09394C0.490797 4.26123 0 4.75191 0 5.35494V12.8983C0 13.5015 0.490797 13.9956 1.09394 13.9956H12.9001C13.5033 13.9956 14 13.5015 14 12.8983V5.35494C14 4.7518 13.5092 4.26123 12.9063 4.26123Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'UploadAvaIcon',
};
</script>

<style scoped></style>
