<template>
  <span class="loader"></span>
</template>

<script>
export default {
  name: 'LoaderPWA',
};
</script>

<style scoped lang="scss">
.loader {
  width: 48px;
  height: 48px;
}
.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #0058a2;
  position: absolute;
  left: calc(50% - 18px);
  top: 5%;
  animation: animloader 2s linear infinite;
}
.loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}
</style>
