import { monthList } from '@/helpers/enums';

export const isCheckURL = (str) => {
  try {
    new URL(str);
    return true;
  } catch {
    return false;
  }
};

export const formatDay = (date) => {
  if (!date) {
    return null;
  }
  const day = leadZero(date.getDate());
  return `${day}`;
};

export const formatDateEuropean = (dateString) => {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);
  const day = leadZero(date.getDate());
  const month = leadZero(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const formatMonth = (date, _day = false) => {
  if (!date) {
    return null;
  }
  const day = leadZero(date.getDate());
  const month = monthList[date.month || date?.getMonth()];
  const year = date.year || date.getFullYear();
  return _day ? `${day} ${month} , ${year}` : `${month} ${year}`;
};

export const formatFullDate = (date) => {
  if (!date) {
    return null;
  }
  const day = leadZero(date.getDate());
  const month = leadZero(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const startOfMonth = (year, month) => {
  return formatFullDate(new Date(year, month, 1));
};

export const formatMonthNameAndDay = (date) => {
  if (!date) {
    return null;
  }
  const day = leadZero(date.getDate());
  const month = monthList[date.month || date?.getMonth()];
  const year = date.year || date.getFullYear();
  return `${month} ${day} , ${year}`;
};

export const leadZero = (num, size = 2) => {
  num = num.toString();
  while (num.length < size) num = '0' + num;
  return num;
};

export const formatDateYearAndMonth = (result) => {
  let month = 0;
  // Todo modify method [best practice]
  if (result <= 11) {
    return `${result} months`;
  }
  if (result <= 23) {
    month = result - 12;
    return month > 0 ? `1 Year ${month} months` : `1 Year`;
  }
  if (result <= 35) {
    month = result - 24;
    return month > 0 ? `2 Year ${month} months` : `2 Year`;
  }
  if (result <= 47) {
    month = result - 36;
    return month > 0 ? `3 Year ${month} months` : `3 Year`;
  }
  if (result <= 59) {
    month = result - 48;
    return month > 0 ? `4 Year ${month} months` : `4 Year`;
  }
};
