<template>
  <div class="main">
    <div class="content">
      <div class="button-back">
        <header-control-button :title="title" @openSidebar="openSidebarMenu" />
      </div>
      <div class="content--block">
        <slot></slot>
      </div>
    </div>
    <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
  </div>
</template>

<script>
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import SlideMenu from '@/components/SlideMenu.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ParentComponent',
  components: { SlideMenu, HeaderControlButton },
  mounted() {
    this.getTitle();
  },
  data() {
    return {
      title: null,
      isOpen: false,
    };
  },
  methods: {
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
    getTitle() {
      switch (this.$route.name) {
        case 'Partners':
          this.title = 'Partners & Stakeholders';
          break;
        case 'TermsConditions':
          this.title = 'T & C';
          break;
        case 'Privacy':
          this.title = 'Group Privacy Notice';
          break;
        default:
          this.title = this.$route.name;
          break;
      }
    },
  },
  computed: {
    ...mapGetters(['getUser']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.main {
  min-height: 100%;
  width: 100vw;
}
.content {
  background: linear-gradient(180deg, #0058a2 0%, #0095ab 100%);

  .button-back {
    padding: 0 25px;
  }

  &--block {
    margin-top: 25px;
    background: $color-white;
    border-radius: 20px 20px 0 0;
    padding: 0 25px;
  }
}
</style>
