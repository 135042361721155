<template>
  <div class="main">
    <div class="slot">
      <div class="content">
        <div class="fix-menu">
          <header-control-button
            :title="`Attend Event`"
            @openSidebar="openSidebarMenu"
          />
        </div>
        <section class="form-block">
          <form
            action="#"
            @submit.prevent
            autocomplete="off"
            id="attend_event-form"
          >
            <section class="info">
              <BaseInput
                :id="`course_title`"
                :label="`Name of the Event (Prepopulated)`"
                :placeholder="`Enter name`"
                :disabled="true"
                v-model="form.name_event"
              />
              <BaseInput
                v-model="form.name"
                @clear-form="clearValidForm"
                :id="`course_title`"
                :label="`Name`"
                :placeholder="`Enter Name`"
                :validInput="validForm.name"
              />
              <BaseInput
                v-model="form.surname"
                @clear-form="clearValidForm"
                :id="`course_title`"
                :label="`Surname`"
                :placeholder="`Enter Surname`"
                :validInput="validForm.surname"
              />
              <BaseInput
                v-model="form.email"
                @clear-form="clearValidForm"
                :id="`course_title`"
                :label="`Email Address`"
                :placeholder="`Enter email address`"
                :validInput="validForm.email"
              />
              <BaseInput
                v-model="form.phone"
                @clear-form="clearValidForm"
                :id="`course_title`"
                :label="`Contact Number`"
                :placeholder="`Enter contact number`"
                :validInput="validForm.phone"
              />
              <div class="form-outline">
                <label class="form-label title-label"
                  >Number of Attendees</label
                >
                <input
                  placeholder="Enter number of attendees"
                  type="number"
                  v-model.number="number_attendees"
                  @focus="clearValidForm"
                  autocomplete="off"
                  required="required"
                  class="form-control form-control-lg"
                  :class="{ 'is-invalid': validForm.number_attendees }"
                />
                <div class="invalid-feedback">
                  {{ validForm.number_attendees }}
                </div>
              </div>
            </section>
            <div class="step-application-form d-flex justify-content-end">
              <BaseButtonForm
                :title="`Confirm my Attendance`"
                :form-input="true"
                @next-step="next"
              />
            </div>
          </form>
        </section>
      </div>
      <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
      <menu-template />
    </div>
    <!-- Modals -->
    <success-attend-event-modal
      ref="inviteSuccessModal"
      :subTitle="subTitle"
      @close-modal="redirectSuccessAttend"
    />
  </div>
</template>

<script>
import BaseInput from '@/components/forms/BaseInput.vue';
import BaseButtonForm from '@/components/forms/BaseButtonForm.vue';
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import SlideMenu from '@/components/SlideMenu.vue';
import MenuTemplate from '@/components/MenuTemplate.vue';
import { incorrect_email_error, require_form_error_all } from '@/helpers/enums';
import { isInValidEmail } from '@/helpers/validate.helper';
import {
  baseErrHandler,
  getEventInform,
  saveAttendEvent,
} from '@/services/api';
import { mapGetters } from 'vuex';
import SuccessAttendEventModal from '@/components/modal/SuccessAttendEventModal.vue';
import { eventTitleAttend } from '@/store/mock-data';

export default {
  name: 'AttendEventTemplate',
  components: {
    SuccessAttendEventModal,
    MenuTemplate,
    SlideMenu,
    HeaderControlButton,
    BaseButtonForm,
    BaseInput,
  },
  mounted() {
    this.bindInfo();
  },
  data() {
    return {
      subTitle: eventTitleAttend,
      filterCalendarDay: null,
      isOpen: false,
      number_attendees: null,
      date_for_filter_calendar: null,
      form: {
        event_id: null,
        user_id: null,
        name_event: '',
        name: '',
        surname: '',
        email: '',
        phone: '',
        number_attendees: null,
      },
      validForm: {
        name_event: null,
        event_id: null,
        user_id: null,
        name: null,
        surname: null,
        email: null,
        phone: null,
        number_attendees: null,
      },
    };
  },
  computed: {
    ...mapGetters(['getUser']),
  },
  watch: {
    ['number_attendees']() {
      if (('' + this.number_attendees).length > 3) {
        let tmp = ('' + this.number_attendees).slice(0, 3);
        this.number_attendees = Number(tmp);
      }
    },
  },
  methods: {
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
    async bindInfo() {
      const user = this.getUser;
      const id = this.$route.params.id;
      const { event } = await getEventInform(id);
      this.form.name_event = event?.title;
      this.form.event_id = event?.id;
      this.date_for_filter_calendar = event?.date_from;
      if (user) {
        const { id: user_id, name, surname, phone, email } = user;
        this.form.user_id = user_id;
        this.form.name = name;
        this.form.surname = surname;
        this.form.phone = phone;
        this.form.email = email;
      }
    },
    async next() {
      if (!this.checkDataInformation()) {
        return;
      }
      await this.savedApplicationForCourse();
    },
    async savedApplicationForCourse() {
      const form = this.parsedForm();
      await baseErrHandler(async () => {
        const { success } = await saveAttendEvent(form);
        if (success) {
          this.$refs.inviteSuccessModal.show();
        }
      });
    },
    async redirectSuccessAttend() {
      /* Redirect to events page - date calendar filter selection */
      await this.$router.push({
        path: '/events',
        query: { type: 'upcoming', date_from: this.filterCalendarDay },
      });
    },
    parsedForm() {
      this.form.number_attendees = this.number_attendees;
      return { ...this.form };
    },
    checkDataInformation() {
      const { name, surname, email, phone } = this.form;
      let is_valid = true;
      if (!name) {
        this.validForm.name = require_form_error_all;
        is_valid = false;
      }
      if (!surname) {
        this.validForm.surname = require_form_error_all;
        is_valid = false;
      }
      if (!email) {
        this.validForm.email = require_form_error_all;
        is_valid = false;
      }
      if (email) {
        if (isInValidEmail(email)) {
          this.validForm.email = incorrect_email_error;
          is_valid = false;
        }
      }
      if (!phone) {
        this.validForm.phone = require_form_error_all;
        is_valid = false;
      }
      if (!this.number_attendees) {
        this.validForm.number_attendees = require_form_error_all;
        is_valid = false;
      }
      this.valid = !this.valid;
      return is_valid;
    },
    isComplete() {
      return (
        this.form.name !== '' &&
        this.form.surname !== '' &&
        this.form.email !== '' &&
        this.phone !== '' &&
        this.number_attendees !== null
      );
    },
    clearValidForm() {
      this.validForm.name_event = null;
      this.validForm.event_id = null;
      this.validForm.user_id = null;
      this.validForm.name = null;
      this.validForm.surname = null;
      this.validForm.email = null;
      this.validForm.phone = null;
      this.validForm.number_attendees = null;
    },
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      const { date_from } = from.query;
      if (date_from) {
        vm.filterCalendarDay = date_from;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.main .slot .control-button {
  z-index: 1;
}

.main {
  position: relative;
  width: 100vw;
  height: 100%;
  background-image: url('@/assets/app_img/dashboard-background.png');
  background-size: cover;

  .slot {
    @include d-flex-column;
    min-height: 100vh;
    overflow: scroll;

    .content {
      @include d-flex-column-center;
      padding-bottom: 80px;

      .fix-menu {
        width: calc(100% - 50px);
      }
      .form-block {
        width: 100%;
        background: $color-white;
        border-radius: 30px 30px 0 0;
        padding: 30px 25px;
      }
      .info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 30px;
      }
      .invite-btn {
        justify-content: center;
        @include d-flex-row-center;
        width: 100%;
        height: 47px;
        border-radius: 10px;
      }

      .next {
        background: #0058a2;
        color: $color-white;
      }

      .text-btn {
        font-weight: $fw-700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $color-white;
        width: 100%;
      }
    }
  }
}

.title-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 147.02%;
  color: #000000;
  text-align: left;
  width: 100%;
}
.form-label {
  margin-bottom: 0.2rem;
}
input::placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 147.02%;
  display: flex;
  align-items: center;
  color: #bababa;
}
.is-invalid {
  border: 1px solid #f64e4e !important;
}
.invalid-feedback {
  color: #f64e4e;
}

@media only screen and (min-width: 768px) {
  .main {
    width: 100%;
  }
  .main .slot {
    overflow: auto;
  }
  .main .slot .content .form-block {
    min-height: 100vh;
  }
  #attend_event-form {
    width: 650px;
    margin: 0 auto;
  }
}
</style>
