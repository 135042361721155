<template>
  <button
    type="button"
    class="text-btn invite-btn prev d-flex justify-content-center"
    @click="prev"
  >
    {{ title }}
  </button>
</template>

<script>
export default {
  name: 'BaseButtonPrevForm',
  props: {
    title: {
      type: String,
      default() {
        return 'Back';
      },
    },
  },
  emits: ['prev-step'],
  methods: {
    prev() {
      this.$emit('prev-step', true);
    },
  },
};
</script>

<style scoped></style>
