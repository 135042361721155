<template>
  <div class="main">
    <div class="slot">
      <header-control-button
        :user="getUser"
        @openSidebar="openSidebarMenu"
        @search-prop="search"
        class="header_button"
        :class="{
          menu_background: isSticky,
        }"
      />
      <div class="container">
        <div
          class="description-pwa"
          @click="onCloseBlock()"
          :class="{ heightBlock: showText }"
        >
          <div
            class="down-up"
            :style="
              showText
                ? { transform: 'rotate(' + 45 + 'deg)', top: 0 }
                : { transform: 'rotate(' + 225 + 'deg)' }
            "
          ></div>
          <div class="supplier-desc pb-2">
            <strong>Rand Water</strong>, South Africa’s leading potable water
            supplier, has been supplying bulk water to South Africa since 1903.
            With the knowledge and expertise gathered over years of
            sustainability, Rand Water
            <transition name="slide-fade"
              ><div class="creation-desc pb-2" v-if="!showText">
                recognised the urgent need to confront water challenges by
                establishing a world class academy, the Rand Water Academy.
              </div></transition
            >
          </div>

          <transition name="slide-fade">
            <div class="creation-desc pb-2" v-if="!showText">
              The creation of the <strong>Rand Water Academy</strong> was
              inspired by the need identified within Rand Water and the needs
              within the water and sanitation sector and the continent.
            </div>
          </transition>

          <transition name="slide-fade">
            <div class="contributed-desc pb-2" v-if="!showText">
              Key issues within the country that contributed to the creation of
              the Rand Water Academy are inclusive but not limited to: scarce
              and critical skills, water quality, water losses, poverty, and
              unemployment. Skills Development is our Passion!!!
            </div>
          </transition>
        </div>
        <ul class="cards" v-scroll="onScroll">
          <li
            class="card"
            v-for="(course, index) in styledCards"
            :key="index"
            :style="course.style"
          >
            <div class="w-100 ps-3" v-if="isCheckCompletedCourse(course)">
              <div class="applied_course_status">
                <span><applied-status-icon /></span> Applied
              </div>
            </div>
            <img
              v-if="course?.image_url"
              :src="course?.image_url"
              alt="course-logo"
            />
            <div class="card-link-title">
              <div class="card-content">
                <p class="m-0 opacity-100">{{ course?.title }}</p>
              </div>
            </div>
            <div class="card-link-wrapper pb-3">
              <div class="duration-title opacity-100">
                Duration:
                <p class="card-duration p-0 m-0 opacity-100">
                  {{ durationTime(course) }}
                </p>
              </div>
              <router-link
                :to="{ name: 'CourseDetails', params: { id: course?.id } }"
                class="card-link"
                @click="handleBuy(course?.id)"
                >View Course</router-link
              >
            </div>
            <div class="shadow-item"></div>
          </li>
        </ul>
      </div>
      <div
        class="previous_course"
        v-if="getUser && previous_course_list?.length"
      >
        <p class="desc-block">Previous Courses</p>
        <div
          class="content"
          v-for="(course, index) in previousCourse"
          :key="index"
          :class="{ showOpacity: index !== 0 }"
        >
          <router-link
            @click="isRedirectForRateApp"
            :to="{ name: 'CourseDetails', params: { id: course?.id } }"
          >
            <div class="content--logo">
              <img :src="course?.image_url" alt="logo_article" />
            </div>
            <div class="content--description">
              <div class="duration">
                <p>{{ startOfCourse(course?.start_date) }}</p>
              </div>
              <div class="title">
                <p>{{ isCheckToSymbolsTitleShow(course?.title) }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
    </div>
    <menu-template />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SlideMenu from '@/components/SlideMenu.vue';
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import MenuTemplate from '@/components/MenuTemplate.vue';
import {
  baseErrHandler,
  coursesFeatured,
  coursesPrevious,
  rateAppExists,
} from '@/services/api';
import { formatDateYearAndMonth, formatMonthNameAndDay } from '@/helpers/tools';
import AppliedStatusIcon from '@/components/svg/AppliedStatusIcon.vue';

export default {
  name: 'DashboardView',
  components: {
    AppliedStatusIcon,
    MenuTemplate,
    HeaderControlButton,
    SlideMenu,
  },
  async mounted() {
    await this.featuredCourseList();
    await this.previousCourseList();
    await this.getAppRatingInformation();
    window.addEventListener('scroll', this.scrollEventHandler);
    window.addEventListener('resize', this.getDimensions);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.scrollEventHandler);
    window.removeEventListener('resize', this.getDimensions);
  },
  data() {
    return {
      showSidebar: false,
      isOpen: false,
      course_featured_list: [],
      previous_course_list: [],
      previous_course_count: 0,
      scrollPosition: 0,
      isSticky: false,
      exists_rate_app_user: false,
      showText: false,
      width:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
    };
  },
  inject: ['gtag'],
  methods: {
    handleBuy(courseId) {
      this.gtag.event('show-course', {
        'event-label': 'user-show-course',
        value: courseId,
      });
    },
    scrollEventHandler() {
      const scrollViewportHeight = window.pageYOffset;
      if (this.width <= 480) {
        const scrollConf = 1;
        const scrollTop = document.documentElement.scrollTop;
        const elements = document.querySelectorAll('.showOpacity');
        scrollTop > 30
          ? elements.forEach((el) => (el.style.opacity = scrollConf))
          : elements.forEach((el) => (el.style.opacity = 0.3));
      }
      return scrollViewportHeight >= 30
        ? (this.isSticky = true)
        : (this.isSticky = false);
    },
    onScroll() {
      let el = document.querySelector('.cards');
      this.scrollPosition = el.scrollLeft;
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
    },
    calculateCardStyle(course, index) {
      if (this.width < 480) {
        const cardWidth = 350; // width + padding + margin

        const positionX = index * cardWidth;
        const deltaX = positionX - this.scrollPosition;

        // constrain deltaX between -350 and 0
        const dY = this.clamp(deltaX, -cardWidth, 0);
        const dishearteningValue = dY / cardWidth + 1;

        course.style = {
          opacity: dishearteningValue,
          transform: `perspective(900px) translateX(-50%,20px,0)`,
        };
      }
      return course;
    },
    clamp(value, min, max) {
      return Math.min(Math.max(min, value), max);
    },
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
    async search() {},
    startOfCourse(date) {
      return formatMonthNameAndDay(new Date(date));
    },
    async featuredCourseList() {
      await baseErrHandler(async () => {
        const { list } = await coursesFeatured();
        this.course_featured_list = list;
      });
    },
    async previousCourseList() {
      if (this.getUser) {
        await baseErrHandler(async () => {
          const { total_count, list } = await coursesPrevious({});
          this.previous_course_list = list;
          this.previous_course_count = total_count;
        });
      }
    },
    async getAppRatingInformation() {
      if (this.getUser) {
        const id = this.getUser?.id;
        await baseErrHandler(async () => {
          const { exists_rate_app } = await rateAppExists(id);
          this.exists_rate_app_user = exists_rate_app;
        });
      }
    },
    durationTime(course) {
      const { start_date = null, end_date = null } = course || {};
      if (start_date && end_date) {
        const start_day = new Date(start_date);
        const end_day = new Date(end_date);
        const result =
          end_day.getMonth() -
          start_day.getMonth() +
          12 * (end_day.getFullYear() - start_day.getFullYear());

        return formatDateYearAndMonth(result);
      }
    },
    async isRedirectForRateApp() {
      if (!this.exists_rate_app_user) {
        await this.$router.push({ path: '/rate-app' });
      }
    },
    isCheckCompletedCourse(course) {
      const userIds = course?.applicants?.map(({ user_id }) => user_id) || [];
      return userIds.includes(this?.getUser?.id);
    },
    isCheckToSymbolsTitleShow(titleCourse) {
      return this.width <= 390 && titleCourse.trim()?.length
        ? titleCourse.substring(0, 55) + ' ...'
        : titleCourse;
    },
    onCloseBlock() {
      this.showText = !this.showText;
    },
  },
  computed: {
    ...mapGetters(['getUser']),
    styledCards() {
      return this.course_featured_list.map(this.calculateCardStyle);
    },
    previousCourse() {
      return this.previous_course_list;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins.scss';
@import '@/assets/_variables.scss';

.slide-fade-enter-active {
  transition: all 0.8s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(50px);
  opacity: 0;
}

.menu_background {
  background: linear-gradient(180deg, #0058a2 0%, #0095ab 100%);
}
.main {
  background-image: url('@/assets/app_img/dashboard-background.png');
  background-size: cover;
  position: relative;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  .slot {
    padding-bottom: 90px;
    min-height: 100vh;
    @include d-flex-column;
  }
}
.header_button {
  position: fixed;
  padding: 0 25px;
  z-index: 2;
}

.description-pwa {
  position: relative;
  .supplier-desc,
  .creation-desc,
  .contributed-desc {
    color: $color-white;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: $fw-400;
    font-size: 13px;
    letter-spacing: 0.015em;
    line-height: 18px;
    text-align: left;
    width: 95%;
  }
}

.heightBlock {
  height: auto;
  overflow: hidden;
}

.downIn {
  transform: rotate(45deg) !important;
  top: 0;
}

.down-up {
  content: '';
  display: inline-block;
  height: 10px;
  width: 10px;
  border-left: none;
  border-top: none;
  border-right: 2px #fff solid;
  border-bottom: 2px #fff solid;
  right: 15px;
  top: 5px;
  position: absolute;
  cursor: pointer;
  transition: all 0.8s ease;
}

.previous_course {
  padding: 20px 25px 0;
  @include d-flex-column;
  gap: 10px;
}

.applied_course_status {
  @include appliedLabel;
  z-index: 1;
}

.content {
  @include d-flex-center;
  gap: 18px;
  width: 100%;
  text-decoration: none;
  background: #479abf;
  box-shadow: 1px 3px 28px rgba(0, 0, 0, 0.2), 1px 3px 28px rgba(0, 0, 0, 0.09);
  border-radius: 14px;
  padding: 8px;
  height: 138px;
  a {
    @include d-flex-center;
    width: 100%;
    gap: 10px;
    text-decoration: none;
  }
  &--logo {
    width: auto;
    height: 122px;
    img {
      width: 122px;
      height: 122px;
      border-radius: 10px;
      background: #d9d9d9;
      position: relative;
    }
  }
  &--description {
    height: 100px;
    width: 100%;
    .title,
    .duration {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      color: $color-white;
      text-align: start;
      font-weight: 600;
    }
    .title {
      font-size: 16px;
      line-height: 19px;
    }
    .duration {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.005em;
    }
  }
}

.desc-block {
  font-family: 'Inter', sans-serif;
  font-weight: $fw-700;
  font-size: 22px;
  line-height: 27px;
  color: $color-white;
  width: 100%;
}

.container {
  max-width: 2600px;
  padding: 80px 0 25px 25px;
  margin: 0 auto;
}

.cards {
  display: flex;
  padding-top: 25px;
  list-style: none;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.card {
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  flex: 0 0 85%;
  background: var(--white);
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 5%);
  scroll-snap-align: start;
  transition: all 0.2s;
}

img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.card:not(:last-child) {
  margin-right: 10px;
}

.card:last-child {
  margin-right: 25px;
}

.card .card-title {
  font-size: 20px;
}

.card .card-content {
  margin: 0 20px 8px 0;
  max-width: 85%;
  font-weight: $fw-700;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.015em;
  color: #f9fafd;
}
.shadow-item {
  opacity: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -130px 66px 23px inset;
}
.card .card-link-wrapper {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card .card-link-title,
.card .card-link-wrapper {
  padding: 0 20px;
  z-index: 1;
  text-align: left;
}

.card .card-duration,
.card .duration-title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  color: #ffffff;
  letter-spacing: -0.005em;
  font-weight: 600;
}

.card .duration-title {
  display: flex;
  align-items: center;
  gap: 3px;
  flex-wrap: wrap;
  font-size: 13px;
  line-height: 16px;
  opacity: 0.8;
}

.card .card-duration {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.005em;
  opacity: 0.6;
}

.card .card-link {
  display: inline-block;
  text-decoration: none;
  background: var(--red);
  transition: background 0.2s;
  background: rgb(0, 149, 171);
  border-radius: 40px;
  font-weight: $fw-600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: $color-white;
  padding: 10px;
  margin: 0;
  text-align: center;
}

@media only screen and (min-width: 1600px) {
  .previous_course {
    padding: 0 25px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .content {
    align-items: center;
    display: flex;
    gap: 18px;
    border-radius: 10px;
    background: none;
    flex-wrap: wrap;
    justify-content: center;
    padding: 8px;
    width: calc(33% - 5px);
    height: 138px;
  }
  .card {
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    flex: 0 0 30%;
    background: var(--white);
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    scroll-snap-align: start;
    transition: all 0.2s;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .card {
    flex: 0 0 40%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .card {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1600px) {
  .previous_course {
    padding: 0 25px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .content {
    align-items: center;
    display: flex;
    gap: 18px;
    border-radius: 10px;
    background: none;
    flex-wrap: wrap;
    justify-content: center;
    padding: 8px;
    width: calc(50% - 5px);
    height: 138px;
  }
}

@media only screen and (min-width: 1100px) {
  .heightBlock {
    height: 40px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
  .heightBlock {
    height: 55px;
    overflow: hidden;
  }
}

@media only screen and (max-width: 480px) {
  .content {
    align-items: center;
    display: flex;
    width: 100%;
    gap: 10px;
    text-decoration: none;
    background: #479abf;
    box-shadow: 1px 3px 28px rgba(0, 0, 0, 0.2),
      1px 3px 28px rgba(0, 0, 0, 0.09);
    border-radius: 14px;
    padding: 8px;
    height: 138px;
  }
  .showOpacity {
    opacity: 0.3;
  }
  .description-pwa {
    .supplier-desc,
    .creation-desc,
    .contributed-desc {
      width: 90%;
    }
  }
}
</style>
