<template>
  <div class="main">
    <header-control-button
      :title="` `"
      :user="getUser"
      :color="{ color: color }"
      @openSidebar="openSidebarMenu"
      class="control-header"
    />
    <div class="content-main d-flex align-items-center justify-content-center">
      <div
        class="content-main d-flex align-items-center justify-content-center"
      >
        <div class="logo">
          <img
            :src="require('@/assets/gesture-like-thumbs-up.png')"
            alt="logo"
          />
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center gap-3"
        >
          <div class="title">
            Thanks for your feedback it will help improving more our platform...
          </div>
        </div>
        <div class="step-application-form d-flex justify-content-center">
          <BaseButtonForm
            :title="`Find More Courses`"
            :form-input="true"
            @next-step="next"
          />
        </div>
      </div>
      <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import SlideMenu from '@/components/SlideMenu.vue';
import BaseButtonForm from '@/components/forms/BaseButtonForm.vue';

export default {
  name: 'SuccessRateAppTemplate',
  components: {
    BaseButtonForm,
    SlideMenu,
    HeaderControlButton,
  },
  data() {
    return {
      isOpen: false,
      color: '#182337',
    };
  },
  methods: {
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
    next() {
      this.$router.push('/courses');
    },
  },
  computed: {
    ...mapGetters(['getUser']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

$active-color: #f3d23e;

.main {
  background-image: url('@/assets/app_img/rate-app-background.png');
  background-size: cover;
  @include vw-vh-scroll;
  min-height: 100vh;
}

.main::-webkit-scrollbar {
  display: none;
}

.control-header {
  padding: 0 25px;
}

.content-main {
  @include d-flex-column;
  flex-direction: column;
  gap: 45px;
  margin-top: 30px;
  padding: 0 25px;
  .title {
    font-weight: $fw-400;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    color: $color-white;
    width: 320px;
  }
}

.invite-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 47px;
}

.next {
  background: $color-white;
  border-radius: 15px;
}

.text-btn {
  font-weight: $fw-700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  min-width: 320px;
  color: #0058a2;
}
</style>
