<template>
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.37454 11.8234L4.79993 11.3998L4.37454 11.8234ZM4.79993 11.3998L5.22472 11.8234C5.16898 11.8794 5.10273 11.9238 5.02978 11.9541C4.95684 11.9844 4.87862 12 4.79963 12C4.72064 12 4.64243 11.9844 4.56948 11.9541C4.49653 11.9238 4.43028 11.8794 4.37454 11.8234L4.37094 11.8192L4.36074 11.809L4.32354 11.7712C4.11203 11.5534 3.90401 11.3321 3.69955 11.1076C3.18619 10.5451 2.69464 9.96311 2.22597 9.36286C1.69078 8.67287 1.14358 7.89289 0.727789 7.1225C0.320995 6.36711 0 5.54872 0 4.79993C0 2.07657 2.15517 0 4.79993 0C7.44469 0 9.59986 2.07657 9.59986 4.79993C9.59986 5.54872 9.27887 6.36711 8.87207 7.1219C8.45628 7.89349 7.90969 8.67287 7.37389 9.36286C6.71833 10.2026 6.01813 11.0066 5.27632 11.7712L5.23912 11.809L5.22892 11.8192L5.22532 11.8228L4.79993 11.3998ZM4.79993 6.5999C5.27731 6.5999 5.73514 6.41026 6.0727 6.0727C6.41026 5.73514 6.5999 5.27731 6.5999 4.79993C6.5999 4.32255 6.41026 3.86472 6.0727 3.52716C5.73514 3.1896 5.27731 2.99996 4.79993 2.99996C4.32255 2.99996 3.86472 3.1896 3.52716 3.52716C3.1896 3.86472 2.99996 4.32255 2.99996 4.79993C2.99996 5.27731 3.1896 5.73514 3.52716 6.0727C3.86472 6.41026 4.32255 6.5999 4.79993 6.5999Z"
      fill="#0095AB"
    />
  </svg>
</template>

<script>
export default {
  name: 'LocationIcon',
};
</script>

<style scoped></style>
