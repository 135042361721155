<template>
  <div v-for="event in events" :key="event?.id" class="event">
    <img :src="getImageEvent(event)" alt="event" />
    <div class="applied_event_status" v-if="appliedEventStatus(event)">
      <span><applied-status-icon /></span> Applied
    </div>
    <div class="information">
      <p class="title p-3">{{ event?.title }}</p>
      <div class="date-and-location">
        <div class="date-event">
          Event Date: <span>{{ setFullDateEvent(event) }}</span>
        </div>
        <div class="location-event">
          <div class="w-50 d-flex gap-1">
            <location-icon />
            <p>{{ event?.location }}</p>
          </div>
          <div class="link-event">
            <router-link :to="isPathToEvent(event)">View Details</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationIcon from '@/components/svg/LocationIcon.vue';
import { formatDay, formatMonth } from '@/helpers/tools';
import AppliedStatusIcon from '@/components/svg/AppliedStatusIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'EventDetailsTemplate',
  components: { AppliedStatusIcon, LocationIcon },
  props: {
    events: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default() {
        return 'recent';
      },
    },
    date: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    isPathToEvent(event) {
      return this.type === this.RECENT_EVENT_TYPE
        ? `events/${event?.id}?type=${this.type}`
        : this.date.date_to !== null
        ? `events/${event?.id}?type=${this.type}&date_from=${this.date.date_from}&date_to=${this.date.date_to}`
        : `events/${event?.id}?type=${this.type}&date_from=${this.date.date_from}`;
    },
    getImageEvent(event) {
      const { image_url = null } = event;
      return image_url ? image_url : require('@/assets/event/default.png');
    },
    setFullDateEvent(event) {
      const { date_from = null, date_to = null } = event;
      let start_date = null;
      let end_date = null;
      if (date_to !== null) {
        start_date = formatDay(new Date(date_from));
        end_date = formatMonth(new Date(date_to), true);
        return `${start_date} - ${end_date}`;
      }
      return formatMonth(new Date(date_from), true);
    },
    appliedEventStatus(event) {
      if (event?.users !== undefined) {
        const userIds = event?.users?.map((el) => el?.user_id);
        return userIds.includes(this?.getUser?.id);
      }
    },
  },
  computed: {
    ...mapGetters(['getUser']),
    RECENT_EVENT_TYPE() {
      return 'recent';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.applied_event_status {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 4px;
  position: absolute;
  height: 24px;
  right: 17px;
  top: 25px;
  background: #0058a2;
  border-radius: 12px;

  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: $color-white;
}

.event:first-child {
  margin-top: 25px;
}
.event {
  width: 100%;
  height: auto;
  background: $color-white;
  border: 1px solid #cbcbcb;
  border-radius: 20px;
  position: relative;
  @include d-flex-column;
  justify-content: space-between;

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
  }

  .title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: $fw-600;
    font-size: 20px;
    line-height: 24px;
    color: #182337;
    text-align: left;
    margin: 0;
  }

  .information {
    .location-event {
      @include d-flex-space-between-end;
      height: auto;
    }
    .date-and-location {
      padding: 1rem;
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .date-event,
      span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: $fw-600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.005em;
        color: #0095ab;
      }
      span,
      a,
      .location-event p {
        margin: 0;
        font-weight: $fw-500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.01em;
        color: #6b6b6b;
      }

      .link-event {
        width: 94px;
        height: 29px;
        background: #0095ab;
        border-radius: 40px;
        text-align: center;
        a {
          font-weight: $fw-600;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.02em;
          color: $color-white;
          text-decoration: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .event img {
    height: 350px;
  }
  .event {
    width: 31%;
    height: auto;
  }
  .event:not(:first-of-type) {
    margin-top: 25px;
  }
  .information {
    min-height: 163px;
    @include d-flex-column;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .event img {
    height: 350px;
  }
  .event {
    width: 48%;
    height: auto;
  }
  .event:not(:first-of-type) {
    margin-top: 25px;
  }
  .information {
    min-height: 163px;
    @include d-flex-column;
    justify-content: space-between;
  }
}
</style>
