<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.09464 9.5C4.09464 8.88295 4.19706 8.29128 4.38009 7.73642L1.17829 5.2915C0.554259 6.55842 0.202759 7.98609 0.202759 9.5C0.202759 11.0127 0.553962 12.4394 1.17695 13.7055L4.37697 11.2559C4.19573 10.7035 4.09464 10.1141 4.09464 9.5Z"
      fill="#FCFCFC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.71626 3.88639C11.0568 3.88639 12.2676 4.36139 13.2189 5.13861L15.9864 2.375C14.3 0.906805 12.1379 0 9.71626 0C5.95664 0 2.72545 2.14997 1.17828 5.2915L4.37993 7.73641C5.11767 5.49709 7.22058 3.88639 9.71626 3.88639Z"
      fill="#E9E9E9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.71626 15.1137C7.22073 15.1137 5.11781 13.503 4.38008 11.2637L1.17828 13.7081C2.72545 16.8501 5.95664 19.0001 9.71626 19.0001C12.0366 19.0001 14.2521 18.1761 15.9147 16.6324L12.8756 14.2829C12.0181 14.8231 10.9382 15.1137 9.71626 15.1137Z"
      fill="#F4F4F4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.7972 9.50017C18.7972 8.93878 18.7107 8.33419 18.5809 7.77295H9.71625V11.4434H14.8189C14.5638 12.6948 13.8694 13.6569 12.8756 14.283L15.9147 16.6324C17.6612 15.0115 18.7972 12.5967 18.7972 9.50017Z"
      fill="#DEDEDE"
    />
  </svg>
</template>

<script>
export default {
  name: 'GoogleAuthIcon',
};
</script>

<style scoped></style>
