<template>
  <div
    class="modal fade"
    id="inviteSuccessModal"
    ref="inviteSuccessModal"
    tabindex="-1"
    aria-labelledby="inviteSuccessModal"
    aria-hidden="true"
    @click.self="outside"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex">
            <button class="btn close-modal" @click="closeModal">
              <close-input-icon />
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class="this_modal_body">
            <section class="gap-4">
              <success-attend-event-icon />
              <div class="declaration_title">
                {{ subTitle }}
              </div>
            </section>
            <div class="step-application-form d-flex justify-content-center">
              <BaseButtonForm
                :title="`Ok`"
                :form-input="true"
                @next-step="redirect"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from 'bootstrap';
import BaseButtonForm from '@/components/forms/BaseButtonForm.vue';
import CloseInputIcon from '@/components/svg/CloseInputIcon.vue';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import SuccessAttendEventIcon from '@/components/svg/SuccessAttendEventIcon.vue';

export default {
  name: 'SuccessAttendEventModal',
  components: {
    SuccessAttendEventIcon,
    CloseInputIcon,
    BaseButtonForm,
  },
  props: {
    subTitle: {
      type: String,
      default: '',
    },
  },
  emits: ['close-modal'],
  data() {
    return {
      inviteSuccessModal: null,
    };
  },
  async mounted() {
    this.inviteSuccessModal = new Modal(this.$refs.inviteSuccessModal);
  },
  methods: {
    closeModal() {
      this.inviteSuccessModal?.hide();
    },
    show() {
      this.inviteSuccessModal?.show();
    },
    outside() {
      this.$emit('close-modal');
    },
    redirect() {
      this.$emit('close-modal');
    },
  },
  beforeUnmount() {
    /* Cleaning the body from a frozen scroll  */
    document.body.style.overflow = 'auto';
    document.body.style.paddingRight = '0px';
    this.inviteSuccessModal?.hide();
    this.inviteSuccessModal?.dispose();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.modal::-webkit-scrollbar {
  display: none;
}
.mobile_display_none {
  display: block;
}

.desktop_display_none {
  display: none;
}

.close-modal {
  position: absolute;
  right: 15px;
}

.invite-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 58px;
  border-radius: 15px;
}

.next {
  background: #0058a2;
  color: $color-white;
}

.text-btn {
  font-style: normal;
  letter-spacing: 0.8px;
  font-weight: $fw-700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.modal-header {
  border: none;
}

.modal-footer {
  border: none;
}

.this_modal_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 60px;
}
.this_modal_body > span {
  margin-bottom: -0.8rem !important;
}

.mobile_display_none {
  display: none;
}

.desktop_display_none {
  display: block;
}

.modal-dialog {
  //width: 100% !important;
  //height: 100vh !important;
  //min-width: 100% !important;
  //min-height: 100vh !important;
  margin: 0 auto;
}

.modal-header {
  @include d-flex-column-center;
  gap: 40px;
  padding-top: 30px;
}

.modal-header,
.modal-body {
  padding: 20px 32px;
}

.modal-content {
  width: calc(100% - 20px) !important;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  background: $color-white;
  gap: 25px;
  margin: 0 auto;
  border-radius: 20px;
}

.modal-body {
  background: $color-white;
  border-radius: 20px;
}

section {
  gap: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-label {
  margin-bottom: 0.2rem;
}

.declaration_title {
  height: 62px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: $fw-400;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: $color-black;
  width: 320px;
}

.next-button {
  margin-top: 60px;
}
</style>

<style lang="scss">
.v-calendar {
  width: 100% !important;
}
.v-calendar .input-field input {
  width: 100%;
  border: 1px solid #ced4da;
}
.v-calendar.left .content {
  width: 100% !important;
}

@media screen and (max-width: 320px) {
  .v-calendar .calendar .days-selection,
  .v-calendar .calendar .selected-field {
    padding: 0 !important;
  }
  //.v-calendar .input-field {
  //  position: relative;
  //  min-width: 140px;
  //  font-weight: var(--v-calendar-input-font-weight);
  //  width: calc(100% - 35px);
  //}
  //.v-calendar.left .content {
  //  width: calc(100% - 25px) !important;
  //}
}
</style>
