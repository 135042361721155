<template>
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    :fill="arrow_style"
    :stroke="arrow_style"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.59558 16.7177C9.89336 16.4244 9.89319 15.944 9.59522 15.651L3.95662 10.1049C3.55826 9.71308 3.55826 9.07085 3.95662 8.67903L9.59535 3.13285C9.89325 2.83983 9.89342 2.35962 9.59572 2.0664C9.30429 1.77937 8.83637 1.77958 8.54521 2.06688L1.83942 8.68372C1.44244 9.07543 1.4426 9.71618 1.83977 10.1077L8.54508 16.7174C8.83635 17.0046 9.30419 17.0047 9.59558 16.7177Z"
      :fill="arrow_style"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowIcon',
  props: {
    arrow_style: {
      type: String,
      default() {
        return '#ffffff';
      },
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  position: absolute;
  left: 48%;
  transform: translateX(-50%);
}
</style>
