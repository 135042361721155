<template>
  <main-template>
    <header-control-button
      :user="getUser"
      :line_style="{ background: background }"
      :color="{ color: color }"
      @openSidebar="openSidebarMenu"
      @search-prop="searchInput"
      class="control-header"
    />
    <slot></slot>
    <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
  </main-template>
</template>

<script>
import MainTemplate from '@/components/MainTemplate.vue';
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import SlideMenu from '@/components/SlideMenu.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CoursesTemplate',
  components: { SlideMenu, HeaderControlButton, MainTemplate },
  data() {
    return {
      color: '#182337',
      background: '#64748B',
      isOpen: false,
      search: null,
    };
  },
  methods: {
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
    searchInput(val) {
      this.search = val;
    },
  },
  computed: {
    ...mapGetters(['getUser']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.main {
  background: #e4f1fb;
}

.control-header {
  padding: 0 25px;
  position: fixed;
  z-index: 2;
  background: #e4f1fb;
}
</style>
