<template>
  <svg
    width="23"
    height="19"
    viewBox="0 0 23 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.31626 16.7495C9.31626 16.276 8.93235 15.8921 8.45886 15.8921H1.98357C1.51008 15.8921 1.12616 16.276 1.12616 16.7495C1.12616 16.8987 1.16436 17.039 1.23147 17.1612H0V18.939H10.4424V17.1612H9.21096C9.27802 17.039 9.31626 16.8987 9.31626 16.7495Z"
      fill="white"
    />
    <path
      d="M8.21434 2.08955L13.6855 5.56382C14.111 5.78065 14.6396 5.64218 14.9007 5.2309L15.1633 4.81761C15.4406 4.38078 15.3113 3.80189 14.8746 3.52449L9.55434 0.146094C9.11761 -0.131255 8.53872 -0.00203736 8.26132 0.434792L7.99878 0.848131C7.73773 1.25937 7.8371 1.79665 8.21434 2.08955Z"
      fill="white"
    />
    <path
      d="M22.9975 15.3621L22.9936 15.2794L22.9868 15.2013C22.9833 15.1479 22.9758 15.1005 22.9693 15.0517C22.943 14.8566 22.8983 14.6906 22.8435 14.5405C22.7325 14.2423 22.5756 14.0164 22.3828 13.8472C22.3348 13.8046 22.2835 13.7672 22.2325 13.7292L22.0798 13.6151C21.9783 13.5384 21.8749 13.4648 21.7721 13.3902C21.6696 13.3152 21.5658 13.2423 21.4619 13.1694C21.3583 13.096 21.2543 13.0235 21.1494 12.9522C20.9408 12.8079 20.7299 12.6673 20.5185 12.5271L20.2004 12.3186L19.8805 12.1129C19.6666 11.977 19.4524 11.8414 19.2367 11.7082C19.0214 11.5744 18.8052 11.4418 18.5878 11.3114L17.935 10.9209L17.2778 10.537L16.6171 10.1589L15.953 9.78591L15.2858 9.41782L14.6155 9.05483C14.3913 8.935 14.1672 8.81493 13.9415 8.69744C13.7163 8.57933 13.4913 8.46062 13.2644 8.34495L13.0591 8.66848L12.0237 8.18052L13.181 6.35801L7.70984 2.88379L4.86377 7.36575L10.335 10.84L11.4922 9.01747L12.3742 9.74701L12.1688 10.0706C12.37 10.2267 12.5731 10.3797 12.7758 10.5334C12.9781 10.6877 13.182 10.8394 13.3858 10.9914L13.9994 11.4438L14.6164 11.891L15.2363 12.3334L15.8596 12.7706L16.4864 13.2022L17.1175 13.6269C17.3281 13.7682 17.5399 13.9075 17.7527 14.0455C17.965 14.1841 18.1787 14.3202 18.3928 14.4561L18.7149 14.658L19.0389 14.8573C19.2557 14.9889 19.4727 15.12 19.692 15.2475C19.8011 15.3121 19.9109 15.3754 20.0214 15.438C20.1315 15.501 20.2418 15.5639 20.3533 15.6248C20.4646 15.686 20.5751 15.7483 20.6876 15.8075L20.8558 15.8972C20.9119 15.9271 20.9676 15.9577 21.0266 15.983C21.2618 16.0855 21.5329 16.1315 21.8499 16.1051C22.0091 16.0908 22.1784 16.0608 22.3662 16.0016C22.4131 15.9868 22.4592 15.9733 22.5091 15.9538L22.5826 15.9267L22.6592 15.895C22.7612 15.8525 22.8704 15.7986 22.9888 15.7305C23.0001 15.5943 23.0024 15.4726 22.9975 15.3621Z"
      fill="white"
    />
    <path
      d="M3.17088 10.1992L8.49101 13.5775C8.92784 13.8548 9.50673 13.7256 9.78407 13.2888L10.0466 12.8754C10.3077 12.4641 10.2082 11.9269 9.83106 11.634L4.35991 8.1598C3.93442 7.94302 3.40583 8.08144 3.14469 8.49277L2.88223 8.90606C2.60479 9.34289 2.73405 9.92183 3.17088 10.1992Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'TermsIcon',
};
</script>

<style scoped></style>
