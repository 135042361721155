<template>
  <div class="container_page" ref="slide">
    <div
      class="splash_screen"
      v-if="loading"
      :class="{ 'splash_screen--web': width > 480 }"
    >
      <LoaderPWA v-if="loading" class="loader" />
    </div>
    <div
      class="content"
      v-if="!loading"
      :class="{ hide_splash_screen: !loading }"
    >
      <swiper
        :modules="modules"
        :options="swiperOptions"
        :navigation="false"
        :pagination="{ clickable: true }"
        :scrollbar="{ draggable: true }"
        :space-between="3"
        :autoHeight="false"
        :effect="'fade'"
        :breakpoints="{
          320: breakpoint,
          640: breakpoint,
          980: breakpoint,
        }"
        ref="mySwiper"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        @touchstart="startDrag"
        @touchend="dragEnd"
      >
        <swiper-slide v-for="slider in sliders" :key="slider.id">
          <p class="skip" @click="skipCheckAuthUser">Skip</p>
          <img class="expert_img" :src="slider.image" alt="logo" />
          <div class="content-block">
            <p class="content-block__parent-title">{{ slider.main }}</p>
            <p class="content-block__child-title">{{ slider.child }}</p>
          </div>
          <button class="next-slide">
            <next-slider-button-template @click="nextSlide(true)" />
          </button>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import NextSliderButtonTemplate from '@/components/svg/NextButtonSlider.vue';
import { mapGetters } from 'vuex';
import LoaderPWA from '@/components/LoaderPWA.vue';

export default {
  name: 'SliderTemplate',
  components: {
    LoaderPWA,
    NextSliderButtonTemplate,
    Swiper,
    SwiperSlide,
  },
  mounted() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    this.redirectAuthUser();
    window.addEventListener('resize', this.getDimensions);
    this.$nextTick(() => {
      this.startSplashScreen();
      setTimeout(() => this.finishSplashScreen(), 3000);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.getDimensions);
  },
  data() {
    return {
      swiper: null,
      active_slide: 0,
      loading: false,
      swiperOptions: {
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      breakpoint: {
        slidesPerGroup: 1,
        slidesPerView: 1,
        allowTouchMove: true,
        resistanceRatio: 0,
      },
      width:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      modules: [Navigation, Pagination],
      sliders: [
        {
          id: 0,
          image: require('@/assets/slider/img/screen-1.png'),
          main: 'Welcome to the Rand Water Academy App!',
          child:
            'Take charge of your future and develop your career by signing up for a course.',
        },
        {
          id: 1,
          image: require('@/assets/slider/img/screen-2.png'),
          main: 'Choose the course that speaks to you.',
          child:
            'Apply for one of our exciting, professional courses that will take you to the next level in your career!',
        },
        {
          id: 2,
          image: require('@/assets/slider/img/screen-3.png'),
          main: 'Empower yourself with the Knowledge Hub',
          child:
            'Read articles and learn more about the global impact within the water and sanitation sector.',
        },
        {
          id: 3,
          image: require('@/assets/slider/img/screen-4.png'),
          main: 'Don’t miss out on Academy events',
          child:
            'Have the ability to keep track, view and RSVP to any Academy event with just a few clicks.',
        },
        {
          id: 4,
          image: require('@/assets/slider/img/screen-5.png'),
          main: 'Save the App directly on your mobile phone',
          child:
            'By clicking on your settings and ‘Adding to Home Screen” the App will be available on your phone like every other App.',
        },
      ],
      touchSessionInfo: {
        clientX: 0,
        tmpClientX: 0,
        isTouchStarted: false,
        centerClientWidth: 0,
      },
    };
  },
  methods: {
    async redirectAuthUser() {
      if (this.getUser) {
        await this.skipCheckAuthUser();
      }
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
    },
    startSplashScreen() {
      this.loading = true;
    },
    finishSplashScreen() {
      this.loading = false;
    },
    async skipCheckAuthUser() {
      await this.$router.push('/auth/log-in');
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onSlideChange() {
      this.active_slide = this.swiper?.activeIndex;
    },
    nextSlide(isNext = true) {
      this.redirectSignIn();
      isNext ? this.swiper?.slideNext() : this.swiper?.slidePrev();
    },
    startDrag(event = {}) {
      this.touchSessionInfo.isDragStarted = true;
      let { clientX, changedTouches = [{}] } = event;
      const touchClientX = changedTouches[0]?.clientX || 0;
      const clientWidth = changedTouches[0]?.target.clientWidth || 0;
      clientX = clientX || touchClientX;
      this.touchSessionInfo.tmpClientX = clientX;
      this.touchSessionInfo.centerClientWidth = clientWidth / 2;
      if (clientX >= this.touchSessionInfo.centerClientWidth) {
        this.redirectSignIn();
      }
    },
    dragEnd() {
      if (this.touchSessionInfo.isTouchStarted) {
        this.touchSessionInfo.isTouchStarted = false;
      }
    },
    redirectSignIn() {
      if (this.active_slide === this.LAST_SLIDE) {
        this.$router.push({ name: 'LogIn' });
      }
    },
  },
  computed: {
    ...mapGetters(['getUser']),
    LAST_SLIDE() {
      return 4;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

@keyframes any {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.container_page::-webkit-scrollbar,
.content::-webkit-scrollbar {
  display: none;
}

.hide_splash_screen {
  animation: any 1s forwards;
}

.splash_screen {
  background-image: url('@/assets/splash-mobile.png');
  background-size: cover;
  position: relative;
  width: 100%;
  min-height: 100vh;

  .loader {
    position: fixed;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.splash_screen--web {
  background-image: url('@/assets/splash-web.png');
  position: relative;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;

  .loader {
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.container_page {
  position: relative;
  .content {
    .skip {
      cursor: pointer;
      position: absolute;
      top: 2%;
      right: 5%;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 147.02%;
      color: $color-white;
      margin: 0;
      height: 40px;
    }
    .expert_img {
      width: 100vw;
      object-fit: cover;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      margin: 0 auto;
    }
    .content-block {
      position: absolute;
      width: 344px;
      display: flex;
      flex-direction: column;
      align-items: center;
      bottom: 19%;
      gap: 30px;
      &__parent-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        color: $color-white;
        margin: 0;
      }
      &__child-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 147.02%;
        text-align: center;
        color: $color-white;
        opacity: 0.8;
        margin-top: 0;
      }
    }
    .next-slide {
      position: absolute;
      bottom: 3.89%;
      background: $color-white;
      box-shadow: 0 14px 29px rgb(23 94 151 / 30%);
      border-radius: 19px;
      width: 60px;
      height: 60px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 24px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .container_page .content .expert_img {
    height: 100vh;
  }
}
</style>
<style>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 110px;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #ffffff;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 8px;
  height: 17px;
  background: #26c0d7;
  border-radius: 19px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}
.swiper {
  overflow: hidden;
}
</style>
