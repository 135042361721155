<template>
  <div class="container_page">
    <div class="content">
      <div class="content-form">
        <div class="control-button">
          <back-icon class="control-button__back" @click="$router.go(-1)" />
        </div>
        <div class="welcome_block">
          <div class="title_welcome text-center">Forgot Password?</div>
          <p>
            Please enter the email address associated with your account and
            we'll send you a link to reset your password.
          </p>
        </div>
        <div class="forgot-pass">
          <form action="#" @submit.prevent autocomplete="off" class="form-pwa">
            <div class="form_block">
              <div class="input_block">
                <div class="input_icon">
                  <email-icon />
                </div>
                <input
                  v-model="formData.email"
                  type="email"
                  autocomplete="off"
                  class="form__input"
                  placeholder="Email address"
                />
              </div>
            </div>
          </form>
          <div class="sign-and-social">
            <button
              class="btn__sign-in"
              :disabled="disabledButton"
              :class="{ 'opacity-50': disabledButton }"
              @click="forgotPassword"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackIcon from '@/components/svg/BackIcon.vue';
import EmailIcon from '@/components/svg/EmailIcon.vue';
import { baseErrHandler, resetPassword } from '@/services/api';
import { errorMessage, successMessage } from '@/helpers/messages';
import { isInValidEmail } from '@/helpers/validate.helper';
import { mapGetters } from 'vuex';

export default {
  name: 'ForgotPassword',
  components: {
    EmailIcon,
    BackIcon,
  },
  async mounted() {
    await this.redirectAuthUser();
  },
  data() {
    return {
      formData: {
        email: '',
      },
      view_password: false,
      disabledButton: false,
    };
  },
  methods: {
    async redirectAuthUser() {
      if (this.isLogged) {
        await this.skipCheckAuthUser();
      }
    },
    async skipCheckAuthUser() {
      await this.$router.push('/dashboard');
    },
    async forgotPassword() {
      if (!this.isValidFormRegistration()) {
        return;
      }
      await baseErrHandler(async () => {
        const success = await resetPassword(this.formData);
        if (success) {
          this.disabledButton = !this.disabledButton;
          successMessage('Please check your email to change your password.');
          setTimeout(async () => {
            await this.$router.push('/auth/log-in');
          }, 2000);
        }
      });
    },
    isValidFormRegistration() {
      let is_valid = true;
      if (!this.formData.email) {
        return errorMessage('Please fill in all required fields.');
      }
      if (this.formData.email) {
        if (isInValidEmail(this.formData.email)) {
          return errorMessage('Incorrect email. Please, try again');
        }
      }
      return is_valid;
    },
    clearForm() {
      this.formData.email = '';
    },
  },
  computed: {
    ...mapGetters(['isLogged']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.container_page {
  @include container;
  min-height: 100vh;
  background-size: cover;
  background-image: url('@/assets/app_img/forgot-pass-background.png');
  .content {
    min-height: 100vh;
    padding: 0 25px;
    .content-form {
      min-height: 100vh;
      .control-button {
        justify-content: start;
        display: flex;
        align-items: end;
        color: white;
        margin-bottom: 75px;
        height: 80px;

        &__back {
          cursor: pointer;
        }
      }
      .welcome_block {
        margin-bottom: 30px;
        & div {
          font-weight: 700;
          font-size: 30px;
          line-height: 36px;
          color: $color-white;
        }
        & p {
          font-weight: 500;
          font-size: 16px;
          line-height: 147.02%;
          text-align: center;
          opacity: 0.4;
          color: $color-white;
          margin: 10px auto;
          max-width: 540px;
        }
      }
      .forgot-pass {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 50px;
        .input_block {
          position: relative;
          input {
            width: 100%;
            padding-left: 40px;
            height: 58px;
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid rgba(255, 255, 255, 0.18);
            border-radius: 17px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 147.02%;
            color: $color-white;
            text-align: start;

            &::placeholder {
              text-align: start;
              font-weight: 500;
              font-size: 16px;
              line-height: 147.02%;
              color: $color-white;
            }
          }

          .input_icon {
            position: absolute;
            left: 16px;
            top: 16px;
            min-width: 16px;
          }
        }
        .sign-and-social {
          display: flex;
          gap: 30px;
          flex-direction: column;
          .btn {
            &__sign-in {
              width: 100%;
              height: 58px;
              border-radius: 15px;
              text-align: center;
              align-items: center;
              display: flex;
              justify-content: center;
              font-weight: 700;
              font-size: 18px;
              line-height: 22px;
              color: #0058a2;
              border: none;
              background: $color-white;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .forgot-pass {
    max-width: 540px;
    margin: 0 auto;
  }
}
</style>
