<template>
  <svg
    width="13"
    height="18"
    viewBox="0 0 13 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0V17.5059H13V3.68405C11.772 2.456 10.5439 1.22796 9.31586 0H0ZM6.59147 14.5125H2.95803V12.8767H6.59147V14.5125ZM10.042 11.4102H2.95803V9.77446H10.042V11.4102ZM10.042 8.3079H2.95803V6.67213H10.042V8.3079ZM8.12389 4.83693V1.29151L11.669 4.83693H8.12389Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'EventIcon',
};
</script>

<style scoped></style>
