<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75634 2.91685V1.5191H9.39436C9.81381 1.5191 10.1539 1.179 10.1539 0.75955C10.1539 0.340096 9.81387 0 9.39436 0H5.7789C5.35945 0 5.01935 0.340096 5.01935 0.75955C5.01935 1.179 5.35945 1.5191 5.7789 1.5191H6.41692V2.91685C2.787 3.48105 0 6.62777 0 10.4133C0 14.5966 3.40339 18 7.58663 18C11.7699 18 15.1733 14.5966 15.1733 10.4133C15.1733 6.62777 12.3863 3.48099 8.75634 2.91685ZM10.1629 12.9787C10.0145 13.1277 9.81959 13.2023 9.62471 13.2023C9.43088 13.2023 9.23698 13.1285 9.08878 12.981L7.05075 10.9516C6.90765 10.809 6.82714 10.6153 6.82714 10.4134V6.51949C6.82714 6.10004 7.16724 5.75994 7.58669 5.75994C8.00614 5.75994 8.34624 6.10004 8.34624 6.51949V10.0978L10.1607 11.9046C10.4579 12.2005 10.4589 12.6814 10.1629 12.9787Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'WhiteClockIcon',
};
</script>

<style scoped></style>
