<template>
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.92519 2.30688V1.20142H7.42979C7.76152 1.20142 8.0305 0.932448 8.0305 0.600711C8.0305 0.268974 7.76157 0 7.42979 0H4.5704C4.23867 0 3.96969 0.268974 3.96969 0.600711C3.96969 0.932448 4.23867 1.20142 4.5704 1.20142H5.075V2.30688C2.20418 2.75308 0 5.24176 0 8.23565C0 11.5441 2.69167 14.2358 6.0001 14.2358C9.30857 14.2358 12.0002 11.5441 12.0002 8.23565C12.0002 5.24176 9.79601 2.75304 6.92519 2.30688ZM8.03761 10.2646C7.92026 10.3824 7.76609 10.4414 7.61197 10.4414C7.45867 10.4414 7.30532 10.3831 7.18811 10.2663L5.57628 8.66134C5.46311 8.5486 5.39943 8.39544 5.39943 8.2357V5.15612C5.39943 4.82438 5.66841 4.55541 6.00014 4.55541C6.33188 4.55541 6.60085 4.82438 6.60085 5.15612V7.98614L8.03583 9.41507C8.27088 9.6491 8.2717 10.0295 8.03761 10.2646Z"
      fill="#0095AB"
    />
  </svg>
</template>

<script>
export default {
  name: 'ClockIcon',
};
</script>

<style scoped></style>
