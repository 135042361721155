<template>
  <div class="content__menu bottom-menu">
    <router-link
      to="/dashboard"
      :class="{
        'active-link': ['Dashboard'].includes(this.$route.name),
      }"
    >
      <div class="content__menu--home-link">
        <home-icon />
        <p>Home</p>
      </div>
    </router-link>

    <router-link
      to="/courses"
      :class="{
        'active-link': [
          'Courses',
          'CourseDetails',
          'CoursesSponsored',
          'CoursesFunded',
          'CoursesFree',
        ].includes(this.$route.name),
      }"
    >
      <div class="content__menu--course-link">
        <course-icon />
        <p>Courses</p>
      </div>
    </router-link>

    <router-link
      to="/news"
      :class="{
        'active-link': ['Articles', 'News'].includes(this.$route.name),
      }"
    >
      <div class="content__menu--news-link">
        <document-icon />
        <p>News</p>
      </div>
    </router-link>

    <router-link
      :to="checkAuth"
      :class="{
        'active-link': ['UserProfile'].includes(this.$route.name),
      }"
    >
      <div class="content__menu--profile-link">
        <profile-icon />
        <p>Profile</p>
      </div>
    </router-link>
  </div>
</template>

<script>
import HomeIcon from '@/components/svg/Menu/HomeIcon.vue';
import CourseIcon from '@/components/svg/Menu/CourseIcon.vue';
import DocumentIcon from '@/components/svg/Menu/DocumentIcon.vue';
import ProfileIcon from '@/components/svg/Menu/ProfileIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MenuTemplate',
  components: {
    ProfileIcon,
    DocumentIcon,
    CourseIcon,
    HomeIcon,
  },
  methods: {},
  computed: {
    ...mapGetters(['getUser']),
    checkAuth() {
      return this.getUser ? '/profile' : '/auth/log-in';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.content__menu {
  overflow: hidden;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  box-sizing: initial;
  @include d-flex-row-around;
  background: #0058a2;
  box-shadow: 0px -5px 31px rgb(10 12 31 / 8%);
  height: 80px;
  line-height: 40px;
  color: $color-white;
  z-index: 1;
  & a {
    text-decoration: none;
  }
  .active-link {
    opacity: 1;
  }
  a {
    opacity: 0.6;
    flex-grow: 1;
  }
  &--home-link,
  &--course-link,
  &--news-link,
  &--profile-link {
    font-weight: $fw-500;
    @include d-flex-justify-space-center;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: $color-white;
    height: 60px;
    & p {
      margin: 0;
    }
  }
}
</style>
