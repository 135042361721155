import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import store from './store';
import http_client from './helpers/http_client';
import VueSignature from 'vue-signature-pad';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'toastify-js/src/toastify.css';
import 'vue-select/dist/vue-select.css';
import vScroll from './directives/vScroll';

const app = createApp(App);

app.use(store).use(router).use(VueSignature);
app.directive('scroll', vScroll);
app.config.globalProperties.$http = http_client;
app.use(VueGtag, {
  appName: 'Rand Water',
  pageTrackerScreenviewEnabled: true,
  config: { id: process.env.VUE_MEASUREMENT_ID },
  enabled: true,
});

app.provide('gtag', app.config.globalProperties.$gtag);

app.mount('#app');
