import http from '@/helpers/http_client';
import { errorMessage } from '@/helpers/messages';
import storage from '@/store/index';

export const baseErrHandler = async (callback) => {
  try {
    storage.commit('showLoader');
    await callback();
  } catch (e) {
    console.log(e);
    errorMessage(e?.response?.data?.error?.message);
  } finally {
    storage.commit('hideLoader');
  }
};

/* USERS API */
export const registerAccount = async (requestData) => {
  const resp = await http.post(
    `${http.apiUrl()}users/registration`,
    requestData,
  );
  const { user, token } = resp?.data?.data || {};

  return { user, token };
};

export const loginPWA = async (requestData) => {
  const resp = await http.post(`${http.apiUrl()}users/login`, requestData);
  const { user, token } = resp?.data?.data || {};

  return { user, token };
};

export const resetPassword = async (requestData) => {
  const resp = await http.post(
    `${http.apiUrl()}users/reset_password`,
    requestData,
  );
  const { success } = resp?.data || {};

  return success;
};

export const updatePassword = async (requestData) => {
  const resp = await http.post(
    `${http.apiUrl()}users/update_password`,
    requestData,
  );
  const { user, token } = resp?.data?.data || {};

  return { user, token };
};

export const getUserProfile = async () => {
  const resp = await http.getAuth(`${http.apiUrl()}users/profile`);
  const { user } = resp?.data?.data || {};

  return { user };
};

export const updateUserProfile = async (requestData) => {
  const resp = await http.postAuth(
    `${http.apiUrl()}users/profile/update`,
    requestData,
  );
  const { user, token } = resp?.data?.data || {};

  return { user, token };
};

export const updateAvatarUserProfile = async (requestData) => {
  const resp = await http.postAuth(
    `${http.apiUrl()}users/profile/update_avatar`,
    requestData,
  );
  const { user } = resp?.data?.data || {};

  return { user };
};

export const savedRateAppUser = async (requestData) => {
  const resp = await http.postAuth(
    `${http.apiUrl()}users/rate/app`,
    requestData,
  );
  const { success = false } = resp?.data || {};

  return { success };
};

export const rateAppExists = async (id) => {
  const resp = await http.getAuth(
    `${http.apiUrl()}users/${id}/exists/rate-app`,
  );
  const { exists_rate_app } = resp?.data?.data || {};

  return { exists_rate_app };
};

/* ARTICLES API */
export const articlesPWAList = async (request_params = {}) => {
  const resp = await http.postAuth(
    `${http.apiUrl()}articles/list`,
    request_params,
  );
  const { list, total_count } = resp?.data?.data || {};

  return { list, total_count };
};

export const getArticleInform = async (id) => {
  const resp = await http.getAuth(`${http.apiUrl()}articles/${id}`);
  const { article } = resp?.data?.data || {};

  return { article };
};

/* EVENTS API */
export const eventsPWAList = async (request_params = {}) => {
  const resp = await http.postAuth(
    `${http.apiUrl()}events/list`,
    request_params,
  );
  const { dates, list, total_count } = resp?.data?.data || {};

  return { dates, list, total_count };
};

export const recentEventsPWAList = async (request_params = {}) => {
  const resp = await http.postAuth(
    `${http.apiUrl()}events/recent-list`,
    request_params,
  );
  const { list, total_count } = resp?.data?.data || {};

  return { list, total_count };
};

export const getEventInform = async (id) => {
  const resp = await http.getAuth(`${http.apiUrl()}events/${id}`);
  const { event } = resp?.data?.data || {};

  return { event };
};

export const saveAttendEvent = async (requestData) => {
  const resp = await http.postAuth(
    `${http.apiUrl()}events/attend`,
    requestData,
  );
  const { success = false } = resp?.data || {};

  return { success };
};

/* COURSES API */
export const coursesPWSList = async (requestData) => {
  const resp = await http.postAuth(`${http.apiUrl()}courses/list`, requestData);
  const { list, total_count } = resp?.data?.data || {};

  return { list, total_count };
};

export const getCourseInform = async (id) => {
  const resp = await http.getAuth(`${http.apiUrl()}courses/${id}`);
  const { course } = resp?.data?.data || {};

  return { course };
};

export const coursesFeatured = async () => {
  const resp = await http.get(`${http.apiUrl()}courses/list/featured`);
  const { list } = resp?.data?.data || {};

  return { list };
};

export const coursesPrevious = async (requestData = {}) => {
  const resp = await http.postAuth(
    `${http.apiUrl()}courses/list/completed`,
    requestData,
  );
  const { total_count, list } = resp?.data?.data || {};

  return { total_count, list };
};

export const saveApplicationCourse = async (
  formRequest = {},
  documentImg,
  signatureImg,
  highest_qualification_files = [],
) => {
  const keys = [];
  const data = new FormData();
  for (let i = 0; i < highest_qualification_files.length; i++) {
    const file = highest_qualification_files[i];
    const key = `file_${i}`;
    data.append(key, file);
    keys.push(key);
  }
  data.append(
    'body',
    JSON.stringify({ ...formRequest, highest_qualification_files: keys }),
  );
  data.append(`document`, documentImg);
  data.append(`signature`, signatureImg, signatureImg.name);

  const resp = await http.postAuth(
    `${http.apiUrl()}courses/apply`,
    data,
    http.formDataHeader(),
  );
  const { success = false } = resp?.data || {};
  const { applicant = {} } = resp?.data?.data || {};

  return { success, applicant };
};

/* Enquire course */

export const sendEnquireToCourse = async (formRequest) => {
  const resp = await http.post(`${http.apiUrl()}courses/enquire`, formRequest);
  const { success = false } = resp?.data || {};

  return { success };
};

/* End course */

/* PAGES API */
export const loadPageInform = async ({ pageName }) => {
  const resp = await http.get(`${http.apiUrl()}pages/${pageName}`);
  const { page } = resp?.data?.data;
  return { page };
};
