<template>
  <div class="container-section">
    <div class="fix-menu">
      <header-control-button :title="` `" @openSidebar="openSidebarMenu" />
    </div>
    <div class="main">
      <div class="slot">
        <div class="content">
          <img :src="event?.image_url" alt="image" class="event-img" />
        </div>
        <div class="event">
          <div class="information">
            <p class="title p-3">{{ event?.title }}</p>
            <div class="date-and-location">
              <div class="date-event">
                Event Date: <span>{{ setFullDateEvent() }}</span>
              </div>
              <div class="location-event">
                <location-icon />
                <p>{{ event?.location }}</p>
              </div>
            </div>
          </div>
          <div class="w-100 ps-3" v-if="appliedEventStatus">
            <div class="applied_event_status">
              <span><applied-status-icon /></span> Applied
            </div>
          </div>
          <div class="d-flex flex-column w-100 mt-2 mb-4">
            <p class="paragraph">Purpose</p>
            <div class="child" v-for="(item, i) in lists" :key="i">
              <paragraph-icon />
              <p>{{ item }}</p>
            </div>
          </div>

          <div
            class="step-application-form d-flex justify-content-center"
            v-if="!blockedAttendEvent && !appliedEventStatus"
          >
            <BaseButtonForm
              :title="`Attend Event`"
              :form-input="true"
              @next-step="next"
            />
          </div>
        </div>
        <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import SlideMenu from '@/components/SlideMenu.vue';
import { getEventInform } from '@/services/api';
import { formatDay, formatFullDate, formatMonth } from '@/helpers/tools';
import BaseButtonForm from '@/components/forms/BaseButtonForm.vue';
import ParagraphIcon from '@/components/svg/ParagraphIcon.vue';
import LocationIcon from '@/components/svg/LocationIcon.vue';
import { mapGetters } from 'vuex';
import AppliedStatusIcon from '@/components/svg/AppliedStatusIcon.vue';

export default {
  name: 'EventDetailsShowTemplate',
  components: {
    AppliedStatusIcon,
    LocationIcon,
    ParagraphIcon,
    BaseButtonForm,
    SlideMenu,
    HeaderControlButton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  inject: ['gtag'],
  data() {
    return {
      isOpen: false,
      event: [],
      lists: '',
      inviteModal: null,
    };
  },
  async mounted() {
    await this.getEventInformation();
    this.gtag.event('Show event', this.id);
  },
  methods: {
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
    async getEventInformation() {
      const { event } = await getEventInform(this.id);
      this.event = event;
      this.lists = event?.content
        .split(/(\n)/)
        .filter((el) => !!el && el !== '\n');
    },
    setFullDateEvent() {
      const { date_from = null, date_to = null } = this.event;
      let start_date = null;
      let end_date = null;
      if (date_to !== null) {
        start_date = formatDay(new Date(date_from));
        end_date = formatMonth(new Date(date_to), true);
        return `${start_date} - ${end_date}`;
      }
      return formatMonth(new Date(date_from), true);
    },
    async next() {
      await this.$router.push({ path: `/events/${this.event?.id}/attend` });
    },
  },
  computed: {
    ...mapGetters(['getUser']),
    blockedAttendEvent() {
      return this.event?.date_to < formatFullDate(new Date());
    },
    appliedEventStatus() {
      if (
        this.event?.users !== undefined &&
        this.event?.users?.map((el) => el?.user_id).includes(this?.getUser?.id)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.main::-webkit-scrollbar,
.slot::-webkit-scrollbar {
  display: none;
}

.main .slot .control-button {
  z-index: 1;
}

.applied_event_status {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 4px;
  height: 24px;
  background: #0058a2;
  width: 80px;
  border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: $color-white;
}

.fix-menu {
  position: absolute;
  width: 100%;
  padding: 0 25px;
  z-index: 1;
}

.main {
  background: #e4f1fb;
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  .slot {
    @include d-flex-column;
    gap: 30px;

    .content {
      @include d-flex-column-center;
      .event-img {
        position: absolute;
        width: 100%;
        height: 250px;
        object-fit: cover;
      }
    }
    .event {
      background: $color-white;
      box-shadow: 0 14px 24px rgba(0, 0, 0, 0.09);
      border-radius: 20px;
      @include d-flex-column-center;
      padding-bottom: 20px;
      z-index: 1;
      width: calc(100% - 50px);
      margin-left: 25px;
      margin-top: 60px;

      .information {
        width: 100%;
        .title {
          @include font-Inter;
          font-weight: $fw-600;
          font-size: 20px;
          line-height: 24px;
          color: #182337;
          text-align: left;
          margin: 0;
        }
        .location-event {
          @include d-flex-center;
          height: auto;
          gap: 5px;
        }
        .date-and-location {
          padding: 1rem;
          text-align: left;
          @include d-flex-column;
          gap: 10px;

          .date-event,
          span {
            @include font-Inter;
            font-weight: $fw-600;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.005em;
            color: #0095ab;
          }
          span,
          .location-event p {
            margin: 0;
            font-weight: $fw-500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.01em;
            color: #6b6b6b;
          }
        }
      }
      .paragraph {
        @include font-Inter;
        text-align: left;
        font-weight: $fw-700;
        font-size: 20px;
        line-height: 24px;
        color: #0058a2;
        padding-left: 15px;
      }
      .child {
        display: flex;
        align-items: baseline;
        padding: 0 14px;
        flex-direction: row;
        text-align: left;
        gap: 10px;

        & svg {
          min-width: 20px;
        }
        & p {
          @include font-Inter;
          white-space: pre-line !important;
          font-weight: $fw-400;
          font-size: 14px;
          line-height: 147.02%;
          color: $color-black;
          opacity: 0.4;
          margin: 0;
        }
      }
      .step-application-form {
        width: calc(100% - 30px);
      }
      .invite-btn {
        justify-content: center;
        @include d-flex-row-center;
        width: 100%;
        height: 47px;
        border-radius: 10px;
      }

      .next {
        background: #0058a2;
        color: $color-white;
      }

      .text-btn {
        font-weight: $fw-700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $color-white;
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .main {
    max-width: 900px !important;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1200px) {
  .main {
    max-width: 650px !important;
  }
}

@media only screen and (min-width: 768px) {
  .container-section {
    background-color: #e4f1fb;
  }
  .main {
    max-width: 600px;
    margin: 0 auto;
  }
  .main .slot .content .event-img {
    border-radius: 0 0 10px 10px;
    height: 60%;
    object-position: 0 0;
  }
  .main .slot .event {
    margin-top: 250px;
  }
}
</style>
