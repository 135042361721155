import Toastify from 'toastify-js';

function errorMessage(text = 'Oops. Something went wrong... Try again later.') {
  Toastify({
    text: text,
    duration: 3000,
    newWindow: true,
    close: true,
    gravity: 'top', // `top` or `bottom`
    position: 'right', // `left`, `center` or `right`
    backgroundColor: 'rgba(255,0,60,0.73)',
    stopOnFocus: true, // Prevents dismissing of toast on hover
    onClick: function () {}, // Callback after click
  }).showToast();
}

function successMessage(text = 'Success') {
  Toastify({
    text: text,
    duration: 3000,
    newWindow: true,
    close: true,
    gravity: 'top', // `top` or `bottom`
    position: 'right', // `left`, `center` or `right`
    backgroundColor: 'rgba(60, 179, 113,0.73)',
    stopOnFocus: true, // Prevents dismissing of toast on hover
    onClick: function () {}, // Callback after click
  }).showToast();
}

export { errorMessage, successMessage };
