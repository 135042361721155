<template>
  <svg
    width="27"
    height="31"
    viewBox="0 0 27 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5004 14.6427C10.9221 12.1635 7.66376 10.4281 4.03709 9.75724C2.32293 9.45099 0.750427 10.851 0.750427 12.6447V21.7447C0.750427 24.1947 1.77043 25.4781 3.17293 25.6677C6.71459 26.1343 9.93043 27.6364 12.5654 29.8531C13.0613 30.276 13.8688 30.3197 14.3646 29.9114C17.0138 27.6802 20.2579 26.1489 23.8279 25.6822C25.1596 25.4927 26.2504 24.1364 26.2504 22.7364V12.6447C26.2504 10.851 24.6779 9.45099 22.9638 9.75724C19.3371 10.4281 16.0788 12.1635 13.5004 14.6427V14.6427ZM13.5004 9.46558C15.8521 9.46558 17.7504 7.51141 17.7504 5.09058C17.7504 2.66974 15.8521 0.715576 13.5004 0.715576C11.1488 0.715576 9.25043 2.66974 9.25043 5.09058C9.25043 7.51141 11.1488 9.46558 13.5004 9.46558Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'CourseIcon',
};
</script>

<style scoped></style>
