<template>
  <div
    class="modal fade"
    id="inviteModal"
    ref="inviteModal"
    tabindex="-1"
    aria-labelledby="inviteModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex">
            <div class="title_modal">Application Form</div>
            <button class="btn close-modal" @click="closeModal">
              <close-input-icon />
            </button>
          </div>
          <line-application-form-completed :step="step" />
        </div>
        <div class="modal-body">
          <div class="this_modal_body" v-if="step === 1">
            <p class="title_app_form">Personal information</p>
            <section class="course_personal--info">
              <BaseInput
                v-model="form.first_name"
                @clear-form="clearValidForm"
                :id="`course_title`"
                :label="`First Name:`"
                :validInput="validForm.first_name"
              />
              <BaseInput
                v-model="form.surname"
                @clear-form="clearValidForm"
                :id="`course_title`"
                :label="`Surname:`"
                :validInput="validForm.surname"
              />
              <span class="form-label title-label">Date of Birth:</span>
              <Datepicker
                v-model="date_of_birth"
                show-clear-button
                lang="en"
                :date-format="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                :placeholder="``"
                :class="{ 'is-invalid': validForm.date_of_birth }"
                @change="date_of_birth"
                @reset="resetDateBirth"
              />
              <div class="invalid-feedback">
                {{ validForm.date_of_birth }}
              </div>
              <BaseInput
                v-model="form.passport_number"
                @clear-form="clearValidForm"
                :id="`course_title`"
                :label="`ID Number:`"
                :validInput="validForm.passport_number"
              />
              <div class="gender">
                <label for="inputGender" class="form-label title-label"
                  >Gender:</label
                >
                <VueSelect
                  class="style-chooser form-control-vs"
                  :options="gender_list"
                  v-model="gender"
                  id="gender"
                  label=""
                  placeholder="Select Gender"
                  :class="{ 'is-invalid': validForm.gender }"
                  @change="gender"
                />
                <div class="invalid-feedback">
                  {{ validForm.gender }}
                </div>
              </div>
              <BaseInput
                v-model="form.phone"
                @clear-form="clearValidForm"
                :id="`course_title`"
                :label="`Cell Number:`"
                :validInput="validForm.phone"
              />
              <BaseInput
                v-model="form.email"
                @clear-form="clearValidForm"
                :id="`course_title`"
                :label="`Email:`"
                :validInput="validForm.email"
              />
              <BaseInput
                v-model="form.address"
                @clear-form="clearValidForm"
                :id="`course_title`"
                :label="`Physical Address:`"
                :validInput="validForm.address"
              />
            </section>
            <div class="step-application-form d-flex justify-content-end">
              <BaseButtonForm
                :title="`Next`"
                :form-input="isComplete()"
                @next-step="next"
              />
            </div>
          </div>
          <div class="this_modal_body" v-if="step === 2">
            <p class="title_app_form">Course applied</p>
            <section>
              <BaseInput
                v-model="form.course_name"
                @clear-form="clearValidTwoForm"
                :validInput="validForm.course_name"
                :id="`course_title`"
                :label="`Name of course/Programme:`"
              />
            </section>
            <div class="step-application-form d-flex justify-content-between">
              <BaseButtonPrevForm :title="`Back`" @prev-step="prev" />
              <BaseButtonForm
                :title="`Next`"
                :form-input="form.course_name !== ''"
                @next-step="next"
              />
            </div>
          </div>
          <div class="this_modal_body" v-if="step === 3">
            <p class="title_app_form">Documentation required</p>
            <section>
              <span class="title-label">SA Identity document:</span>
              <BaseInputFile
                ref="document-file"
                @document-file="fileDocument"
                :class="{ 'is-invalid': validForm.document }"
              />
              <div class="invalid-feedback">
                {{ validForm.document }}
              </div>
              <BaseInput
                v-model="form.highest_qualification"
                :id="`course_title`"
                :label="`Highest Qualification:`"
                @clear-form="clearValidThreeForm"
                :validInput="validForm.highest_qualification"
              />
              <div id="highest_qualification-input" class="mt-2">
                <label
                  v-if="!highest_qualification_files[0]"
                  for="highest_qualification"
                  @click="openInputFile"
                  class="highest_qualification-label"
                >
                  <span
                    class="upload-icon"
                    v-if="!highest_qualification_files[0]"
                  >
                    <upload-application-image-icon />
                  </span>
                </label>
                <input
                  class="d-none"
                  id="highest_qualification-files"
                  ref="otherFilesInput"
                  type="file"
                  @change="highestQualificationFiles"
                  accept="image/*,application/pdf"
                />

                <div
                  class="highest_qualification-first-file"
                  v-if="highest_qualification_files[0]"
                >
                  <img
                    class="preview"
                    :src="previewSrc(highest_qualification_files[0])"
                    :class="{
                      upload_pdf:
                        highest_qualification_files[0].type === accepted_type,
                    }"
                    alt="preview"
                  />
                  <button
                    v-if="highest_qualification_files[0].type !== accepted_type"
                    @click="removeFile(0)"
                    class="btn remove-uploaded"
                  >
                    DELETE PHOTO
                  </button>
                  <div
                    class="d-flex align-items-center gap-3"
                    v-if="highest_qualification_files[0].type === accepted_type"
                  >
                    <span class="d-flex align-items-center">{{
                      getFileName(highest_qualification_files[0])
                    }}</span>
                    <div @click="removeFile(0)" class="remove-file">
                      <remove-input-file-icon />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="step-application-form d-flex justify-content-between">
              <BaseButtonPrevForm :title="`Back`" @prev-step="prev" />
              <BaseButtonForm
                :title="`Next`"
                :form-input="
                  !!form.highest_qualification &&
                  file &&
                  highest_qualification_files?.length
                "
                @next-step="next"
              />
            </div>
          </div>
          <!--
          <div class="this_modal_body" v-if="step === 4">
            <p class="title_app_form">Application fees</p>
            <BaseInput
              v-model="form.payment_requirements"
              :id="`course_title`"
              :label="`Payment Requirements:`"
            />
            <span class="title-label">Proof of Payment:</span>
            <BaseInputFile @file-change="$emit('file-change', [$event])" />
            <BaseInput
              v-model="form.bank"
              :id="`course_title`"
              :label="`Bank:`"
            />
            <div class="gender">
              <label for="inputGender" class="form-label title-label"
                >Account Type:</label
              >
              <VueSelect
                class="style-chooser form-control-vs"
                :options="[
                  'Checking account',
                  'Savings account',
                  'Certificate of Deposit',
                  'Money market account',
                  'Individual Retirement Accounts',
                ]"
                v-model="form.account_type"
                id="gender"
                label=""
                placeholder="Select Type"
              />
            </div>
            <BaseInput
              v-model="form.account_number"
              :id="`course_title`"
              :label="`Account Number:`"
            />
            <BaseInput
              v-model="form.branch_name"
              :id="`course_title`"
              :label="`Branch Name:`"
            />
            <BaseInput
              v-model="form.branch_code"
              :id="`course_title`"
              :label="`Branch Code:`"
            />
          </div>
          -->
          <div class="this_modal_body" v-if="step === 4">
            <p class="title_app_form">Declaration</p>
            <section class="gap-4">
              <div class="declaration_title">
                I declare that all information provided (including documents
                attached) is correct. I understand that any false information
                provided may disqualify my application
              </div>
              <div class="signed">
                <span class="form-label title-label d-inline-block"
                  >Signature of Applicant:</span
                >
                <div class="signature-paid">
                  <VueSignaturePad
                    id="signature"
                    width="100%"
                    height="225px"
                    ref="signaturePad"
                    :options="options"
                    @touchend="savedSignaturePaid"
                    @mouseover="savedSignaturePaid"
                    @mouseleave="savedSignaturePaid"
                  />
                </div>
              </div>
              <div class="reset" v-if="signaturePaid">
                <button style="width: 100%" @click="resetSignaturePaid">
                  Reset
                </button>
              </div>
              <div>
                <span class="form-label title-label d-inline-block">
                  Date of Signature:
                </span>
                <Datepicker
                  v-model="date"
                  show-clear-button
                  lang="en"
                  :date-format="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  :class="{ 'is-invalid': validForm.date }"
                  :placeholder="``"
                  @change="date"
                  @reset="resetDate"
                />
                <div class="invalid-feedback">
                  {{ validForm.date }}
                </div>
              </div>
              <CustomImportantBlockCourse />
            </section>
            <div class="step-application-form d-flex justify-content-between">
              <BaseButtonPrevForm :title="`Back`" @prev-step="prev" />
              <BaseButtonForm
                :title="`Next`"
                :form-input="!!(date && signaturePaid)"
                @next-step="next"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from 'bootstrap';
import LineApplicationFormCompleted from '@/components/modal/LineApplicationFormCompleted.vue';
import BaseInput from '@/components/forms/BaseInput.vue';
import VueSelect from 'vue-select';
import {
  file_size_validation,
  file_type_validation,
  incorrect_email_error,
  require_input_error,
} from '@/helpers/enums';
import { formatFullDate } from '@/helpers/tools';
import CustomImportantBlockCourse from '@/components/forms/CustomImportantBlockCourse.vue';
import {
  isInValidEmail,
  isValidFileSize,
  isValidFileType,
} from '@/helpers/validate.helper';
import BaseInputFile from '@/components/forms/BaseInputFile.vue';
import BaseButtonForm from '@/components/forms/BaseButtonForm.vue';
import BaseButtonPrevForm from '@/components/forms/BaseButtonPrevForm.vue';
import { baseErrHandler, saveApplicationCourse } from '@/services/api';
import { mapGetters } from 'vuex';
import CloseInputIcon from '@/components/svg/CloseInputIcon.vue';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import VueDatepickerUi from 'vue-datepicker-ui';
import { errorMessage } from '@/helpers/messages';
import RemoveInputFileIcon from '@/components/svg/RemoveInputFileIcon.vue';
import UploadApplicationImageIcon from '@/components/svg/UploadApplicationImageIcon.vue';

export default {
  name: 'ApplicationFormModal',
  components: {
    UploadApplicationImageIcon,
    // UploadApplicationImageIcon,
    RemoveInputFileIcon,
    CloseInputIcon,
    BaseButtonPrevForm,
    BaseButtonForm,
    BaseInputFile,
    VueSelect,
    CustomImportantBlockCourse,
    BaseInput,
    LineApplicationFormCompleted,
    Datepicker: VueDatepickerUi,
  },
  emits: ['close-apply'],
  inject: ['gtag'],
  data() {
    return {
      inviteModal: null,
      options: {
        penColor: '#c0f',
      },
      step: 1,
      left: '0',
      background: '#0058A2',
      gender_list: ['Male', 'Female', 'Other'],
      file: null,
      signaturePaid: null,
      course_id: null,
      gender: null,
      form: {
        user_id: null,
        course_id: null,
        first_name: '',
        surname: '',
        passport_number: '',
        gender: '',
        phone: '',
        email: '',
        address: '',
        date_of_birth: '',
        course_name: '',
        highest_qualification: '',
        date: '',
      },
      validForm: {
        first_name: null,
        surname: null,
        date_of_birth: null,
        passport_number: null,
        gender: null,
        phone: null,
        email: null,
        address: null,
        course_name: null,
        highest_qualification: null,
        document: null,
        date: null,
        signature: null,
      },
      valid: false,
      date_of_birth: null,
      date: null,
      highest_qualification_files: [],
      highest_qualification_preview_last_file: null,
      accepted_type: 'application/pdf',
    };
  },
  async mounted() {
    this.inviteModal = new Modal(this.$refs.inviteModal);
  },
  methods: {
    /* highest Qualification Files  */
    openInputFile() {
      this.$refs['otherFilesInput'].click();
    },
    async highestQualificationFiles({ target }) {
      console.log(this.highest_qualification_files);
      const file = target?.files[0] || null;
      if (!this.validFile(file)) {
        return;
      }
      this.highest_qualification_files.push(file);
    },
    validFile(image) {
      let is_valid = true;
      if (isValidFileSize(image.size)) {
        errorMessage(file_size_validation);
        return (is_valid = false);
      }
      if (!isValidFileType(image.name.split('.').pop())) {
        errorMessage(file_type_validation);
        return (is_valid = false);
      }
      return is_valid;
    },
    previewSrc(file) {
      return file.type === this.accepted_type
        ? this.publicPath + `img/preview-pdf.png`
        : URL.createObjectURL(file);
    },
    removeFile(indexFile) {
      this.$refs.otherFilesInput.value = null;
      this.highest_qualification_files.splice(indexFile, 1);
    },
    bindInfo(course) {
      const { title = '', id } = course;
      this.form.course_id = id;
      this.form.course_name = title;
      const user = this.getUser;
      if (user) {
        const {
          id: user_id,
          name,
          surname,
          phone,
          email,
          country,
          city,
        } = user;

        this.form.user_id = user_id;
        this.form.first_name = name;
        this.form.surname = surname;
        this.form.phone = phone;
        this.form.email = email;
        this.form.address =
          country !== null && city !== null
            ? [country, city].join(' ')
            : country !== null || city !== null
            ? country || city
            : '';
      }
    },
    /* Signature Paid */
    resetSignaturePaid() {
      this.$refs.signaturePad.clearSignature();
      this.signaturePaid = null;
    },
    async savedSignaturePaid() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        const documentName = Math.random().toString(12).slice(2);
        const fileFetched = await fetch(data);
        const blob = await fileFetched.blob();
        this.signaturePaid = await new File([blob], `${documentName}.png`, {
          type: blob.type,
        });
      }
    },
    /* End Signature Paid */
    closeModal() {
      this.clearForm();
      this.inviteModal?.hide();
    },
    show() {
      this.inviteModal?.show();
    },
    async fileDocument(file) {
      this.file = file;
    },
    next() {
      switch (this.step) {
        case 1:
          if (!this.checkDataStepInformation()) {
            return;
          }
          this.left = '25';
          this.step++;
          break;
        case 2:
          if (!this.checkDataStepTwoInformation()) {
            return;
          }
          this.left = '25';
          this.step++;
          break;
        case 3:
          if (!this.checkDataStepThreeInformation()) {
            return;
          }
          this.left = '25';
          this.step++;
          break;
        case 4:
          if (!this.checkDataStepFourInformation()) {
            return;
          }
          this.savedApplicationForCourse();
          break;
      }
    },
    async savedApplicationForCourse() {
      const applicationForm = this.parsedForm();
      await baseErrHandler(async () => {
        const { success } = await saveApplicationCourse(
          applicationForm,
          this.file,
          this.signaturePaid,
          this.highest_qualification_files,
        );
        if (success) {
          this.closeModal();
          this.handleBuy(applicationForm);
          this.$emit('close-apply');
        }
      });
    },
    handleBuy(form) {
      const {
        user_id = null,
        course_id = null,
        first_name = '',
        surname = '',
      } = form;
      const result = `New apply in course: ${course_id}, user: ${user_id} - ${first_name} - ${surname}`;
      this.gtag.event('new-apply-course', {
        'event-label': 'new-apply-course',
        value: result,
      });
    },
    parsedForm() {
      this.form.date_of_birth = formatFullDate(new Date(this.date_of_birth));
      this.form.date = formatFullDate(new Date(this.date));

      return { ...this.form };
    },
    prev() {
      this.step--;
    },
    checkDataStepInformation() {
      const {
        first_name,
        surname,
        passport_number,
        gender,
        phone,
        email,
        address,
      } = this.form;
      let is_valid = true;
      if (!first_name) {
        this.validForm.first_name = require_input_error;
        is_valid = false;
      }
      if (!surname) {
        this.validForm.surname = require_input_error;
        is_valid = false;
      }
      if (!this.date_of_birth) {
        this.validForm.date_of_birth = require_input_error;
        is_valid = false;
      }
      if (!passport_number) {
        this.validForm.passport_number = require_input_error;
        is_valid = false;
      }
      if (!gender) {
        this.validForm.gender = require_input_error;
        is_valid = false;
      }
      if (!phone) {
        this.validForm.phone = require_input_error;
        is_valid = false;
      }
      if (!email) {
        this.validForm.email = require_input_error;
        is_valid = false;
      }
      if (email) {
        if (isInValidEmail(email)) {
          this.validForm.email = incorrect_email_error;
          is_valid = false;
        }
      }
      if (!address) {
        this.validForm.address = require_input_error;
        is_valid = false;
      }
      this.valid = !this.valid;
      return is_valid;
    },
    isComplete() {
      return (
        this.form.first_name !== '' &&
        this.form.surname !== '' &&
        this.date_of_birth !== null &&
        this.form.passport_number !== '' &&
        this.gender !== null &&
        this.form.phone !== '' &&
        this.form.email !== '' &&
        this.form.address !== ''
      );
    },
    clearValidForm() {
      this.validForm.first_name = null;
      this.validForm.surname = null;
      this.validForm.date_of_birth = null;
      this.validForm.passport_number = null;
      this.validForm.gender = null;
      this.validForm.phone = null;
      this.validForm.email = null;
      this.validForm.address = null;
      this.validForm.course_name = null;
    },
    checkDataStepTwoInformation() {
      let is_valid = true;
      if (!this.form.course_name) {
        this.validForm.course_name = require_input_error;
        is_valid = false;
      }
      return is_valid;
    },
    checkDataStepThreeInformation() {
      let is_valid = true;
      if (!this.form.highest_qualification) {
        this.validForm.highest_qualification = require_input_error;
        is_valid = false;
      }
      if (!this.file) {
        this.validForm.document = require_input_error;
        is_valid = false;
      }
      return is_valid;
    },
    checkDataStepFourInformation() {
      let is_valid = true;
      if (!this.date) {
        this.validForm.date = require_input_error;
        is_valid = false;
      }
      return is_valid;
    },
    clearValidTwoForm() {
      this.validForm.course_name = null;
    },
    clearValidThreeForm() {
      this.validForm.highest_qualification = null;
      this.validForm.document = null;
    },
    clearValidFourForm() {
      this.validForm.date = null;
    },
    async toBlob(img) {
      return fetch(img).then((res) => res.blob());
    },
    clearForm() {
      this.form.first_name = null;
      this.form.surname = null;
      this.form.passport_number = null;
      this.form.phone = null;
      this.form.email = null;
      this.form.address = null;
      this.form.course_name = null;
      this.date_of_birth = null;
      this.form.date_of_birth = null;
      this.gender = null;
      this.form.gender = null;
      this.form.highest_qualification = null;
      this.highest_qualification_files = [];
      this.file = null;
      this.date = null;
      this.form.date = null;
      if (this.signaturePaid) {
        this.$refs.signaturePad.clearSignature();
        this.signaturePaid = null;
      }
      this.step = 1;
    },
    resetDateBirth() {
      this.date_of_birth = null;
    },
    resetDate() {
      this.date = null;
    },
    getFileName(file) {
      return file.name.length <= 15
        ? file.name
        : file.name.slice(0, 15) + '...' + file.name.split('.').pop();
    },
  },
  computed: {
    ...mapGetters(['getUser']),
    publicPath() {
      return process.env.BASE_URL;
    },
  },
  watch: {
    gender(value) {
      this.form.gender = value;
      this.clearValidForm();
    },
    date_of_birth(val) {
      if (val !== undefined) {
        this.form.date_of_birth = val;
      }
      this.clearValidForm();
    },
    file() {
      this.clearValidThreeForm();
    },
    date(val) {
      this.date = val;
      this.clearValidFourForm();
    },
  },
  beforeUnmount() {
    /* Cleaning the body from a frozen scroll  */
    document.body.style.overflow = 'auto';
    this.inviteModal?.hide();
    this.inviteModal?.dispose();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.modal::-webkit-scrollbar {
  display: none;
}
.mobile_display_none {
  display: block;
}

.desktop_display_none {
  display: none;
}

.highest_qualification_files {
  margin: 30px 0 0;
  display: flex;
  gap: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

#highest_qualification-input {
  position: relative;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  height: 118px;
  background: rgba(0, 88, 162, 0.05);
  border: 2px dashed #add0ee;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  gap: 12px;
}
.highest_qualification-label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.upload-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-file {
  min-width: 18px;
  height: 18px;
  background: #0095ab;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 18px;
  cursor: pointer;
}

.upload_pdf {
  width: 24px !important;
  height: 24px;
}

.highest_qualification_files > div {
  position: relative;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  height: 118px;
  background: rgba(0, 88, 162, 0.05);
  border: 2px dashed #add0ee;
  border-radius: 10px;
  display: flex;
  gap: 12px;
  justify-content: start;
  padding-left: 18px;
  align-items: center;
}

.preview {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.highest_qualification-first-file {
  width: 100%;
  padding: 0 18px;
  align-items: center;
  display: flex;
  gap: 10px;
}

.remove-uploaded {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: brown;
  height: 22px;
  background: aliceblue;
  font-size: 11px;
  padding: 0;
}

.title_modal {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  color: $color-black;
}

.close-modal {
  position: absolute;
  right: 15px;
}

.invite-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 139px;
  height: 47px;
  border-radius: 10px;
}

.upload-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.next {
  background: #0058a2;
  color: $color-white;
}

.prev {
  border: 1px solid #0095ab;
  background: $color-white;
  color: #0095ab;
}

.text-btn {
  font-style: normal;
  letter-spacing: 0.8px;
  font-weight: $fw-700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.modal-header {
  border: none;
}

.modal-footer {
  border: none;
}

.this_modal_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
.this_modal_body > span {
  margin-bottom: -0.8rem !important;
}
.title_app_form {
  font-style: normal;
  font-weight: $fw-600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  text-align: left;
  color: #0058a2;
}
.mobile_display_none {
  display: none;
}

.desktop_display_none {
  display: block;
}

.modal-dialog {
  //width: 100% !important;
  //height: 100vh !important;
  //min-width: 100% !important;
  //min-height: 100vh !important;
  margin: 0 auto;
}

.modal-header {
  @include d-flex-column-center;
  gap: 40px;
  padding-top: 30px;
}

.modal-header,
.modal-body {
  padding: 20px 32px;
}

.modal-content {
  width: 100% !important;
  border-radius: 0;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  background: $color-white;
  gap: 25px;
}

.modal-body {
  background: $color-white;
}

section {
  gap: 3px;
  display: flex;
  flex-direction: column;
}
.title-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 147.02%;
  color: #000000;
  text-align: left;
  width: 100%;
}
.form-label {
  margin-bottom: 0.2rem;
}

.declaration_title {
  font-weight: $fw-500;
  font-size: 12px;
  line-height: 147.02%;
  color: #d84a4a;
  text-align: left;
}

.signature-paid {
  width: 100%;
  height: 225px;
  background: rgba(0, 88, 162, 0.05);
  border: 2px dashed #add0ee;
  border-radius: 10px;
}

.reset {
  width: 100%;
  height: auto;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cccccc;
  border-radius: 3px;

  button {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 147.02%;
    background: $color-white;
    color: brown;
  }
}

.step-application-form {
  margin-top: 40px;
}

.next-button {
  margin-top: 60px;
}
.is-invalid {
  border: 1px solid #f64e4e !important;
  border-radius: 10px;
}
.invalid-feedback {
  color: #f64e4e;
}
</style>

<style lang="scss">
.v-calendar {
  width: 100% !important;
}
.v-calendar .input-field input {
  width: 100%;
  border: 1px solid #ced4da;
}
.v-calendar.left .content {
  width: 100% !important;
}

@media only screen and (min-width: 768px) {
  .v-calendar.left .content {
    justify-content: center;
  }
}

@media screen and (max-width: 320px) {
  .v-calendar .calendar .days-selection,
  .v-calendar .calendar .selected-field {
    padding: 0 !important;
  }
  //.v-calendar .input-field {
  //  position: relative;
  //  min-width: 140px;
  //  font-weight: var(--v-calendar-input-font-weight);
  //  width: calc(100% - 35px);
  //}
  //.v-calendar.left .content {
  //  width: calc(100% - 25px) !important;
  //}
}
</style>
