<template>
  <div class="main">
    <div v-if="!resetSuccessPassword" class="container_page">
      <div class="content">
        <div class="content-form">
          <div class="welcome_block">
            <div class="title_welcome text-center">Reset Password</div>
            <p>Please enter your new password below.</p>
          </div>
          <form action="#" @submit.prevent autocomplete="off" class="form-pwa">
            <div class="form_block">
              <div class="input_block">
                <div class="input_icon">
                  <password-lock />
                </div>
                <input
                  v-model="formData.password"
                  :type="view_password ? 'text' : 'password'"
                  autocomplete="off"
                  class="form__input"
                  placeholder="New Password"
                />
                <div class="show_pass" @click="showPassword">
                  <eye-icon v-if="!view_password" />
                  <eye-open-icon v-else />
                </div>
              </div>
              <div class="input_block">
                <div class="input_icon">
                  <password-lock />
                </div>
                <input
                  v-model="formData.password_confirm"
                  :type="view_confirm_password ? 'text' : 'password'"
                  autocomplete="off"
                  class="form__input"
                  placeholder="Confirm New Password"
                />
                <div class="show_pass" @click="showConfirmPassword">
                  <eye-icon v-if="!view_confirm_password" />
                  <eye-open-icon v-else />
                </div>
              </div>
            </div>
          </form>
          <div class="sign-and-social">
            <div class="btn">
              <button class="btn__sign-in" @click="updatePassword">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container_block">
      <div class="content-block">
        <div class="content-block__item">
          <div class="icon">
            <reset-password-icon />
          </div>
          <div class="title text-center">
            Your password was successfully changed!
          </div>
          <div class="btn">
            <button class="btn-sign-in" @click="goToSignIn">
              Back to Log in
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordLock from '@/components/svg/PasswordLock.vue';
import EyeIcon from '@/components/svg/EyeCloseIcon.vue';
import EyeOpenIcon from '@/components/svg/EyeOpenIcon.vue';
import { baseErrHandler, updatePassword } from '@/services/api';
import { errorMessage } from '@/helpers/messages';
import { rules } from '@/helpers/validate.helper';
import ResetPasswordIcon from '@/components/svg/ResetPasswordIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ResetPass',
  components: {
    ResetPasswordIcon,
    EyeOpenIcon,
    EyeIcon,
    PasswordLock,
  },
  async mounted() {
    await this.redirectAuthUser();
  },
  data() {
    return {
      formData: {
        password: '',
        password_confirm: '',
        token: null,
      },
      view_password: false,
      view_confirm_password: false,
      resetSuccessPassword: false,
    };
  },
  methods: {
    async redirectAuthUser() {
      if (this.isLogged) {
        await this.skipCheckAuthUser();
      }
    },
    async skipCheckAuthUser() {
      await this.$router.push('/dashboard');
    },
    showPassword() {
      this.view_password = !this.view_password;
    },
    showConfirmPassword() {
      this.view_confirm_password = !this.view_confirm_password;
    },
    async goToSignIn() {
      return await this.$router.push('/auth/log-in');
    },
    getQueryParam() {
      const route = this.$route;
      const {
        query: { token },
      } = route;
      if (token) {
        this.formData.token = token;
      }
    },
    async updatePassword() {
      if (!this.isValidFormRegistration()) {
        return;
      }
      this.getQueryParam();
      await baseErrHandler(async () => {
        const { user, token } = await updatePassword(this.formData);
        if (token && user) {
          this.resetSuccessPassword = true;
        }
      });
    },
    isValidFormRegistration() {
      if (!this.formData.password || !this.formData.password_confirm) {
        errorMessage('Please fill in all of the required fields.');
        return false;
      }
      if (this.formData.password) {
        for (let condition of rules()) {
          if (!condition.regex.test(this.formData.password)) {
            errorMessage(condition.message);
            return false;
          }
        }
      }
      if (this.formData.password !== this.formData.password_confirm) {
        errorMessage('Password is not match. Please try again.');
        return false;
      }
      return true;
    },
    clearForm() {
      this.formData.password = '';
      this.formData.password_confirm = '';
    },
  },
  computed: {
    ...mapGetters(['isLogged']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.container_page {
  @include container;
  min-height: 100vh;
  background-size: cover;
  background-image: url('@/assets/app_img/reset-pass-background.png');
  .content {
    min-height: 100vh;
    padding: 0 25px;
    .content-form {
      min-height: 100vh;
      position: relative;
      top: 110px;
      .welcome_block {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 50px;
        & div {
          font-weight: 700;
          font-size: 30px;
          line-height: 36px;
          color: $color-white;
        }
        & p {
          font-weight: 500;
          font-size: 16px;
          line-height: 147.02%;
          text-align: center;
          opacity: 0.4;
          color: $color-white;
        }
      }
      .form_block {
        .input_block {
          width: 100%;
          position: relative;
          margin-bottom: 17px;

          .form-label {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #222626;
            margin-bottom: 4px;
          }

          input {
            padding-left: 40px;
            width: 100%;
            height: 58px;
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid rgba(255, 255, 255, 0.18);
            border-radius: 17px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 147.02%;
            color: $color-white;
            text-align: start;

            &::placeholder {
              text-align: start;
              font-weight: 500;
              font-size: 16px;
              line-height: 147.02%;
              color: $color-white;
            }
          }

          .input_icon {
            position: absolute;
            left: 16px;
            top: 16px;
            min-width: 16px;
          }

          .show_pass {
            position: absolute;
            right: 16px;
            top: 16px;
            min-width: 16px;
          }

          .press_here {
            font-weight: 500;
            font-size: 14px;
            line-height: 147.02%;
            text-align: right;
            color: $color-white;
            margin-top: 17px;

            span {
              cursor: pointer;
              color: $color-white;
            }
          }
        }
      }
      .sign-and-social {
        display: flex;
        gap: 30px;
        flex-direction: column;
        margin-top: 20px;
        .btn {
          width: 100%;
          padding: 0;
          &__sign-in {
            width: 100%;
            height: 58px;
            border-radius: 15px;
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #0058a2;
            border: none;
            background: $color-white;
          }
        }
      }
    }
  }
}

.container_block {
  @include container;
  min-height: 100vh;
  background-size: cover;
  background-image: url('@/assets/app_img/rate-app-background.png');
  .content-block {
    min-height: 100vh;
    padding: 0 25px;
    position: relative;
    top: 110px;
  }
  .content-block__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 70px;
    div {
      height: auto;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: $fw-400;
      font-size: 26px;
      line-height: 31px;
      text-align: center;
      color: $color-white;
    }
    .btn {
      width: 100%;
      padding: 0;
    }
    .btn-sign-in {
      width: 100%;
      height: 58px;
      border-radius: 15px;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #0058a2;
      border: none;
      background: $color-white;
    }
  }
}

@media only screen and (min-width: 768px) {
  .container_page {
    background-size: contain;
  }
  .form-pwa,
  .sign-and-social {
    max-width: 540px;
    margin: 0 auto;
  }
}
</style>
