import { pwa_jwt_key, pwa_user } from '@/helpers/enums';
import { createStore } from 'vuex';

export default createStore({
  state: {
    isLoading: false,
    user: (() => {
      try {
        return JSON.parse(localStorage.getItem(pwa_user));
      } catch (e) {
        console.log(e);
        return null;
      }
    })(),
    isLogged: Boolean(
      (() => {
        try {
          return JSON.parse(localStorage.getItem(pwa_user));
        } catch (e) {
          console.log(e);
          return null;
        }
      })()?.id,
    ),
  },
  getters: {
    isLogged({ isLogged }) {
      return isLogged;
    },
    getUser({ user }) {
      return user;
    },
    isLoaded({ isLoading }) {
      return isLoading;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      if (user?.id) {
        localStorage.setItem(pwa_user, JSON.stringify(user));
        state.isLogged = true;
        return;
      }
      localStorage.removeItem(pwa_user);
      localStorage.removeItem(pwa_jwt_key);
      state.isLogged = false;
    },
    showLoader(state) {
      state.isLoading = true;
    },
    hideLoader(state) {
      state.isLoading = false;
    },
  },
  actions: {},
  modules: {},
});
