import axios from 'axios';
import { pwa_jwt_key } from '@/helpers/enums';

export default {
  formDataHeader() {
    return {
      'Content-Type': 'multipart/form-data',
    };
  },
  apiUrl() {
    return process.env.VUE_APP_API_URL;
  },
  uploadsUrl() {
    return process.env.VUE_APP_UPLOADS_URL;
  },
  async get(url, data = {}, headers = {}) {
    return await axios.get(url, { data, headers });
  },
  async getAuth(url, data = {}, headers = {}) {
    try {
      return await axios.get(url, {
        data,
        headers: Object.assign(this.getAuthHeader(), headers),
      });
    } catch (e) {
      if (e.response.status === 401) {
        await this.refreshToken();
        return await axios.get(url, {
          data,
          headers: Object.assign(this.getAuthHeader(), headers),
        });
      } else {
        throw e;
      }
    }
  },
  async post(url, data = {}, headers = {}) {
    return await axios.post(url, data, { headers: headers });
  },
  async postAuth(url, data = {}, headers = {}) {
    try {
      return await axios.post(url, data, {
        headers: Object.assign(this.getAuthHeader(), headers),
      });
    } catch (e) {
      if (e.response && e.response.status === 401) {
        await this.refreshToken();
        return await axios.post(url, data, {
          headers: Object.assign(this.getAuthHeader(), headers),
        });
      } else {
        throw e;
      }
    }
  },
  async delete(url, data = {}, headers = {}) {
    return await axios.delete(url, { data, headers });
  },
  async deleteAuth(url, data = {}, headers = {}) {
    try {
      return await axios.delete(url, {
        data,
        headers: Object.assign(this.getAuthHeader(), headers),
      });
    } catch (e) {
      if (e.response.status === 401) {
        await this.refreshToken();
        return await axios.get(url, {
          data,
          headers: Object.assign(this.getAuthHeader(), headers),
        });
      } else {
        throw e;
      }
    }
  },
  async refreshToken() {
    try {
      const response = await axios.get(`${this.apiUrl()}users/refresh_token`, {
        headers: this.getAuthHeader(),
      });
      const { token } = response?.data?.data || {};
      localStorage.setItem(pwa_jwt_key, token);
      return token;
    } catch (e) {
      console.log('ERROR:', e);
      throw e;
    }
  },
  getAuthHeader() {
    return { Authorization: `Bearer ${localStorage.getItem('rw_pwa_jwt')}` };
  },
};
