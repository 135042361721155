<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.45052 4.26352C6.27164 4.50987 4.49052 6.25302 4.22526 8.43194C3.99793 10.3267 4.88848 12.0509 6.30953 13.0172C6.68845 13.2636 6.91592 13.6804 6.91592 14.1351L6.91565 14.533H11.0272V14.1541C11.0272 13.7184 11.2357 13.2825 11.6146 13.0362C12.922 12.1837 13.7746 10.7058 13.7746 9.01941C13.7936 6.19621 11.3494 3.94151 8.45036 4.26362L8.45052 4.26352Z"
      fill="white"
    />
    <path
      d="M6.93481 16.6357C6.93481 16.8062 7.01055 16.9578 7.14324 17.0715L7.73058 17.5641C7.82534 17.6398 7.95791 17.6968 8.09061 17.6968H9.90963C10.0423 17.6968 10.1749 17.6588 10.2697 17.5641L10.857 17.0715C10.9897 16.9578 11.0654 16.8062 11.0654 16.6357V15.5747H6.9349L6.93481 16.6357Z"
      fill="white"
    />
    <path
      d="M8.99993 2.78526C9.32202 2.78526 9.58727 2.52001 9.58727 2.19793V0.587335C9.58727 0.265246 9.32201 0 8.99993 0C8.67785 0 8.4126 0.265256 8.4126 0.587335V2.17888C8.4126 2.52 8.65895 2.78526 8.99993 2.78526Z"
      fill="white"
    />
    <path
      d="M5.07797 3.41031C5.19163 3.59983 5.38116 3.7135 5.58958 3.7135C5.68435 3.7135 5.79801 3.6946 5.87374 3.63776C6.1579 3.46727 6.25265 3.10724 6.08217 2.84197L5.28638 1.45885C5.11588 1.17469 4.75585 1.07994 4.49059 1.25042C4.20643 1.42092 4.11167 1.78095 4.28216 2.04621L5.07797 3.41031Z"
      fill="white"
    />
    <path
      d="M1.42103 5.32358L2.80416 6.11937C2.89892 6.1762 2.99369 6.1951 3.08831 6.1951C3.29674 6.1951 3.48628 6.08144 3.59993 5.89191C3.77042 5.60776 3.65676 5.24774 3.3915 5.09612L2.00837 4.30047C1.72422 4.12997 1.3642 4.24364 1.21258 4.5089C1.04209 4.79305 1.13685 5.15307 1.42101 5.32357L1.42103 5.32358Z"
      fill="white"
    />
    <path
      d="M2.76635 9.01946C2.76635 8.69738 2.50109 8.43213 2.17902 8.43213H0.587335C0.265246 8.43213 0 8.69739 0 9.01946C0 9.34155 0.265256 9.6068 0.587335 9.6068H2.17888C2.52 9.6068 2.76635 9.34154 2.76635 9.01946Z"
      fill="white"
    />
    <path
      d="M17.4128 8.41211H15.8212C15.4991 8.41211 15.2339 8.67737 15.2339 8.99944C15.2339 9.32152 15.4991 9.58678 15.8212 9.58678H17.4128C17.7349 9.58678 18.0001 9.32152 18.0001 8.99944C18.0001 8.67737 17.7348 8.41211 17.4128 8.41211Z"
      fill="white"
    />
    <path
      d="M14.8927 6.17645C14.9874 6.17645 15.1011 6.15755 15.1768 6.10072L16.5599 5.30493C16.8441 5.13444 16.9389 4.77441 16.7684 4.50914C16.5979 4.22499 16.2379 4.13023 15.9726 4.30071L14.5895 5.0965C14.3053 5.267 14.2106 5.62703 14.381 5.89229C14.4947 6.08169 14.6842 6.17645 14.8927 6.17645L14.8927 6.17645Z"
      fill="white"
    />
    <path
      d="M12.1076 3.6192C12.2023 3.67603 12.2971 3.69493 12.3917 3.69493C12.6001 3.69493 12.7897 3.58127 12.9033 3.39174L13.6991 2.00862C13.8696 1.72446 13.7559 1.36444 13.4907 1.21283C13.2065 1.04233 12.8465 1.1371 12.6949 1.42126L11.8991 2.80438C11.7286 3.08867 11.8234 3.44856 12.1075 3.61919L12.1076 3.6192Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'HubIcon',
};
</script>

<style scoped></style>
