<template>
  <div
    class="modal fade"
    id="enquireModal"
    ref="enquireModal"
    tabindex="-1"
    aria-labelledby="enquireModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex">
            <div class="title_modal">Enquire Form</div>
            <button class="btn close-modal" @click="closeModal">
              <close-input-icon />
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class="this_modal_body">
            <section class="course_personal--info">
              <BaseInput
                v-model="form.email"
                @clear-form="clearValidForm"
                :id="`enquire`"
                :label="`Email from`"
                :validInput="validForm.email"
              />
              <BaseInput
                v-model="form.subject"
                @clear-form="clearValidForm"
                :id="`enquire`"
                :label="`Email subject`"
                :validInput="validForm.subject"
              />
              <BaseTextarea
                v-model="form.body_text"
                :validInput="validForm.body_text"
                :id="'enquire'"
                :label="`Email body`"
                :rows="8"
              />
            </section>
            <div class="step-application-form d-flex justify-content-between">
              <BaseButtonPrevForm :title="`Back`" @prev-step="closeModal" />
              <BaseButtonForm
                :title="`Submit`"
                :form-input="isComplete()"
                @next-step="next"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from 'bootstrap';
import BaseInput from '@/components/forms/BaseInput.vue';
import { incorrect_email_error, require_input_error } from '@/helpers/enums';
import BaseButtonForm from '@/components/forms/BaseButtonForm.vue';
import BaseButtonPrevForm from '@/components/forms/BaseButtonPrevForm.vue';
import CloseInputIcon from '@/components/svg/CloseInputIcon.vue';
import BaseTextarea from '@/components/forms/BaseTextarea.vue';
import { baseErrHandler, sendEnquireToCourse } from '@/services/api';
import { isInValidEmail } from '@/helpers/validate.helper';

export default {
  name: 'EnquireFormModal',
  components: {
    BaseTextarea,
    CloseInputIcon,
    BaseButtonPrevForm,
    BaseButtonForm,
    BaseInput,
  },
  inject: ['gtag'],
  emits: ['close-enquire'],
  data() {
    return {
      enquireModal: null,
      form: {
        subject: null,
        body_text: null,
        course_id: null,
        user_id: null,
        email: null,
      },
      validForm: {
        subject: null,
        body_text: null,
        course_id: null,
        user_id: null,
        email: null,
      },
      valid: false,
    };
  },
  async mounted() {
    this.enquireModal = new Modal(this.$refs.enquireModal);
  },
  methods: {
    bindToEnquireInfo(data) {
      const { title, name, surname } = data;

      for (const item in this.form) {
        this.form[item] = data[item];
      }
      this.form.subject = `App enquire: ${title} ${name || ''} ${
        surname || ''
      }`.trim();
    },
    closeModal() {
      this.clearForm();
      this.enquireModal?.hide();
    },
    show() {
      this.enquireModal?.show();
    },
    async next() {
      if (!this.validFormData()) {
        return;
      }

      await baseErrHandler(async () => {
        const enquireForm = { ...this.form };
        const { success } = await sendEnquireToCourse(enquireForm);
        if (success) {
          this.closeModal();
          this.handleBuy(enquireForm);
          this.$emit('close-enquire');
        }
      });
    },
    handleBuy(form) {
      const {
        body_text = '',
        course_id = null,
        user_id = null,
        email = '',
      } = form;
      const result = `New apply in course: ${course_id}, user: ${user_id} - and body text: ${body_text} - and email: ${email}`;
      this.gtag.event('new-enquire-course', {
        'event-label': 'new-enquire-course',
        value: result,
      });
    },
    validFormData() {
      const { subject, body_text, email } = this.form;
      let is_valid = true;

      if (!subject) {
        this.validForm.subject = require_input_error;
        is_valid = false;
      }
      if (!body_text) {
        this.validForm.body_text = require_input_error;
        is_valid = false;
      }
      if (!email) {
        this.validForm.email = require_input_error;
        is_valid = false;
      }
      if (email) {
        if (isInValidEmail(email)) {
          this.validForm.email = incorrect_email_error;
          is_valid = false;
        }
      }

      this.valid = !this.valid;
      return is_valid;
    },
    isComplete() {
      return this.form.subject !== '' && this.form.body_text !== '';
    },
    clearValidForm() {
      this.validForm.subject = null;
      this.validForm.body_text = null;
    },
    clearForm() {
      this.form.subject = null;
      this.form.body_text = null;
    },
  },
  beforeUnmount() {
    /* Cleaning the body from a frozen scroll  */
    document.body.style.overflow = 'auto';
    this.inviteModal?.hide();
    this.inviteModal?.dispose();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.mobile_display_none {
  display: block;
}

.desktop_display_none {
  display: none;
}

.title_modal {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  color: $color-black;
}

.close-modal {
  position: absolute;
  right: 15px;
}

.invite-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 139px;
  height: 47px;
  border-radius: 10px;
}

.next {
  background: #0058a2;
  color: $color-white;
}

.prev {
  border: 1px solid #0095ab;
  background: $color-white;
  color: #0095ab;
}

.text-btn {
  font-style: normal;
  letter-spacing: 0.8px;
  font-weight: $fw-700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.modal-header {
  border: none;
}

.modal-footer {
  border: none;
}

.this_modal_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
.this_modal_body > span {
  margin-bottom: -0.8rem !important;
}
.title_app_form {
  font-style: normal;
  font-weight: $fw-600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  text-align: left;
  color: #0058a2;
}
.mobile_display_none {
  display: none;
}

.desktop_display_none {
  display: block;
}

.modal-dialog {
  //width: 100% !important;
  //height: 100vh !important;
  //min-width: 100% !important;
  //min-height: 100vh !important;
  margin: 0 auto;
}

.modal-header {
  @include d-flex-column-center;
  gap: 40px;
  padding-top: 30px;
}

.modal-header,
.modal-body {
  padding: 20px 32px;
}

.modal-content {
  width: 100% !important;
  border-radius: 0;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  background: $color-white;
  gap: 25px;
}

.modal-body {
  background: $color-white;
}

section {
  gap: 3px;
  display: flex;
  flex-direction: column;
}
.title-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 147.02%;
  color: #000000;
  text-align: left;
  width: 100%;
}
.form-label {
  margin-bottom: 0.2rem;
}

.next-button {
  margin-top: 60px;
}
.is-invalid {
  border: 1px solid #f64e4e !important;
  border-radius: 10px;
}
.invalid-feedback {
  color: #f64e4e;
}
</style>
