<template>
  <parent-component>
    <div class="information">
      <div class="question">
        <p class="title">General questions</p>
        <div class="d-flex flex-row align-items-baseline gap-3">
          <p class="title-block m-0">How can I contact Rand Water Academy?</p>
          <div
            class="button-up"
            @click="openChildBlock()"
            :class="{ up: open }"
          ></div>
        </div>
        <div class="block-info" v-if="open">
          We have numerous ways for you to get in touch with us. See the list of
          options below: Call us: Email: admissions@rwa.co.za Facebook:
          https://www.facebook.com/GetSmarterShortCourses/ LinkedIn:
          https://www.linkedin.com/school/getsmarter/
        </div>
      </div>
    </div>
  </parent-component>
</template>

<script>
import ParentComponent from '@/components/ParentComponent.vue';

export default {
  name: 'FaqsTemplate',
  components: { ParentComponent },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    openChildBlock() {
      this.open = !this.open;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins.scss';

.information {
  padding-top: 40px;
}
.question {
  font-family: 'Inter', sans-serif;
  font-style: normal;

  .title {
    height: 29px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #0058a2;
  }
}
.block-info {
  margin-top: 10px;
  min-height: 178px;
  height: auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 147.02%;
  color: #000000;
  opacity: 0.6;
}
.title-block {
  min-height: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 147.02%;
  color: #000000;
}

.open {
  min-width: 18px;
  height: 18px;
  background: #0070bb;
  border-radius: 40px;
  position: relative;
}

.button-up {
  position: relative;
  padding: 6px;
  border-radius: 50%;
  transition: all 0.2s linear;
  min-width: 18px;
  min-height: 18px;
  background: #0070bb;
  transform: rotate(225deg);
}

.button-up::after {
  content: '';
  position: absolute;
  z-index: 11;
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}

.up {
  transform: rotate(45deg);
  --webkit-transform: rotate(-135deg);
}
</style>
