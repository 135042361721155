<template>
  <svg
    width="47"
    height="39"
    viewBox="0 0 47 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.7329 10.9869L24.4967 0.311664C23.7087 -0.103888 22.7665 -0.103888 21.9788 0.311664L1.74254 10.9869C0.85727 11.4538 0.303314 12.3727 0.303314 13.3735C0.303314 14.1692 0.654337 14.912 1.24287 15.4183V22.9512H0.907158C0.674157 22.9305 0.466832 23.0987 0.439917 23.331L0.260295 24.8804C0.174369 25.6209 0.088556 26.3613 0.00285557 27.1021C-0.0104331 27.2171 0.0230138 27.3325 0.0957634 27.4227C0.168513 27.5128 0.274372 27.57 0.389465 27.5808H3.84305C3.95814 27.5698 4.064 27.5128 4.13675 27.4227C4.20938 27.3326 4.24283 27.2172 4.22954 27.1021L3.79237 23.3312C3.76534 23.099 3.55835 22.9305 3.32535 22.9514H2.98953V16.4178L6.04862 18.0316V33.1314C6.04862 34.1683 6.68118 35.1 7.64483 35.4825C12.739 37.5049 17.9851 38.5303 23.2372 38.5305C23.2379 38.5305 23.2379 38.5305 23.2387 38.5305C28.4891 38.5305 33.7356 37.5051 38.831 35.4827C39.7949 35.1003 40.4277 34.1683 40.4277 33.1314C40.4271 26.6673 40.4271 25.2446 40.4274 19.7688L40.4276 18.0316L44.7332 15.76C45.6185 15.2931 46.1725 14.3744 46.1725 13.3737C46.1721 12.3726 45.618 11.4538 44.7329 10.9869ZM23.2384 33.4714C23.2379 33.4714 23.2372 33.4714 23.237 33.4714C19.1706 33.4713 15.0982 32.767 11.1074 31.3755V20.6999L21.9789 26.435C22.3728 26.6428 22.8051 26.7468 23.2376 26.7468C23.6703 26.7468 24.1028 26.6429 24.4967 26.435L35.3676 20.6999C35.3675 24.9394 35.3674 26.6807 35.3677 31.3757C31.3759 32.7674 27.3037 33.4714 23.2384 33.4714Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'GraduationHatIcon',
};
</script>

<style scoped></style>
