<template>
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3325 3.09668L10.5929 8.8363C9.95288 9.47466 9.08585 9.83315 8.18192 9.83315C7.27799 9.83315 6.41096 9.47466 5.77098 8.8363L0.031364 3.09668C0.0218185 3.20441 0 3.30191 0 3.40896V11.5909C0.00108264 12.4947 0.360606 13.3612 0.999708 14.0003C1.63881 14.6394 2.50531 14.9989 3.40913 15H12.9547C13.8585 14.9989 14.725 14.6394 15.3641 14.0003C16.0032 13.3612 16.3628 12.4947 16.3638 11.5909V3.40896C16.3638 3.30191 16.342 3.20441 16.3325 3.09668Z"
      fill="white"
    />
    <path
      d="M9.6288 7.87237L15.8566 1.64388C15.5549 1.14364 15.1294 0.72958 14.6211 0.44164C14.1128 0.1537 13.5389 0.00159593 12.9548 0H3.40918C2.825 0.00159593 2.2511 0.1537 1.74281 0.44164C1.23453 0.72958 0.809018 1.14364 0.507324 1.64388L6.73513 7.87237C7.11939 8.25509 7.63963 8.46997 8.18197 8.46997C8.7243 8.46997 9.24454 8.25509 9.6288 7.87237Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'EmailIcon',
};
</script>

<style lang="scss" scoped></style>
