export const socialLinkList = [
  {
    id: 1,
    img: `img/social_link/facebook.png`,
    url: 'https://www.facebook.com/RandWater/',
  },
  {
    id: 2,
    img: `img/social_link/instagram.png`,
    url: 'https://www.instagram.com/randwater/?hl=en',
  },
  {
    id: 3,
    img: `img/social_link/twitter.png`,
    url: 'https://twitter.com/Rand_Water',
  },
  {
    id: 4,
    img: `img/social_link/youTube.png`,
    url: 'https://www.youtube.com/channel/UC-V1DiwkJyG9JDkIlL_pUQA',
  },
  {
    id: 5,
    img: `img/social_link/linkedin.png`,
    url: 'https://www.linkedin.com/company/rand-water/',
  },
];

export const eventTitleAttend = 'You successfully attend to event.';
export const courseTitleApply = 'You successfully applied to the course.';
