<template>
  <div class="events-block" v-if="isNotEmptyData">
    <event-details-template :events="data" :type="type" />
  </div>
  <div
    v-else
    class="events-block-empty align-items-center justify-content-center"
  >
    <empty-event-block :title="`There are no recent events.`" />
  </div>
</template>

<script>
import EventDetailsTemplate from '@/components/events/EventDetailsTemplate.vue';
import EmptyEventBlock from '@/components/EmptyEventBlock.vue';

export default {
  name: 'RecentEvents',
  components: { EmptyEventBlock, EventDetailsTemplate },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default() {
        return 'recent';
      },
    },
  },
  computed: {
    isNotEmptyData() {
      return !!this.data.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.events-block::-webkit-scrollbar {
  display: none;
}
.events-block,
.events-block-empty {
  min-height: 100vh;
  @include event-block;
}
.events-block-empty {
  height: calc(100vh - 195px);
}
</style>
