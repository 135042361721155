<template>
  <svg
    width="15"
    height="22"
    viewBox="0 0 15 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.625 0H5.04167C3.70498 0.00145582 2.42346 0.533097 1.47828 1.47828C0.533097 2.42346 0.00145582 3.70498 0 5.04167V16.9583C0.00145582 18.295 0.533097 19.5765 1.47828 20.5217C2.42346 21.4669 3.70498 21.9985 5.04167 22H9.625C10.9617 21.9985 12.2432 21.4669 13.1884 20.5217C14.1336 19.5765 14.6652 18.295 14.6667 16.9583V5.04167C14.6652 3.70498 14.1336 2.42346 13.1884 1.47828C12.2432 0.533097 10.9617 0.00145582 9.625 0V0ZM5.04167 2.75H9.625C10.2328 2.75 10.8157 2.99144 11.2455 3.42121C11.6752 3.85098 11.9167 4.43388 11.9167 5.04167V16.5H2.75V5.04167C2.75 4.43388 2.99144 3.85098 3.42121 3.42121C3.85098 2.99144 4.43388 2.75 5.04167 2.75ZM7.33333 20.1667C7.09022 20.1667 6.85706 20.0701 6.68515 19.8982C6.51324 19.7263 6.41667 19.4931 6.41667 19.25C6.41667 19.0069 6.51324 18.7737 6.68515 18.6018C6.85706 18.4299 7.09022 18.3333 7.33333 18.3333C7.57645 18.3333 7.80961 18.4299 7.98151 18.6018C8.15342 18.7737 8.25 19.0069 8.25 19.25C8.25 19.4931 8.15342 19.7263 7.98151 19.8982C7.80961 20.0701 7.57645 20.1667 7.33333 20.1667Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'PhoneIcon',
};
</script>

<style lang="scss" scoped></style>
