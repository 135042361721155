<template>
  <div class="important-block">
    <p class="title-important">Very Important:</p>
    <div class="item" v-for="item in items" :key="item.id">
      <div class="d-flex">
        <done-icon />
      </div>
      <p>{{ item.title }}</p>
    </div>
  </div>
</template>

<script>
import DoneIcon from '@/components/svg/DoneIcon.vue';

export default {
  name: 'CustomImportantBlockCourse',
  components: { DoneIcon },
  data() {
    return {
      items: [
        {
          id: 1,
          title:
            'Please note the ‘Application requirements and instructions’ in submission of your application.',
        },
        {
          id: 2,
          title: 'Incomplete forms will not be processed.',
        },
        {
          id: 3,
          title:
            'Please allow for between 2 – 3 weeks for your application to be processed.',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins.scss';
@import '@/assets/_variables.scss';

.important-block {
  width: 100%;
  height: auto;
  background: #e4f1fb;
  border: 1px dashed #0058a2;
  border-radius: 10px;
  @include d-flex-column;
  text-align: left;
  padding: 20px;
  gap: 10px;

  .title-important {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #0058a2;
    opacity: unset;
  }
  .item {
    @include d-flex-row-center;
    gap: 5px;
  }
  p {
    font-weight: $fw-500;
    font-size: 12px;
    line-height: 15px;
    color: $color-black;
    opacity: 0.4;
    margin: 0;
  }
}
</style>
