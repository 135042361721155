<template>
  <courses-template>
    <div class="content-main">
      <hr class="line_menu" />
      <div class="title_and_btn-back">
        <div class="button-back" @click="redirectBackLink()">
          <div class="arrow">
            <svg
              width="11"
              height="18"
              viewBox="0 0 11 18"
              fill="#ffffff"
              stroke="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.59558 16.7177C9.89336 16.4244 9.89319 15.944 9.59522 15.651L3.95662 10.1049C3.55826 9.71308 3.55826 9.07085 3.95662 8.67903L9.59535 3.13285C9.89325 2.83983 9.89342 2.35962 9.59572 2.0664C9.30429 1.77937 8.83637 1.77958 8.54521 2.06688L1.83942 8.68372C1.44244 9.07543 1.4426 9.71618 1.83977 10.1077L8.54508 16.7174C8.83635 17.0046 9.30419 17.0047 9.59558 16.7177Z"
                fill="#ffffff"
                stroke-width="2"
              ></path>
            </svg>
          </div>
        </div>
        <p class="course-title">Free Courses</p>
      </div>
      <div
        class="item"
        v-for="(course, index) in list_free_courses"
        :key="index"
      >
        <router-link :to="{ name: 'CourseDetails', params: { id: course.id } }">
          <div class="category">
            <div
              class="applied_course_status"
              v-if="isCheckCompletedCourse(course)"
            >
              <span><applied-status-icon /></span> Applied
            </div>
            <img :src="course?.image_url" alt="category" />
            <div class="description-course">
              <div class="hat">
                <graduation-hat-icon />
              </div>
              <div class="inform">
                <p class="title">Open CourseWare</p>
                <p class="desc">View Courses</p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </courses-template>
</template>

<script>
import CoursesTemplate from '@/components/courses/CoursesTemplate.vue';
import GraduationHatIcon from '@/components/svg/GraduationHatIcon.vue';
import { coursesPWSList } from '@/services/api';
import { course_status_free, partner_link_free } from '@/helpers/enums';
import { mapGetters } from 'vuex';
import AppliedStatusIcon from '@/components/svg/AppliedStatusIcon.vue';

export default {
  name: 'CoursesFreeTemplate',
  components: {
    AppliedStatusIcon,
    GraduationHatIcon,
    CoursesTemplate,
  },
  data() {
    return {
      list_free_courses: [],
      courses_count: [],
    };
  },
  async mounted() {
    await this.getSponsoredCourseList();
  },
  methods: {
    partner_link_free() {
      return partner_link_free;
    },
    async redirectBackLink() {
      return await this.$router.push('/courses');
    },
    async getSponsoredCourseList() {
      const { list, total_count } = await coursesPWSList({
        funding_type: course_status_free,
      });
      this.list_free_courses = list;
      this.courses_count = total_count;
    },
    isCheckCompletedCourse(course) {
      const userIds = course?.applicants?.map(({ user_id }) => user_id) || [];
      return userIds.includes(this?.getUser?.id);
    },
  },
  computed: {
    ...mapGetters(['getUser']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.content-main::-webkit-scrollbar {
  display: none;
}

.line_menu {
  width: 100%;
  opacity: 0.1;
  border: 1px solid #000000;
  @include reset-m-p;
}

.title_and_btn-back {
  @include d-flex-center;
  gap: 15px;
  width: 100%;
}

.button-back {
  cursor: pointer;
  min-width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background: #0095ab;
}

.arrow {
  margin-left: -2px;
  margin-bottom: 2px;
}

.content-main {
  @include d-flex-column;
  gap: 20px;
  padding: 80px 25px 20px;
  overflow-y: scroll;
  .course-title {
    width: 100%;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: $color-black;
    text-align: left;
    @include reset-m-p;
  }
  .applied_course_status {
    @include appliedLabel;
    z-index: 1;
  }
  .item {
    width: 100%;
    position: relative;
    .category {
      img {
        width: 100%;
        height: 173px;
        opacity: 0.8;
        border-radius: 20px;
      }
      .description-course {
        position: absolute;
        top: 40%;
        left: 24px;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 15px;
        width: auto;

        .hat {
          background: rgba(14, 131, 198, 0.933);
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 89px;
          height: 89px;
        }
        .inform {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .title {
            font-weight: $fw-500;
            font-size: 17px;
            line-height: 21px;
            letter-spacing: 0.015em;
            color: $color-white;
            margin: 0;
          }
          .desc {
            background: rgb(0, 149, 171);
            border-radius: 40px;
            font-weight: $fw-600;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.02em;
            color: $color-white;
            width: 90px;
            padding: 5px;
            margin: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .main {
    overflow: auto;
  }
  .content-main {
    gap: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .content-main .item {
    width: 32%;
    position: relative;
  }
  .content-main .item .category {
    height: 100%;
  }
  .content-main .item .category img {
    height: 250px;
  }
  .content-main .item .category .course-information {
    justify-content: space-between;
    height: 100%;
  }
  .content-main .item .category .course-information .date-info p,
  .content-main .item .category .course-information .cancel-info p {
    width: initial;
    font-size: 15px;
  }
  .content-main .item .category .course-information .date-info span,
  .content-main .item .category .course-information .cancel-info span {
    font-size: 14px;
  }
  .cancel-info {
    justify-content: space-between;
  }
  .content-main .item .category .course-information .main-info .desc {
    font-size: 17px;
  }
  .content-main .item .category .course-information .main-info .title {
    font-size: 24px;
    line-height: 34px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .main {
    overflow: auto;
  }
  .content-main {
    gap: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .content-main .item {
    width: 48%;
    position: relative;
  }
  .content-main .item .category {
    height: 100%;
  }
  .content-main .item .category img {
    height: 250px;
  }
  .content-main .item .category .course-information {
    justify-content: space-between;
    height: 100%;
  }
  .content-main .item .category .course-information .date-info p,
  .content-main .item .category .course-information .cancel-info p {
    width: initial;
    font-size: 14px;
  }
  .content-main .item .category .course-information .date-info span,
  .content-main .item .category .course-information .cancel-info span {
    font-size: 13px;
  }
  .cancel-info {
    justify-content: space-between;
  }
  .content-main .item .category .course-information .main-info .desc {
    font-size: 15px;
  }
  .content-main .item .category .course-information .main-info .title {
    font-size: 22px;
    line-height: 30px;
  }
  .content-main .item .category .course-information .line_menu {
    width: 95%;
  }
}
</style>
