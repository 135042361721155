<template>
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.69396 1.78361C8.10198 1.37558 8.10198 0.714043 7.69396 0.306018C7.28593 -0.102006 6.62439 -0.102006 6.21637 0.306019L3.99992 2.52247L1.78363 0.306176C1.37561 -0.101848 0.714068 -0.101848 0.306043 0.306176C-0.101982 0.714201 -0.101982 1.37574 0.306043 1.78376L2.52233 4.00006L0.306019 6.21637C-0.102006 6.62439 -0.102006 7.28593 0.306018 7.69396C0.714043 8.10198 1.37558 8.10198 1.78361 7.69396L3.99992 5.47764L6.21639 7.69412C6.62442 8.10214 7.28596 8.10214 7.69398 7.69412C8.10201 7.28609 8.10201 6.62455 7.69398 6.21653L5.47751 4.00005L7.69396 1.78361Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'RemoveInputFileIcon',
};
</script>

<style scoped></style>
