<template>
  <button
    v-if="avatar"
    v-show="!blockedForm"
    @click="removeFile"
    class="remove"
  >
    X
  </button>
  <img
    :class="{ file_image: checkInputFile() }"
    :src="getUrlImage"
    alt="avatar"
  />
  <label for="file-input" v-show="!blockedForm" v-if="!avatar">
    <span class="upload-icon">
      <upload-ava-icon />
    </span>
  </label>
  <input
    type="file"
    id="file-input"
    @click="$refs.file.click()"
    @change="handleFileChange($event)"
    ref="file"
    accept="image/*"
  />
</template>

<script>
import UploadAvaIcon from '@/components/svg/UploadAvaIcon.vue';
import { isValidFileSize, isValidFileType } from '@/helpers/validate.helper';
import { file_size_validation, file_type_validation } from '@/helpers/enums';
import { errorMessage } from '@/helpers/messages';

export default {
  name: 'FileUpload',
  components: { UploadAvaIcon },
  emits: ['fileUploaded', 'fileToRemove'],
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    avatar: {
      type: String,
      default() {
        return null;
      },
    },
    blockedForm: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      errors: [],
      file: {
        url: '',
        isImage: false,
        isUploaded: false,
      },
    };
  },
  methods: {
    handleFileChange(e) {
      const file = e.target.files[0];
      if (e.target.files && file) {
        if (this.isValidFile(file) === 0) {
          const fileReader = new FileReader();
          fileReader.addEventListener('load', () => {
            this.file.url = fileReader.result;
            this.file.isImage = true;
            this.file.isUploaded = true;
            this.sendDataToParent();
          });
          fileReader.readAsDataURL(file);
          this.resetFileInput();
        }
        this.errors = [];
      }
      this.resetFileInput();
    },
    isValidFile(file) {
      if (isValidFileSize(Math.round((file.size / 1024 / 1024) * 100) / 100)) {
        this.errors.push(['File size']);
        errorMessage(file_size_validation);
      }
      if (!isValidFileType(file.name.split('.').pop())) {
        this.errors.push(['File type']);
        errorMessage(file_type_validation);
      }
      return this.errors.length;
    },
    resetFileInput() {
      this.file = {
        url: '',
        isImage: false,
        isUploaded: false,
      };
      this.$refs.file.value = null;
    },
    sendDataToParent() {
      this.$emit('fileUploaded', this.file);
    },
    removeFile() {
      this.$emit('fileToRemove', undefined);
      this.resetFileInput();
    },
    checkInputFile() {
      return this.avatar || this.file.url;
    },
  },
  computed: {
    getUrlImage() {
      return this.avatar || this.file.url
        ? this.avatar || this.file.url
        : require('@/assets/default_photo_user.png');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';

.remove {
  top: 0;
  right: -15px;
  position: absolute;
  width: 32px;
  color: white;
  height: 32px;
  background: #f78d8d;
  border: 3px solid $color-white;
  border-radius: 40px;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}
.file_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.upload-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
span {
  bottom: 0;
  right: 0;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #0095ab;
  border: 3px solid $color-white;
  border-radius: 40px;
}
input[type='file'] {
  display: none;
}
</style>
