<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    :fill="setColor"
  >
    <path
      d="M21.4631 18.8707L16.8091 14.2167C17.7707 12.7675 18.3335 11.0322 18.3335 9.16676C18.3335 4.11221 14.2213 0 9.16676 0C4.11221 0 0 4.11221 0 9.16676C0 14.2213 4.11221 18.3335 9.16676 18.3335C11.0322 18.3335 12.7675 17.7707 14.2167 16.8091L18.8707 21.4631C19.5857 22.179 20.748 22.179 21.4631 21.4631C22.179 20.7471 22.179 19.5866 21.4631 18.8707ZM2.75003 9.16676C2.75003 5.62839 5.62839 2.75003 9.16676 2.75003C12.7051 2.75003 15.5835 5.62839 15.5835 9.16676C15.5835 12.7051 12.7051 15.5835 9.16676 15.5835C5.62839 15.5835 2.75003 12.7051 2.75003 9.16676Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon',
  props: {
    search_style: {
      type: Object,
      default() {
        return {
          fill: '#ffffff',
        };
      },
    },
  },
  data() {
    return {
      fill: this.search_style,
    };
  },
  computed: {
    setColor() {
      return Object.values(this.fill).toString();
    },
  },
};
</script>

<style scoped></style>
