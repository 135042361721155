<template>
  <div class="main">
    <div class="slot">
      <header-control-button
        :title="` `"
        :user="getUser"
        :line_style="{ background: background }"
        :color="{ color: color }"
        @openSidebar="openSidebarMenu"
        class="control-header"
      />
      <div
        class="content-main d-flex align-items-center justify-content-center"
      >
        <div class="logo">
          <img :src="require('@/assets/rate-app.png')" alt="logo" />
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center gap-3"
        >
          <div class="title">Rate the App</div>
          <div class="description">
            On a scale of 1 – 5, how would you rate your overall experience on
            the app
          </div>
          <div class="star_range">
            <ul class="list">
              <li
                :key="star"
                v-for="star in maxStars"
                :class="{ active: star <= rating }"
                @click="rate(star)"
                class="star"
              >
                <svg
                  width="33"
                  height="31"
                  viewBox="0 0 33 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M31.773 13.7672C32.9749 12.5955 32.3117 10.5543 30.6506 10.3129L22.9692 9.19668C22.3096 9.10085 21.7393 8.6866 21.4444 8.08879L18.0094 1.12879C17.2666 -0.376262 15.1204 -0.376262 14.3775 1.12879L10.9423 8.0894C10.6474 8.68722 10.0772 9.10147 9.41751 9.19729L1.73613 10.3133C0.0751152 10.5546 -0.588134 12.5959 0.613689 13.7675L6.17177 19.1853C6.64903 19.6504 6.86685 20.3207 6.75414 20.9778L5.44194 28.6281C5.15825 30.2825 6.89464 31.544 8.38021 30.7628L15.2507 27.1509C15.8407 26.8408 16.5456 26.8408 17.1355 27.1509L24.006 30.7628C25.4918 31.5437 27.228 30.2822 26.9443 28.6281L25.6321 20.9778C25.5194 20.3207 25.7372 19.6506 26.2145 19.1853L31.773 13.7672Z"
                    :fill="star <= rating ? 'star' : '#AABFCF'"
                  />
                </svg>
              </li>
            </ul>
          </div>
        </div>
        <form action="#" @submit.prevent autocomplete="off" id="rate-app__form">
          <BaseInput
            v-model="form.recommendations"
            @clear-form="clearValidForm"
            :label="`Would you recommend the app to your friends?`"
            :validInput="validForm.recommendations"
            :font-size="{ fontSize: 14 + 'px' }"
            :font-weight="{ fontWeight: 500 }"
          />
          <BaseInput
            v-model="form.wish_changes"
            @clear-form="clearValidForm"
            :label="`What changes would you make to the app?`"
            :validInput="validForm.wish_changes"
            :font-size="{ fontSize: 14 + 'px' }"
            :font-weight="{ fontWeight: 500 }"
          />
          <BaseInput
            v-model="form.challenges"
            @clear-form="clearValidForm"
            :label="`What are the challenges you experience on the app?`"
            :validInput="validForm.challenges"
            :font-size="{ fontSize: 14 + 'px' }"
            :font-weight="{ fontWeight: 500 }"
          />
          <BaseInput
            v-model="form.open_to_interview"
            @clear-form="clearValidForm"
            :label="`Would you be open to a 10 minute interview on the course you took and your overall learining experience?`"
            :validInput="validForm.open_to_interview"
            :font-size="{ fontSize: 14 + 'px' }"
            :font-weight="{ fontWeight: 500 }"
          />
        </form>
        <div class="step-application-form d-flex justify-content-center">
          <BaseButtonForm
            :title="`Submit Feedback`"
            :form-input="isComplete()"
            @next-step="next"
          />
        </div>
      </div>
      <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import SlideMenu from '@/components/SlideMenu.vue';
import BaseInput from '@/components/forms/BaseInput.vue';
import BaseButtonForm from '@/components/forms/BaseButtonForm.vue';
import {
  max_description_input,
  max_size_description_error,
  require_input_error,
} from '@/helpers/enums';
import { baseErrHandler, savedRateAppUser } from '@/services/api';

export default {
  name: 'RateAppTemplate',
  components: {
    BaseButtonForm,
    BaseInput,
    SlideMenu,
    HeaderControlButton,
  },
  data() {
    return {
      isOpen: false,
      color: '#182337',
      background: '#64748B',
      form: {
        rating: 1,
        recommendations: '',
        wish_changes: '',
        challenges: '',
        open_to_interview: '',
      },
      validForm: {
        rating: null,
        recommendations: null,
        wish_changes: null,
        challenges: null,
        open_to_interview: null,
      },
      maxStars: 5,
      rating: 0,
    };
  },
  methods: {
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
    rate(star) {
      if (typeof star === 'number' && star <= this.maxStars && star >= 0)
        this.rating = this.rating === star ? star - 1 : star;
    },
    next() {
      if (!this.checkDataForm()) {
        return;
      }
      this.savedRateApp();
    },
    async savedRateApp() {
      const rateAppForm = this.parsedForm();
      await baseErrHandler(async () => {
        const { success } = await savedRateAppUser(rateAppForm);
        if (success) {
          this.$router.push('/rate-app-success');
        }
      });
    },
    parsedForm() {
      this.form.rating = this.rating;

      return { ...this.form };
    },
    checkDataForm() {
      const { recommendations, wish_changes, challenges, open_to_interview } =
        this.form;
      let is_valid = true;
      if (this.rating === 0) {
        this.validForm.rating = require_input_error;
        is_valid = false;
      }
      if (recommendations && recommendations?.length > max_description_input) {
        this.validForm.recommendations = max_size_description_error;
        is_valid = false;
      }
      if (wish_changes && wish_changes?.length > max_description_input) {
        this.validForm.wish_changes = max_size_description_error;
        is_valid = false;
      }
      if (challenges && challenges?.length > max_description_input) {
        this.validForm.challenges = max_size_description_error;
        is_valid = false;
      }
      if (
        open_to_interview &&
        open_to_interview?.length > max_description_input
      ) {
        this.validForm.open_to_interview = max_size_description_error;
        is_valid = false;
      }
      return is_valid;
    },
    clearValidForm() {
      this.validForm.recommendations = null;
      this.validForm.wish_changes = null;
      this.validForm.challenges = null;
      this.validForm.open_to_interview = null;
    },
    isComplete() {
      return this.rating > 0;
    },
  },
  computed: {
    ...mapGetters(['getUser']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

$active-color: #f3d23e;

.main {
  background: #e4f1fb;
  @include vw-vh-scroll;
}

.main::-webkit-scrollbar {
  display: none;
}

.slot {
  height: 100vh;
  @include d-flex-column;
}

.control-header {
  padding: 0 25px;
}

.content-main {
  @include d-flex-column;
  gap: 30px;
  padding: 0 25px;
  .title {
    font-weight: $fw-700;
    font-size: 26px;
    line-height: 31px;
    color: $color-black;
  }
  .description {
    font-weight: $fw-500;
    font-size: 16px;
    line-height: 147.02%;
    text-align: center;
    color: #000000;
  }
}
form {
  @include d-flex-column;
  gap: 20px;
}
.star_range {
  @include d-flex-row-center;
  justify-content: center;
  gap: 20px;
}
.step-application-form {
  margin: 40px 30px;
  width: 100%;
}
.invite-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 47px;
  border-radius: 10px;
}

.next {
  background: #0058a2;
  color: $color-white;
}

.text-btn {
  font-weight: $fw-700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: $color-white;
  width: 100%;
}

.list {
  @include d-flex-row-center;
  justify-content: center;
  gap: 20px;
  margin: 0 0 5px 0;
  padding: 0;
  list-style-type: none;
  &:hover {
    .star {
      color: $active-color;
    }
  }
}
.star {
  display: inline-block;
  cursor: pointer;
  &:hover {
    & ~ .star {
      &:not(.active) {
        color: inherit;
      }
    }
  }
}
.active {
  color: $active-color;
}
.active svg path {
  fill: $active-color;
}

@media only screen and (min-width: 768px) {
  #rate-app__form,
  .step-application-form {
    max-width: 600px;
    margin: 0 auto;
  }
}
</style>
