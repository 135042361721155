<template>
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.16667 14.9137H10.6667C11.0424 14.9137 11.4027 14.76 11.6684 14.4865C11.9341 14.213 12.0833 13.8421 12.0833 13.4553V1.78866C12.0833 1.40188 11.9341 1.03095 11.6684 0.757458C11.4027 0.483968 11.0424 0.330322 10.6667 0.330322H2.16667C1.79094 0.330322 1.43061 0.483968 1.16493 0.757458C0.899255 1.03095 0.75 1.40188 0.75 1.78866V13.4553C0.75 13.8421 0.899255 14.213 1.16493 14.4865C1.43061 14.76 1.79094 14.9137 2.16667 14.9137ZM0.75 25.122C0.75 25.5088 0.899255 25.8797 1.16493 26.1532C1.43061 26.4267 1.79094 26.5803 2.16667 26.5803H10.6667C11.0424 26.5803 11.4027 26.4267 11.6684 26.1532C11.9341 25.8797 12.0833 25.5088 12.0833 25.122V19.2887C12.0833 18.9019 11.9341 18.5309 11.6684 18.2575C11.4027 17.984 11.0424 17.8303 10.6667 17.8303H2.16667C1.79094 17.8303 1.43061 17.984 1.16493 18.2575C0.899255 18.5309 0.75 18.9019 0.75 19.2887V25.122ZM14.9167 25.122C14.9167 25.5088 15.0659 25.8797 15.3316 26.1532C15.5973 26.4267 15.9576 26.5803 16.3333 26.5803H24.8333C25.2091 26.5803 25.5694 26.4267 25.8351 26.1532C26.1007 25.8797 26.25 25.5088 26.25 25.122V14.9137C26.25 14.5269 26.1007 14.1559 25.8351 13.8825C25.5694 13.609 25.2091 13.4553 24.8333 13.4553H16.3333C15.9576 13.4553 15.5973 13.609 15.3316 13.8825C15.0659 14.1559 14.9167 14.5269 14.9167 14.9137V25.122ZM16.3333 10.5387H24.8333C25.2091 10.5387 25.5694 10.385 25.8351 10.1115C26.1007 9.83803 26.25 9.4671 26.25 9.08032V1.78866C26.25 1.40188 26.1007 1.03095 25.8351 0.757458C25.5694 0.483968 25.2091 0.330322 24.8333 0.330322H16.3333C15.9576 0.330322 15.5973 0.483968 15.3316 0.757458C15.0659 1.03095 14.9167 1.40188 14.9167 1.78866V9.08032C14.9167 9.4671 15.0659 9.83803 15.3316 10.1115C15.5973 10.385 15.9576 10.5387 16.3333 10.5387Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon',
};
</script>

<style scoped></style>
