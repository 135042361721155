<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.1169 15.492C19.532 16.2784 20.9479 17.0649 22.3629 17.8513C22.9719 18.1895 23.2391 18.9071 22.9994 19.5616C21.7819 22.8877 18.2161 24.6433 14.861 23.4188C7.99089 20.9112 2.89846 15.8185 0.390594 8.94836C-0.833987 5.5933 0.921728 2.02749 4.2478 0.810044C4.90229 0.570248 5.61989 0.837421 5.95891 1.44651C6.7445 2.86161 7.53104 4.27753 8.31741 5.69254C8.68604 6.35638 8.5994 7.14436 8.09485 7.71124C7.43409 8.45478 6.77346 9.19816 6.11271 9.94091C7.52315 13.3755 10.4338 16.2862 13.8684 17.6966C14.6112 17.0359 15.3546 16.3752 16.0981 15.7145C16.6658 15.21 17.4531 15.1233 18.1168 15.4919L18.1169 15.492Z"
      fill="#0095AB"
    />
  </svg>
</template>

<script>
export default {
  name: 'TelephoneIcon',
};
</script>

<style scoped></style>
