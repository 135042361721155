<template>
  <div class="container_page">
    <div class="content">
      <div class="content__header">
        <div class="control-button">
          <div class="back">
            <back-icon
              @click="this.$router.go(-1)"
              class="control-button__back"
            />
            <p>Profile</p>
          </div>
          <div @click="openMenu" class="control-button__menu"></div>
        </div>
      </div>
      <div class="content__main profile">
        <div class="showEditButton">
          <svg
            @click="unBlockedForm()"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            class="edit_profile_icon"
          >
            <path
              d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h274.9c-2.4-6.8-3.4-14-2.6-21.3l6.8-60.9 1.2-11.1 7.9-7.9 77.3-77.3c-24.5-27.7-60-45.5-99.9-45.5zm45.3 145.3l-6.8 61c-1.1 10.2 7.5 18.8 17.6 17.6l60.9-6.8 137.9-137.9-71.7-71.7-137.9 137.8zM633 268.9L595.1 231c-9.3-9.3-24.5-9.3-33.8 0l-37.8 37.8-4.1 4.1 71.8 71.7 41.8-41.8c9.3-9.4 9.3-24.5 0-33.9z"
            />
          </svg>
        </div>
        <div class="content__main--avatar">
          <file-upload
            :user="user"
            :avatar="getAvatar"
            :blockedForm="blockedForm"
            @fileUploaded="getUploadedData"
            @fileToRemove="getRemoveAvatar"
          />
        </div>
        <user-profile-template
          :user="user"
          :blockedForm="blockedForm"
          @blocked-form="unBlockedForm"
        />
      </div>
    </div>
  </div>
  <menu-template />
  <slide-menu :user="user" :show="open_menu" @close="openMenu" />
</template>

<script>
import MenuTemplate from '@/components/MenuTemplate.vue';
import {
  baseErrHandler,
  getUserProfile,
  updateAvatarUserProfile,
} from '@/services/api';
import UserProfileTemplate from '@/components/profile/UserProfileTemplate.vue';
import { mapMutations } from 'vuex';
import { pwa_user } from '@/helpers/enums';
import FileUpload from '@/components/profile/FileUpload.vue';
// import BackIcon from '@/components/svg/BackIcon.vue';
import SlideMenu from '@/components/SlideMenu.vue';
import { successMessage } from '@/helpers/messages';
import BackIcon from '@/components/svg/BackIcon.vue';
export default {
  name: 'EditProfile',
  components: {
    BackIcon,
    SlideMenu,
    FileUpload,
    UserProfileTemplate,
    MenuTemplate,
  },
  inject: ['gtag'],
  async mounted() {
    await this.getProfileUser();
    this.gtag.event('Edit profile');
  },
  data() {
    return {
      user: null,
      photo: null,
      avatar: null,
      file: {},
      open_menu: false,
      blockedForm: true,
    };
  },
  methods: {
    ...mapMutations(['setUser']),
    unBlockedForm() {
      return (this.blockedForm = !this.blockedForm);
    },
    async getProfileUser() {
      const userData = JSON.parse(localStorage.getItem(pwa_user));
      if (!userData) {
        return;
      }
      await baseErrHandler(async () => {
        const { user } = await getUserProfile();
        if (user) {
          this.setUser(user);
          this.user = user;
          this.photo = user?.avatar_url;
        }
      });
    },
    async getUploadedData(file) {
      const { url } = file;
      let uploadAvatar = await this.toBlob(url);
      await this.updateAvatarUser(uploadAvatar);
    },
    async updateAvatarUser(image) {
      const logoName = Math.random().toString(12).slice(2);
      const formData = new FormData();
      formData.append('image', image, `${logoName}.png`);
      await this.getProfileImage(formData);
    },
    async getRemoveAvatar(val) {
      await this.getProfileImage({ val });
    },
    async getProfileImage(val) {
      await baseErrHandler(async () => {
        const { user } = await updateAvatarUserProfile(val);
        const userData = JSON.parse(localStorage.getItem(pwa_user));
        userData.avatar_url = user?.avatar_url;
        this.setUser(userData);
        this.photo = user?.avatar_url;
        successMessage('Avatar updated successfully');
        this.unBlockedForm();
      });
    },
    async toBlob(img) {
      return fetch(img).then((res) => res.blob());
    },
    openMenu() {
      this.open_menu = !this.open_menu;
    },
  },
  computed: {
    getAvatar() {
      return this.photo;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.edit_profile_icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 25px;
  cursor: pointer;
}

.container_page {
  overflow: hidden;
  position: relative;
  width: 100vw;
  min-height: 100%;
  background-size: contain;
  background-image: url('@/assets/app_img/profile-background.png');

  .content {
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &__header {
      min-height: 145px;
      .control-button {
        justify-content: space-between;
        display: flex;
        align-items: center;
        color: $color-white;
        position: relative;
        top: 40px;
        padding: 0 25px;
        & .back {
          display: flex;
          align-items: center;
          gap: 23px;
          p {
            margin: 0;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.015em;
            color: #f9fafd;
          }
        }
        &__menu {
          background: $color-white;
          display: block;
          height: 3px;
          position: relative;
          right: 0;
          top: 0;
          width: 24px;
          border-radius: 30px;
          cursor: pointer;
          &::before,
          &::after {
            background: $color-white;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            transition: all 0.2s ease-out;
            width: 100%;
            border-radius: 30px;
          }
          &::before {
            top: 7px;
          }
          &::after {
            top: -7px;
          }
        }
      }
    }

    .profile {
      display: flex;
      flex-direction: column;
      gap: 35px;
      align-items: center;
      justify-content: flex-start;
      padding-top: 36px;
    }

    &__main {
      background: $color-white;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      margin-top: -30px;

      &--avatar {
        width: 121px;
        height: 121px;
        background: #d5e7f5;
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container_page .content__main {
    margin-top: -50px;
  }
}
</style>
