import { createRouter, createWebHistory } from 'vue-router';
import SliderTemplate from '@/components/onboarding/SliderTemplate.vue';
import LoginTemplate from '@/components/auth/LoginTemplate.vue';
import ForgotPassTemplate from '@/components/auth/ForgotPassTemplate.vue';
import ResetPassTemplate from '@/components/auth/ResetPassTemplate.vue';
import RegisterTemplate from '@/components/auth/RegisterTemplate.vue';
import EditProfile from '@/views/EditProfile.vue';
import ArticleDetailsTemplate from '@/components/news_actirle/ArticleDetailsTemplate.vue';
import ArticlesNewsTemplate from '@/views/ArticlesNewsTemplate.vue';
import EventCalendarView from '@/views/EventCalendarView.vue';
import CoursesView from '@/views/CoursesView.vue';
import DashboardView from '@/views/DashboardView.vue';
import CoursesFreeTemplate from '@/components/courses/CoursesFreeTemplate.vue';
import CoursesSponsoredTemplate from '@/components/courses/CoursesSponsoredTemplate.vue';
import CoursesFundedTemplate from '@/components/courses/CoursesFundedTemplate.vue';
import CourseDetailsTemplate from '@/components/courses/CourseDetailsTemplate.vue';
import RateAppTemplate from '@/components/RateAppTemplate.vue';
import SuccessRateAppTemplate from '@/components/SuccessRateAppTemplate.vue';
import EventDetailsShowTemplate from '@/components/events/EventDetailsShowTemplate.vue';
import AttendEventTemplate from '@/components/events/AttendEventTemplate.vue';
import AboutUsTemplate from '@/views/AboutUsTemplate.vue';
import PartnersTemplate from '@/components/PartnersTemplate.vue';
import AccreditationsTemplate from '@/components/AccreditationsTemplate.vue';
import AffiliationsTemplate from '@/components/AffiliationsTemplate.vue';
import TermsConditionsTemplate from '@/components/TermsConditionsTemplate.vue';
import ContactUsTemplate from '@/components/ContactUsTemplate.vue';
import FaqsTemplate from '@/components/FaqsTemplate.vue';
import PrivacyTemplate from '@/components/PrivacyTemplate.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: SliderTemplate,
  },
  {
    path: '/auth/log-in',
    name: 'LogIn',
    component: LoginTemplate,
  },
  {
    path: '/auth/registration',
    name: 'Register',
    component: RegisterTemplate,
  },
  {
    path: '/auth/forgot-password',
    name: 'ForgotPass',
    component: ForgotPassTemplate,
  },
  {
    path: '/set_new_password',
    name: 'ResetPass',
    component: ResetPassTemplate,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
  },
  {
    path: '/courses',
    name: 'Courses',
    component: CoursesView,
  },
  {
    path: '/courses/:id',
    name: 'CourseDetails',
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    },
    component: CourseDetailsTemplate,
  },
  {
    path: '/course/sponsored',
    name: 'CoursesSponsored',
    component: CoursesSponsoredTemplate,
  },
  {
    path: '/course/self-funded',
    name: 'CoursesFunded',
    component: CoursesFundedTemplate,
  },
  {
    path: '/course/free',
    name: 'CoursesFree',
    component: CoursesFreeTemplate,
  },
  {
    path: '/articles',
    name: 'Articles',
    props: () => {
      return {
        type: 'articles',
      };
    },
    component: ArticlesNewsTemplate,
  },
  {
    path: '/news',
    name: 'News',
    props: () => {
      return {
        type: 'news',
      };
    },
    component: ArticlesNewsTemplate,
  },
  {
    path: '/articles/:id',
    name: 'ArticleDetails',
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    },
    component: ArticleDetailsTemplate,
  },
  {
    path: '/events',
    name: 'Events',
    component: EventCalendarView,
  },
  {
    path: '/events/:id',
    name: 'EventDetails',
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    },
    component: EventDetailsShowTemplate,
  },
  {
    path: '/events/:id/attend',
    name: 'AttendEvent',
    component: AttendEventTemplate,
  },
  {
    path: '/profile',
    name: 'UserProfile',
    meta: {
      requiresAuth: true,
    },
    component: EditProfile,
  },
  {
    path: '/rate-app',
    name: 'RateApp',
    component: RateAppTemplate,
  },
  {
    path: '/rate-app-success',
    name: 'SuccessRateApp',
    component: SuccessRateAppTemplate,
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUsTemplate,
  },
  {
    path: '/partners',
    name: 'Partners',
    component: PartnersTemplate,
  },
  {
    path: '/accreditations',
    name: 'Accreditations',
    component: AccreditationsTemplate,
  },
  {
    path: '/affiliations',
    name: 'Affiliations',
    component: AffiliationsTemplate,
  },
  {
    path: '/terms-conditions',
    name: 'TermsConditions',
    component: TermsConditionsTemplate,
  },
  {
    path: '/privacy',

    name: 'Privacy',
    component: PrivacyTemplate,
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUsTemplate,
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: FaqsTemplate,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
