<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="5.4" stroke="white" stroke-width="1.2" />
    <path
      d="M6 8.5L7.14645 9.64645C7.34171 9.84171 7.65829 9.84171 7.85355 9.64645L10.5 7"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'AppliedStatusIcon',
};
</script>

<style scoped></style>
