<template>
  <svg
    width="22"
    height="32"
    viewBox="0 0 22 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.607422V31.0268H22V7.00907C19.9218 4.87513 17.8435 2.7412 15.7653 0.607422H0ZM11.1548 25.8253H5.0059V22.9828H11.1548V25.8253V25.8253ZM16.9941 20.4346H5.0059V17.5922H16.9941V20.4346ZM16.9941 15.0438H5.0059V12.2014H16.9941V15.0438ZM13.7481 9.01239V2.85163L19.7476 9.01239H13.7481Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'DocumentIcon',
};
</script>

<style scoped></style>
