<template>
  <svg
    width="260"
    height="140"
    viewBox="0 0 260 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M126.62 109.056L129.5 106.188L126.62 109.056ZM129.5 106.188L132.376 109.056C131.999 109.435 131.55 109.735 131.056 109.94C130.562 110.146 130.033 110.251 129.498 110.251C128.963 110.251 128.434 110.146 127.94 109.94C127.446 109.735 126.997 109.435 126.62 109.056L126.595 109.027L126.526 108.958L126.274 108.702C124.842 107.227 123.434 105.729 122.049 104.209C118.573 100.4 115.245 96.4595 112.072 92.3953C108.448 87.7234 104.743 82.4422 101.928 77.2259C99.1734 72.1113 97 66.57 97 61.5C97 43.0603 111.592 29 129.5 29C147.408 29 162 43.0603 162 61.5C162 66.57 159.827 72.1112 157.072 77.2219C154.257 82.4463 150.556 87.7234 146.928 92.3953C142.489 98.0813 137.748 103.525 132.726 108.702L132.474 108.958L132.405 109.027L132.38 109.052L129.5 106.188ZM129.5 73.6875C132.732 73.6875 135.832 72.4035 138.118 70.1179C140.403 67.8323 141.688 64.7323 141.688 61.5C141.688 58.2677 140.403 55.1677 138.118 52.8821C135.832 50.5965 132.732 49.3125 129.5 49.3125C126.268 49.3125 123.168 50.5965 120.882 52.8821C118.597 55.1677 117.312 58.2677 117.312 61.5C117.312 64.7323 118.597 67.8323 120.882 70.1179C123.168 72.4035 126.268 73.6875 129.5 73.6875Z"
      fill="#0095AB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M157.574 76.2723C153.121 79.2582 147.764 81 142 81C126.536 81 114 68.464 114 53C114 42.8566 119.394 33.9729 127.47 29.0606C110.502 30.0771 97 43.7624 97 61.5C97 66.57 99.1734 72.1113 101.928 77.2259C104.743 82.4422 108.448 87.7234 112.072 92.3953C115.245 96.4595 118.573 100.4 122.049 104.209C123.434 105.729 124.842 107.227 126.274 108.702L126.526 108.958L126.595 109.027L126.62 109.056C126.997 109.435 127.446 109.735 127.94 109.94C128.434 110.146 128.963 110.251 129.498 110.251C130.033 110.251 130.562 110.146 131.056 109.94C131.55 109.735 131.999 109.435 132.376 109.056L129.5 106.188L132.38 109.052L132.405 109.027L132.474 108.958L132.726 108.702C137.748 103.525 142.489 98.0813 146.928 92.3953C150.556 87.7234 154.257 82.4463 157.072 77.2219C157.242 76.9069 157.409 76.5903 157.574 76.2723Z"
      fill="#14BED8"
    />
    <path
      d="M181.14 29.5402L180.161 28.5613L181.14 27.5824L180.54 26.9826L179.561 27.9614L178.579 26.9789L177.979 27.5787L178.961 28.5613L177.983 29.5402L178.582 30.14L179.561 29.1611L180.54 30.14L181.14 29.5402Z"
      fill="#14BED8"
    />
    <path
      d="M182.661 104.696L181.086 103.121L182.661 101.546L181.696 100.581L180.121 102.156L178.54 100.575L177.575 101.54L179.156 103.121L177.581 104.696L178.546 105.661L180.121 104.086L181.696 105.661L182.661 104.696Z"
      fill="#14BED8"
    />
    <path
      d="M72.6612 64.6961L71.0862 63.1211L72.6612 61.5461L71.6961 60.5809L70.121 62.156L68.5401 60.575L67.575 61.5401L69.1559 63.1211L67.5809 64.6961L68.546 65.6612L70.121 64.0862L71.696 65.6612L72.6612 64.6961Z"
      fill="#14BED8"
    />
    <path
      d="M81 99C80.4696 99 79.9609 99.2107 79.5858 99.5858C79.2107 99.9609 79 100.47 79 101C79 101.53 79.2107 102.039 79.5858 102.414C79.9609 102.789 80.4696 103 81 103C81.5305 103 82.0391 102.789 82.4142 102.414C82.7893 102.039 83 101.53 83 101C83 100.47 82.7893 99.9609 82.4142 99.5858C82.0391 99.2107 81.5305 99 81 99V99Z"
      stroke="#EFC04D"
      stroke-width="1.43695"
      stroke-miterlimit="10"
    />
    <path
      d="M84.03 30C83.7568 30 83.4948 30.1085 83.3017 30.3017C83.1085 30.4948 83 30.7568 83 31.03C83 31.3031 83.1085 31.5651 83.3017 31.7582C83.4948 31.9514 83.7568 32.0599 84.03 32.0599C84.3031 32.0599 84.5651 31.9514 84.7582 31.7582C84.9514 31.5651 85.0599 31.3031 85.0599 31.03C85.0599 30.7568 84.9514 30.4948 84.7582 30.3017C84.5651 30.1085 84.3031 30 84.03 30V30Z"
      stroke="#EFC04D"
      stroke-width="0.74"
      stroke-miterlimit="10"
    />
    <path
      d="M190.03 67C189.757 67 189.495 67.1085 189.302 67.3017C189.109 67.4948 189 67.7568 189 68.03C189 68.3031 189.109 68.5651 189.302 68.7582C189.495 68.9514 189.757 69.0599 190.03 69.0599C190.303 69.0599 190.565 68.9514 190.758 68.7582C190.951 68.5651 191.06 68.3031 191.06 68.03C191.06 67.7568 190.951 67.4948 190.758 67.3017C190.565 67.1085 190.303 67 190.03 67Z"
      stroke="#EFC04D"
      stroke-width="0.74"
      stroke-miterlimit="10"
    />
  </svg>
</template>

<script>
export default {
  name: 'EmptyEventIcon',
};
</script>

<style scoped></style>
