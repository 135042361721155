<template>
  <empty-event-icon />
  <div class="empty-title">
    <p class="m-0">Oops</p>
    <span>{{ title }}</span>
  </div>
</template>

<script>
import EmptyEventIcon from '@/components/svg/EmptyEventIcon.vue';

export default {
  name: 'EmptyEventBlock',
  components: { EmptyEventIcon },
  props: {
    title: {
      type: String,
      default() {
        return '';
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  p {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #182337;
  }
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #6b6b6b;
    text-align: center;
  }
}
</style>
