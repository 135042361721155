<template>
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.34156 0.000871587C0.199488 0.00854764 0.0752891 0.098774 0.0241971 0.231533C-0.0270174 0.364166 0.00456627 0.514414 0.104733 0.615335L6.23836 7.10976C6.30669 7.18363 6.4027 7.22566 6.50337 7.22566C6.60404 7.22566 6.70006 7.18363 6.76839 7.10976L12.902 0.615335C13.039 0.469111 13.0314 0.239329 12.8851 0.102418C12.7388 -0.0346193 12.509 -0.0270683 12.3721 0.11928L6.50328 6.33183L0.634459 0.11928C0.560214 0.036856 0.451993 -0.00681051 0.341382 0.00086618L0.34156 0.000871587Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowDownIcon',
};
</script>

<style lang="scss" scoped>
svg {
  background: none;
}
</style>
