<template>
  <svg
    width="23"
    height="29"
    viewBox="0 0 23 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0033 9.90802C21.7251 7.19105 20.4488 4.67368 18.4215 2.84328C16.3943 1.01299 13.7601 -0.00023012 11.0287 3.92031e-08C8.29744 0.000230199 5.66338 1.01345 3.63602 2.84397C1.60889 4.67466 0.332889 7.19203 0.055086 9.90929C0.0179998 10.2815 -0.000430887 10.6553 1.84994e-05 11.0293C-0.000655753 11.3963 0.017101 11.7632 0.0532878 12.1284C0.483266 18.6678 6.90514 25.7199 10.632 28.8553V28.8555C10.8628 29.049 11.1995 29.0481 11.4292 28.853C15.1576 25.6805 21.581 18.5879 22.0033 12.1492C22.077 11.4039 22.0768 10.6529 22.0031 9.90762L22.0033 9.90802ZM11.0293 17.1224C9.41326 17.1215 7.86394 16.4788 6.72171 15.3355C5.57966 14.1923 4.93844 12.6421 4.9393 11.0263C4.9402 9.41019 5.58283 7.86064 6.7262 6.71865C7.86935 5.57636 9.41959 4.93537 11.0354 4.93623C12.6515 4.93691 14.201 5.57976 15.343 6.7229C16.4853 7.86628 17.1263 9.41629 17.1256 11.0324C17.1229 12.6477 16.4794 14.1961 15.3367 15.3379C14.1938 16.4795 12.6447 17.1215 11.0293 17.1224Z"
      fill="#0095AB"
    />
  </svg>
</template>

<script>
export default {
  name: 'LocationIcon',
};
</script>

<style scoped></style>
