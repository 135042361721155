<template>
  <svg
    width="21"
    height="26"
    viewBox="0 0 21 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6606 11.878C13.7968 11.878 16.3391 9.33563 16.3391 6.19948C16.3391 3.06332 13.7968 0.520966 10.6606 0.520966C7.52447 0.520966 4.98212 3.06332 4.98212 6.19948C4.98212 9.33563 7.52447 11.878 10.6606 11.878Z"
      fill="white"
    />
    <path
      d="M10.5 13.8065C4.70368 13.8129 0.00645314 18.4717 0 24.2208C0 24.8598 0.522322 25.3779 1.16665 25.3779H19.8333C20.4777 25.3779 21 24.8598 21 24.2208C20.9936 18.4717 16.2964 13.8128 10.5 13.8065Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'ProfileIcon',
};
</script>

<style scoped></style>
