<template>
  <div class="control-button">
    <div v-if="title" class="control-button__back">
      <div
        class="arrow"
        :class="[
          { 'bg-white': isComponentName },
          { 'd-none': isVisibleLinkBackToEventPage },
        ]"
        @click="redirectBackLink()"
      >
        <arrow-icon :arrow_style="isComponentName ? arrow_style : '#ffffff'" />
      </div>
      <p :style="[color, position, top]">{{ title }}</p>
    </div>
    <div v-else class="control-button--profile">
      <div class="avatar">
        <img :src="userAvatar" alt="avatar" />
      </div>
      <div class="name" :style="color">
        Hi {{ getUserFullName }}
        <p :style="color">Welcome</p>
      </div>
    </div>
    <div class="d-flex align-items-center gap-4 control-button--search">
      <div class="link-search" v-if="!title">
        <search-icon
          :search_style="line_style"
          @click="searchList"
          class="search-btn"
        />
        <div
          :class="{
            'd-block': input_show,
            'd-none': !input_show,
          }"
          class="input_block"
        >
          <input
            class="control-button--input"
            type="text"
            v-model="search"
            :class="{
              active: input_show,
            }"
            placeholder="Type to search..."
          />
          <div @click="searchList" class="input_icon">
            <close-input-icon />
          </div>
        </div>
      </div>
      <div @click="openMenu" class="control-button__menu">
        <span :style="line_style"></span>
        <span :style="line_style"></span>
        <span :style="line_style"></span>
      </div>
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/components/svg/SearchIcon.vue';
import CloseInputIcon from '@/components/svg/CloseInputIcon.vue';
import ArrowIcon from '@/components/svg/ArrowIcon.vue';

export default {
  name: 'HeaderControlButton',
  components: { ArrowIcon, CloseInputIcon, SearchIcon },
  emits: ['openSidebar', 'search-prop'],
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    line_style: {
      type: Object,
      default() {
        return {
          background: '#ffffff',
        };
      },
    },
    color: {
      type: Object,
      default() {
        return {};
      },
    },
    position: {
      type: Object,
      default() {
        return {};
      },
    },
    top: {
      type: Object,
      default() {
        return {};
      },
    },
    title: {
      type: String,
      default() {
        return '';
      },
    },
    type: {
      type: String,
      default() {
        return null;
      },
    },
    isVisibleLinkBackToEventPage: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.isComponentName =
      this.$route.name === 'CourseDetails' || this.$route.name === 'AboutUs';
  },
  data() {
    return {
      open_menu: false,
      input_show: false,
      arrow_style: '#0058A2',
      search: '',
      isComponentName: false,
    };
  },
  methods: {
    openMenu() {
      this.open_menu = !this.open_menu;
      this.$emit('openSidebar', this.open_menu);
    },
    searchList() {
      this.input_show = !this.input_show;
    },
    async redirectBackLink() {
      return this.type
        ? await this.$router.push(`/course/${this.type}`)
        : this.$router.go(-1);
    },
  },
  computed: {
    getUserFullName() {
      return this.user?.name || 'Guest';
    },
    userAvatar() {
      return this.user?.avatar_url
        ? this.user?.avatar_url
        : require('@/assets/default-ava.png');
    },
  },
  watch: {
    search(val) {
      this.$emit('search-prop', val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.control-button {
  @include d-flex-center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 80px;
  &--profile {
    @include d-flex-center;
    text-align: left;
    gap: 15px;
    .avatar {
      width: 51px;
      height: 51px;
      & img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 40px;
        background: #6c63ff;
      }
    }
    .name {
      font-size: 19px;
      line-height: 23px;
      font-weight: $fw-700;
      color: $color-white;
      max-width: 200px;
      & p {
        @include reset-m-p;
        font-style: normal;
        font-weight: $fw-600;
        font-size: 12px;
        line-height: 140.9%;
        color: $color-white;
      }
    }
  }
  &__back {
    cursor: pointer;
    @include d-flex-center;
    gap: 15px;
    & div {
      min-width: 40px;
      height: 40px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
      background: #0095ab;
    }
    p {
      color: $color-white;
      font-size: 24px;
      font-style: normal;
      font-weight: $fw-700;
      margin: 0;
      text-align: left;
    }
  }
  &__menu {
    cursor: pointer;
    @include d-flex-justify-align-center;
    gap: 4px;
    height: 40px;
    span {
      border-radius: 30px;
      display: block;
      height: 3px;
      width: 24px;
    }
  }
  &--search {
    .link-search {
      display: none;
      position: relative;
      top: 11px;
    }
    .search-btn {
      transform: translateY(-50%);
      transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      z-index: 2;
    }

    .input_block {
      position: relative;
      top: -24px;
      transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
    input {
      height: 33px;
      position: absolute;
      top: -18px;
      left: -125px;
      z-index: 1;
      width: 100%;
      border: none;
      background: #fff;
      font-size: 15px;
      border-radius: 50px;
      padding: 0 10px 0 10px;
      outline: none;
      opacity: 0;
      font-style: normal;
      font-weight: $fw-600;
    }
    .cancel-btn {
      position: absolute;
      color: #664aff;
      top: 50%;
      right: -75px;
      transform: translateY(-50%);
      font-size: 25px;
      cursor: pointer;
      height: 50px;
      width: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 50%;
    }

    .input_icon {
      position: absolute;
      top: -15px;
      min-width: 16px;
      left: 50px;
      z-index: 2;
    }

    .active .cancel-btn {
      right: -135px;
      opacity: 1;
      color: #fff;
      transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
    .active {
      width: 200px;
      opacity: 1;
      padding-right: 30px;
    }
  }
}
</style>
