<template>
  <courses-template>
    <div class="content-main">
      <hr class="line_menu" />
      <div class="title_and_btn-back">
        <div class="button-back" @click="redirectBackLink()">
          <div class="arrow">
            <svg
              width="11"
              height="18"
              viewBox="0 0 11 18"
              fill="#ffffff"
              stroke="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.59558 16.7177C9.89336 16.4244 9.89319 15.944 9.59522 15.651L3.95662 10.1049C3.55826 9.71308 3.55826 9.07085 3.95662 8.67903L9.59535 3.13285C9.89325 2.83983 9.89342 2.35962 9.59572 2.0664C9.30429 1.77937 8.83637 1.77958 8.54521 2.06688L1.83942 8.68372C1.44244 9.07543 1.4426 9.71618 1.83977 10.1077L8.54508 16.7174C8.83635 17.0046 9.30419 17.0047 9.59558 16.7177Z"
                fill="#ffffff"
                stroke-width="2"
              ></path>
            </svg>
          </div>
        </div>
        <p class="course-title">Sponsored Courses</p>
      </div>
      <div
        class="item"
        v-for="(course, index) in list_sponsored_courses"
        :key="index"
      >
        <router-link
          :to="{ name: 'CourseDetails', params: { id: course?.id } }"
        >
          <div class="category">
            <div class="course-img">
              <img :src="course?.image_url" alt="category" />
              <!--        <p class="status">Non Funded</p>-->
              <div
                class="applied_course_status"
                v-if="isCheckCompletedCourse(course)"
              >
                <span><applied-status-icon /></span> Applied
              </div>
            </div>
            <div class="course-information">
              <div class="cancel-info">
                <p>
                  <clock-icon />
                  <span>{{ getStartAndFinishedDate(course) }}</span>
                </p>
                <p>
                  Course Code: <span>{{ course?.code }}</span>
                </p>
              </div>
              <div class="main-info">
                <div class="title">
                  {{ course.title }}
                </div>
                <div
                  class="desc preview ql-editor"
                  v-html="course.short_description"
                ></div>
              </div>
              <div class="cancel-info justify-content-start">
                <p class="m-0 p-0">
                  Category: <span>{{ course?.category }}</span>
                </p>
              </div>
              <hr class="line_menu" />
              <div class="cancel-info">
                <p class="date">
                  Last Reg Date: <span>{{ getLastRegDate(course) }}</span>
                </p>
                <p class="language">
                  Language: <span>{{ course?.language }}</span>
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </courses-template>
</template>

<script>
import CoursesTemplate from '@/components/courses/CoursesTemplate.vue';
import ClockIcon from '@/components/svg/ClockIcon.vue';
import { coursesPWSList } from '@/services/api';
import { course_status_sponsored } from '@/helpers/enums';
import { formatDateEuropean, formatMonth } from '@/helpers/tools';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import AppliedStatusIcon from '@/components/svg/AppliedStatusIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CoursesSponsoredTemplate',
  components: { AppliedStatusIcon, ClockIcon, CoursesTemplate },
  data() {
    return {
      list_sponsored_courses: [],
      courses_count: [],
    };
  },
  async mounted() {
    await this.getSponsoredCourseList();
  },
  methods: {
    async redirectBackLink() {
      return await this.$router.push('/courses');
    },
    async getSponsoredCourseList() {
      const { list, total_count } = await coursesPWSList({
        funding_type: course_status_sponsored,
      });
      this.list_sponsored_courses = list;
      this.courses_count = total_count;
    },
    getStartAndFinishedDate(course) {
      const { start_date, end_date } = course || {};
      const startDate = formatDateEuropean(start_date);
      const finishedDate = formatDateEuropean(end_date);

      return `${startDate} - ${finishedDate}`;
    },
    getLastRegDate(course) {
      /* Default date doesn't not 'column' in response list */
      const { last_date_registration = null } = course;
      if (last_date_registration) {
        return formatMonth(new Date(last_date_registration), true);
      }
    },
    isCheckCompletedCourse(course) {
      const userIds = course?.applicants?.map(({ user_id }) => user_id) || [];
      return userIds.includes(this?.getUser?.id);
    },
  },
  computed: {
    ...mapGetters(['getUser']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.content-main::-webkit-scrollbar {
  display: none;
}

.line_menu {
  width: 100%;
  opacity: 0.1;
  border: 1px solid #000000;
  @include reset-m-p;
}

.title_and_btn-back {
  @include d-flex-center;
  gap: 15px;
  width: 100%;
}

.button-back {
  cursor: pointer;
  min-width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background: #0095ab;
}

.arrow {
  margin-left: -2px;
  margin-bottom: 2px;
}

.content-main {
  @include d-flex-column;
  gap: 20px;
  padding: 80px 25px 20px;
  overflow-y: scroll;
  .course-title {
    width: 100%;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: $color-black;
    text-align: left;
    @include reset-m-p;
  }
  .applied_course_status {
    @include appliedLabel;
  }
  .item {
    width: 100%;
    position: relative;
    a {
      text-decoration: none;
    }
    .category {
      width: 100%;
      height: auto;
      background: $color-white;
      box-shadow: 1px 3px 28px rgb(0 0 0 / 9%);
      border-radius: 18px;
      @include d-flex-column;
      gap: 10px;
      img {
        width: 100%;
        height: 173px;
        border-radius: 18px 18px 0 0;
      }
      .status {
        position: absolute;
        right: 17px;
        top: 13px;
        background: #61b643;
        border-radius: 140px;
        font-weight: $fw-600;
        font-size: 11px;
        line-height: 13px;
        @include d-flex-justify-align-center;
        color: $color-white;
        width: 80px;
        height: 22px;
        @include reset-m-p;
      }
      .course-information {
        @include d-flex-column-center;
        gap: 10px;

        .main-info {
          text-align: left;
          @include d-flex-column;
          width: calc(100% - 27px);
          .title {
            font-weight: $fw-600;
            font-size: 20px;
            line-height: 24px;
            color: #182337;
          }
          .desc {
            font-weight: $fw-400;
            font-size: 14px;
            line-height: 147.02%;
            color: $color-black;
            opacity: 0.4;
          }
        }
        .line_menu {
          width: 92%;
          opacity: 0.1;
          border: 1px solid #000000;
          @include reset-m-p;
        }
        .date-info,
        .cancel-info {
          @include d-flex-center;
          width: 100%;
          padding: 0 15px;
          p {
            //width: 50%;
            font-weight: $fw-700;
            font-size: 12px;
            line-height: 15px;
            @include d-flex-center;
            letter-spacing: -0.005em;
            color: #0095ab;
            gap: 5px;
            margin-bottom: 0.5rem;
          }
          span {
            font-weight: $fw-500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.005em;
            color: #6b6b6b;
          }
        }
        .date-info > p:last-child,
        .cancel-info > p:last-child {
          padding-left: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .main {
    overflow: auto;
  }
  .content-main {
    gap: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .content-main .item {
    width: 32%;
    position: relative;
  }
  .content-main .item .category {
    height: 100%;
  }
  .content-main .item .category img {
    height: 250px;
  }
  .content-main .item .category .course-information {
    justify-content: space-between;
    height: 100%;
  }
  .content-main .item .category .course-information .date-info p,
  .content-main .item .category .course-information .cancel-info p {
    width: initial;
    font-size: 15px;
  }
  .content-main .item .category .course-information .date-info span,
  .content-main .item .category .course-information .cancel-info span {
    font-size: 14px;
  }
  .cancel-info {
    justify-content: space-between;
  }
  .content-main .item .category .course-information .main-info .desc {
    font-size: 17px;
  }
  .content-main .item .category .course-information .main-info .title {
    font-size: 24px;
    line-height: 34px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .main {
    overflow: auto;
  }
  .content-main {
    gap: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .content-main .item {
    width: 48%;
    position: relative;
  }
  .content-main .item .category {
    height: 100%;
  }
  .content-main .item .category img {
    height: 250px;
  }
  .content-main .item .category .course-information {
    justify-content: space-between;
    height: 100%;
  }
  .content-main .item .category .course-information .date-info p,
  .content-main .item .category .course-information .cancel-info p {
    width: initial;
    font-size: 14px;
  }
  .content-main .item .category .course-information .date-info span,
  .content-main .item .category .course-information .cancel-info span {
    font-size: 13px;
  }
  .cancel-info {
    justify-content: space-between;
  }
  .content-main .item .category .course-information .main-info .desc {
    font-size: 15px;
  }
  .content-main .item .category .course-information .main-info .title {
    font-size: 22px;
    line-height: 30px;
  }
  .content-main .item .category .course-information .line_menu {
    width: 95%;
  }
}

@media only screen and (max-width: 420px) {
  .cancel-info {
    justify-content: space-between;
    padding: 0 13px;
    p {
      width: unset !important;
    }
  }
  .cancel-info > p:last-child {
    padding-left: 0 !important;
  }
}
</style>
<style>
.ql-editor {
  padding: 0;
}
</style>
