<template>
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4 8.00183H11.2H4.8V5.76182C4.8 4.08182 6 2.56183 7.68 2.40183C9.6 2.24183 11.2 3.76183 11.2 5.60182C11.2 6.08182 11.52 6.40183 12 6.40183H12.8C13.28 6.40183 13.6 6.08182 13.6 5.60182C13.6 2.56183 11.2 0.0818255 8.16 0.00182548C5.04 -0.0781745 2.4 2.48183 2.4 5.60182V8.00183H1.6C0.72 8.00183 0 8.72183 0 9.60182V15.2018C0 17.4418 1.76 19.2018 4 19.2018H12C14.24 19.2018 16 17.4418 16 15.2018V9.60182C16 8.72183 15.28 8.00183 14.4 8.00183ZM8.8 14.1618V15.2018C8.8 15.6018 8.4 16.0018 8 16.0018C7.6 16.0018 7.2 15.6018 7.2 15.2018V14.1618C6.72 13.9218 6.4 13.3618 6.4 12.8018C6.4 11.9218 7.12 11.2018 8 11.2018C8.88 11.2018 9.6 11.9218 9.6 12.8018C9.6 13.3618 9.28 13.9218 8.8 14.1618Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: 'PasswordLock',
};
</script>
