<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z"
      fill="white"
    />
    <path
      d="M16 15V16C16 16.2652 15.8946 16.5196 15.7071 16.7071C15.5196 16.8946 15.2652 17 15 17H1C0.734784 17 0.48043 16.8946 0.292893 16.7071C0.105357 16.5196 0 16.2652 0 16V15C0 13.4087 0.632141 11.8826 1.75736 10.7574C2.88258 9.63214 4.4087 9 6 9H10C11.5913 9 13.1174 9.63214 14.2426 10.7574C15.3679 11.8826 16 13.4087 16 15Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'ProfileUserIcon',
};
</script>

<style scoped></style>
