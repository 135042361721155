<template>
  <div class="calendar-search">
    <Calendar
      ref="calendar"
      is-expanded
      color="green"
      locale="en"
      disable-page-swipe
      :min-date="minDate"
      :attributes="attributes"
      :masks="{ navMonths: 'MMMM' }"
      @update:to-page="onMonthClick"
      @dayclick="onDayClick"
    />
  </div>
  <div v-if="isNotEmptyData" class="events-block upcoming-block">
    <event-details-template :events="data" :type="type" :date="date" />
  </div>
  <div
    v-else
    class="events-block-empty align-items-center justify-content-center"
  >
    <empty-event-block :title="`There are no upcoming events in this month.`" />
  </div>
</template>

<script>
import EventDetailsTemplate from '@/components/events/EventDetailsTemplate.vue';
import { Calendar } from 'v-calendar';
import 'v-calendar/dist/style.css';
import { startOfMonth, formatFullDate, leadZero } from '@/helpers/tools';
import EmptyEventBlock from '@/components/EmptyEventBlock.vue';

export default {
  name: 'UpcomingEvents',
  components: {
    EmptyEventBlock,
    EventDetailsTemplate,
    Calendar,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    calendarDates: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default() {
        return 'upcoming';
      },
    },
    date: {
      type: Object,
      required: true,
    },
  },
  emits: ['item-month', 'item-day'],
  async mounted() {
    this.writeWeekDayName(formatFullDate(new Date()));
  },
  data() {
    return {
      month: null,
    };
  },
  methods: {
    onDayClick(day) {
      this.$emit('item-day', day.id);
    },
    async setOldFilterMonthForCalendar(filterDate) {
      const year = new Date(filterDate).getFullYear();
      const month = Number(
        leadZero((new Date(filterDate).getMonth() + 1) % 12),
      );
      /* substitution of a number in the calendar by the selected filter */
      const calendar = this.$refs.calendar;
      await calendar.move({ month, year });
    },
    async setOldFilterDateForCalendar(filterDate) {
      /* substitution of a number in the calendar by the selected filter */
      const calendar = this.$refs.calendar;
      await calendar.focusDate(filterDate);
      const focusableEl = document.querySelector(`.id-${filterDate}`);
      if (focusableEl?.childNodes.length) {
        focusableEl.childNodes[1].click();
      }
    },
    onMonthClick({ month, year }) {
      if (!month || !year) return;
      const date_from = startOfMonth(year, month - 1);
      const date_to = startOfMonth(year, month);
      this.$emit('item-month', { date_from, date_to });
      setTimeout(() => {
        this.writeWeekDayName(date_from);
      }, 400);
    },
    /**
     * @Param {String} selectDate - date in format YYYY-MM-DD
     * */
    writeWeekDayName(selectDate) {
      const year = new Date(selectDate).getFullYear();
      const month = leadZero((new Date(selectDate).getMonth() + 1) % 12);

      const countDaysSelectMonth = this.countDaysForSelectMonth(
        new Date(selectDate).getFullYear(),
        new Date(selectDate).getMonth(),
      );
      for (let k = 1; k <= countDaysSelectMonth; k++) {
        const day = leadZero(k);
        const el = document.querySelector(`.id-${year}-${month}-${day}`);
        if (el?.childNodes.length) {
          if (
            [...(el?.childNodes || [])]?.find((el) => {
              return el?.classList?.contains('day_name_calendar');
            })
          ) {
            continue;
          }
          const day_name = el.childNodes[1]
            ?.getAttribute('aria-label')
            ?.substring(0, 3);
          const e = document.createElement('div');
          e.classList.add('day_name_calendar');
          e.innerHTML = day_name;
          el.childNodes[1]?.insertAdjacentElement('afterEnd', e);
        }
      }
    },
    countDaysForSelectMonth(year, month) {
      return new Date(year, (month + 1) % 12, 0).getDate();
    },
  },
  computed: {
    minDate() {
      return new Date(formatFullDate(new Date()));
    },
    isNotEmptyData() {
      return !!this.data.length;
    },
    attributes() {
      return [
        ...this.calendarDates.map((event) => ({
          dates: { start: event.date_from, end: event.date_to },
          dot: {
            backgroundColor: '#ffc907',
          },
        })),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.calendar-search {
  border-top: 1px solid #3499b9;
  height: auto;
  position: relative;
  padding-bottom: 14px;
}

.events-block,
.events-block-empty {
  @include event-block;
}

.events-block-empty {
  height: calc(100vh - 375px);
}

.date_green {
  background: #03c982;
}

.down {
  transform: rotate(225deg);
  bottom: -33px;
  z-index: 999;
}
</style>
<style>
.day_name_calendar {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
  color: #b3dbe5;
  position: absolute;
  bottom: 10px;
}

.vc-container.vc-is-expanded {
  margin-top: 5px;
  background: inherit;
  border: none;
  width: 100%;
  height: auto;
}

.vc-header {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  padding: 10px 35px 0;
  height: 50px;
}

.vc-weeks {
  display: flex;
  flex-direction: row;
  position: relative;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto;
  padding: 6px;
  min-width: 250px;
  gap: 12px;
  height: 100px;
  overflow-x: auto;
}

.vc-weeks::-webkit-scrollbar {
  /*display: none;*/
}

.vc-weekday {
  display: none;
}

.vc-arrow {
  display: none;
}

.is-not-in-month {
  display: none !important;
}

.vc-title {
  font-family: 'Inter', sans-serif !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #ffffff !important;
}

.vc-day-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--text-sm);
  font-weight: var(--font-medium);
  line-height: 28px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  width: 44px;
  height: 44px;
  background: #349ab9;
  box-shadow: 0 14px 20px rgb(0 0 0 / 4%);
  border-radius: 40px;
  color: white;
}

.vc-day-content.is-disabled {
  color: white;
}

.vc-day-content:hover,
.vc-day-content:focus {
  background-color: #ffffff;
  font-weight: 500;
  font-size: 19px;
  line-height: 18px;
  color: #0095ab;
  position: relative;
  transform-style: preserve-3d;
}

.vc-day-content:hover + .day_name_calendar,
.vc-day-content:focus + .day_name_calendar {
  color: #ffffff;
}

.vc-day-content:focus::after {
  /*background: #ffc907;*/
  /*border: 3px solid #017ea8;*/
}

.vc-day-content:hover::after,
.vc-day-content:focus::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 40px;
  top: -4px;
  left: 27px;
}

.vc-day-content:hover::before,
.vc-day-content:focus:before {
  content: '';
  position: absolute;
  top: -2px;
  width: 50px;
  height: 96px;
  background: #0095ab;
  box-shadow: 0 14px 20px rgba(0, 0, 0, 0.04);
  border-radius: 51px;
  transform: translateZ(-1px);
}

.vc-day-box-center-center {
  display: flex;
  justify-content: center;
  align-items: start;
  transform-origin: 50% 50%;
}

.vc-day-layer {
  position: absolute;
  bottom: 77px;
  left: 15px;
}

.vc-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(50%, -50%);
  pointer-events: none;
  width: 8px;
  height: 8px;
  background-color: #ffc907;
  border-radius: 18px;
}

.vc-dot {
  background-color: #ffc907 !important;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  transition: all var(--day-content-transition-time);
}

.vc-nav-container > .vc-nav-header {
  /*display: none;*/
}

.vc-nav-items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.vc-nav-item {
  width: 100%;
}

.vc-day-popover-container {
  display: none !important;
}

.is-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.vc-title {
  width: 200px;
  text-align: start;
}

.vc-popover-content-wrapper {
  transform: translate3d(21px, 29px, 0px) !important;
}

.vc-title::after {
  content: '';
  display: inline-block;
  height: 10px;
  width: 10px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #fff solid;
  border-bottom: 2px #fff solid;
  transform: rotate(45deg);
  position: relative;
  left: 15px;
  bottom: 3px;
}

@media only screen and (min-width: 768px) {
  .upcoming-block {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: calc(100vh - 194px);
  }
}
@media screen and (min-width: 1200px) and (max-width: 1679px) {
  .vc-weeks {
    height: 116px;
  }
  .vc-weeks::-webkit-scrollbar {
    width: 3px;
  }
  .vc-weeks::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  }
  .vc-weeks::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}
@media screen and (min-width: 1680px) {
  .vc-weeks {
    justify-content: center;
  }
}
</style>
