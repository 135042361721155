<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.512 0.43081C10.2066 0.153588 9.80889 0 9.39632 0C8.98375 0 8.58609 0.153605 8.2806 0.43081L0.543081 7.46961C0.210795 7.77158 0.0153032 8.19526 0.000841281 8.64403C-0.0134402 9.09279 0.154609 9.52823 0.466912 9.85082C0.779204 10.1734 1.20878 10.3556 1.65782 10.3559H1.96393C2.02677 10.3559 2.08707 10.3808 2.1315 10.4253C2.17593 10.4697 2.20084 10.53 2.20084 10.5928V16.1075C2.20116 16.5473 2.37603 16.9691 2.68706 17.2801C2.9981 17.5911 3.41987 17.766 3.85974 17.7662H6.54311C6.79446 17.7662 7.03567 17.6664 7.21339 17.4886C7.39112 17.3109 7.49109 17.0697 7.49109 16.8184V13.7481C7.47951 13.2835 7.63184 12.8294 7.92113 12.4655C8.21057 12.1016 8.61855 11.8511 9.07398 11.7576C9.62747 11.6637 10.1944 11.8182 10.6236 12.1802C11.0528 12.5423 11.3008 13.0748 11.3015 13.6364V16.8109V16.8108C11.3015 17.0621 11.4014 17.3033 11.5792 17.4811C11.7569 17.6588 11.9981 17.7588 12.2495 17.7588H14.933H14.9328C15.3727 17.7584 15.7945 17.5836 16.1055 17.2725C16.4165 16.9615 16.5914 16.5397 16.5917 16.0999V10.5889C16.5917 10.458 16.6977 10.352 16.8286 10.352H17.1338C17.5819 10.351 18.0105 10.169 18.3222 9.84704C18.6338 9.52523 18.8022 9.09092 18.7885 8.64309C18.775 8.1953 18.581 7.77192 18.2504 7.46944L10.512 0.43081Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon',
};
</script>

<style lang="scss" scoped></style>
