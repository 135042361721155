<template>
  <parent-component>
    <div class="partners">
      <section class="generate-title">
        <img
          :src="`${publicPath}img/logo-pwa.png`"
          class="privacy_logo"
          alt="privacy_logo"
        />
        <div class="d-flex flex-column gap-2">
          <div class="title">RAND WATER GROUP PRIVACY NOTICE</div>
          <span
            >(issued in terms of section of section 5(a)(i) and 18 of the
            Protection of Personal Information Act, No 4 of 2013)</span
          >
        </div>
        <div class="mt-4">
          <div class="introduction">
            <div class="sub-title">1. INTRODUCTION</div>
            <div class="desc">
              <p>
                The Rand Water Group (“the Group”) values your privacy and wants
                to be transparent on how your Personal Information will be
                processed when interacting with you. With this Privacy Notice,
                the Group informs you and provides you with an overview of how
                it will process your Personal Information and by doing so the
                Group is fulfilling its notification obligation in terms of the
                Protection of Personal Information Act 4 of 2013 (“POPIA”).
              </p>
              <p>
                The Group is committed to manage and process your Personal
                Information in accordance with its Data Protection and Privacy
                Policy and the applicable privacy and information protection law
                provisions, which specifically provides for the lawful, fair and
                transparent processing of your Personal Information for
                specified, explicit and legitimate purposes in a reasonable
                manner that does not infringe on your right to privacy.
              </p>
            </div>
          </div>
          <div class="about_us">
            <div class="sub-title">2. ABOUT US AND HOW TO CONTACT US</div>
            <div class="desc">
              <div class="responsibility">
                <p>Responsible Party :</p>
                <p>Rand Water Group</p>
              </div>
              <div class="responsibility">
                <p>Physical Address :</p>
                <p>522 Impala Road Glenvista 2058</p>
              </div>
              <div class="responsibility">
                <p>Postal Address :</p>
                <p>P O Box 1127 Johannesburg 2000 South Africa</p>
              </div>
              <div class="responsibility">
                <p>Tel :</p>
                <p>(011) 682 0911</p>
              </div>
              <div class="responsibility">
                <p>Fax :</p>
                <p>(011) 682 0444/05</p>
              </div>
              <div class="responsibility">
                <p>Website :</p>
                <a href="https://www.randwater.co.za" target="_blank">
                  www.randwater.co.za</a
                >
              </div>
              <div class="responsibility">
                <p>Email Address :</p>
                <a :href="'mailto:' + 'customerservices@randwater.co.za'"
                  >customerservices@randwater.co.za</a
                >
              </div>
            </div>
          </div>
          <div class="collection-personal_info">
            <div class="sub-title">3. COLLECTION OF PERSONAL INFORMATION</div>
            <div class="desc">
              <p>
                Personal Information is information which relates to an
                identified or identifiable natural person or a juristic person,
                by means of an identifying factor and subject to the applicable
                data protection laws. The Personal Information collected and
                processed by the Group may differ depending on purpose of
                collecting and processing your personal information. The Group
                may collect or obtain Personal Information about you, not
                limited to the following instances:
              </p>
              <ul>
                <li>directly from you;</li>
                <li>in the course of our relationship with you;</li>
                <li>when you make your Personal Information public;</li>
                <li>when you visit our offices;</li>
                <li>
                  when you visit and/or interact with the Group on our website,
                  emails or our various social media platforms;
                </li>
                <li>
                  in the course of providing services to you or your
                  organisation;
                </li>
                <li>
                  from persons and entities employed by the Group to provide
                  services to the Group which may be legally entitled to provide
                  the Group with Personal Information;
                </li>
                <li>
                  the Group may also receive Personal Information about you from
                  third parties (eg, law enforcement authorities; credit
                  bureaus).
                </li>
              </ul>
              <p>
                In addition to the above, the Group may create Personal
                Information about you such as records of your communications and
                interactions with us, including, but not limited to, your
                attendance at events or at interviews in the course of applying
                for a job with us.
              </p>
            </div>
          </div>
          <div class="categories-personal">
            <div class="sub-title">
              4. CATEGORIES OF PERSONAL INFORMATION TO BE PROCESSED
            </div>
            <div class="desc">
              <p>
                The Group may process the following categories of Personal
                Information about you, without limitation:
              </p>
              <ul>
                <li>personal details: name and photograph;</li>
                <li>
                  demographic information: gender; date of birth / age;
                  nationality; salutation; title; and language preferences;
                </li>
                <li>
                  identifier information: passport or national identity number;
                </li>
                <li>
                  contact details: correspondence address; telephone number;
                  email address; and details of your public social media
                  profile(s);
                </li>
                <li>
                  attendance records: details of meetings and other events
                  organised by or on behalf of the Group that you have attended;
                </li>
                <li>
                  consent records: records of any consents you may have given,
                  together with the date and time, means of consent and any
                  related information;
                </li>
                <li>
                  employment details, -history, qualifications, and
                  designations;
                </li>
                <li>
                  educational details, including student number, educational
                  establishment, dates of study, subject studied, exam results,
                  student training records, awards etc.
                </li>
                <li>
                  payment details: billing address; payment method; bank account
                  number or credit card number; invoice records; payment
                  records; SWIFT details; IBAN details; payment amount; payment
                  date; and records of cheques;
                </li>
                <li>
                  data relating to your visits to our Website: your device type;
                  operating system; browser type; browser settings; IP address;
                  language settings; dates and times of connecting to a Website;
                  and other technical communications information;
                </li>
                <li>complaints and disciplinary records;</li>
                <li>video and voice recordings;</li>
                <li>company details;</li>
                <li>details of membership or other professional bodies;</li>
                <li>
                  any other information which the Group may, in order to conduct
                  its primary and secondary objectives, be required to process.
                </li>
              </ul>
            </div>
          </div>
          <div class="personal-info">
            <div class="sub-title">5. SPECIAL PERSONAL INFORMATION</div>
            <div class="desc">
              <p>
                Where the Group needs to process your Special Personal
                Information, the Group will do so in the ordinary course of
                business, for a legitimate purpose, and in accordance with
                applicable laws. Special Personal Information includes but not
                limited to information relating to, health; criminal behaviour;
                personal information of children; biometric and race or ethnic
                origin.
              </p>
            </div>
          </div>
          <div class="use-of-personal_info">
            <div class="sub-title">6. USE OF YOUR PERSONAL INFORMATION</div>
            <div class="desc">
              <p>
                The Group collects and processes Personal Information for the
                below purposes, including but not limited to:
              </p>
              <ul>
                <li>
                  the purpose of providing any services or products to the Data
                  Subject from time to time;
                </li>
                <li>
                  the purposes of receiving services or products provided by the
                  Data Subject from time to time;
                </li>
                <li>
                  the Data Subject is an employee, in the course and scope of
                  employment by the Group;
                </li>
                <li>
                  respond to any correspondence that the Data Subject may send
                  to the Group, including via e-mail or by telephone;
                </li>
                <li>
                  such other purposes to which the Data Subject may consent from
                  time to time; and
                </li>
                <li>such other uses authorised in terms of applicable laws</li>
              </ul>
            </div>
          </div>
          <div class="sharing">
            <div class="sub-title">7. SHARING OF PERSONAL INFORMATION</div>
            <div class="desc">
              <p>
                The Group may disclose your Personal Information to third-party
                service providers if:
              </p>
              <ul>
                <li>
                  Sharing is necessary to fulfil certain conditions, e.g.
                  applications for training agreement, membership, recruitment;
                </li>
                <li>Sharing is required by law;</li>
                <li>Sharing protects your legitimate interest;</li>
                <li>
                  Sharing is necessary for pursuing our or a third parties to
                  whom the information is supplied legitimate; and/or
                </li>
                <li>
                  Sharing was agreed to by you (or a competent person where you
                  are a child) in the form of a consent.
                </li>
              </ul>
            </div>
          </div>
          <div class="access-personal_info">
            <div class="sub-title">
              8. PERSONS WHO WILL ACCESS YOUR PERSONAL INFORMATION
            </div>
            <div class="desc">
              <p>
                The Group’s employees will have access to your Personal
                Information to administer and manage our inclusive services and
                our various stakeholder relationships. Your personal information
                will further be shared with third parties, subject to the
                purpose of us collecting and processing your information,
                including but not limited to:
              </p>
              <ul>
                <li>
                  To the divisions and entities in the Group, including
                  directors, contractors, agents, auditors, legal and other
                  professional advisors who are authorised to process this
                  information;
                </li>
                <li>Service providers;</li>
                <li>
                  To any person who acts as legal guardian, executor of an
                  estate, curator or in a similar capacity;
                </li>
                <li>
                  Oversight and other regulators, professional bodies and
                  statutory bodies;
                </li>
                <li>Our Board and sub-Board Committee;</li>
                <li>Other Committee members;</li>
                <li>Government agencies;</li>
                <li>Law enforcement, e.g. SAPS</li>
              </ul>
            </div>
          </div>
          <div class="protection">
            <div class="sub-title">
              9. PROTECTION OF YOUR PERSONAL INFORMATION
            </div>
            <div class="desc">
              <p>
                The Group will take the necessary steps to secure the integrity
                and confidentiality of personal information in its possession
                and under its control by taking appropriate, reasonable
                technical and organisation measures to prevent loss of, damage
                to or unauthorised destruction of your personal information and
                unlawful access to or processing of personal information,
                regardless of the format in which it is held.
              </p>
              <p>
                Where there are reasonable grounds to believe that your Personal
                Information that is in our possession has been accessed or
                acquired by any unauthorised person, the Group will notify the
                relevant regulator and you, unless a public body responsible for
                detection, prevention or investigation of offences or the
                relevant regulator informs us that notifying you will impede a
                criminal investigation.
              </p>
            </div>
          </div>
          <div class="data-retention">
            <div class="sub-title">10. DATA RETENTION</div>
            <div class="desc">
              <p>
                The Group shall only retain and store Personal Information for
                the period for which the data is required to serve its primary
                purpose or a legitimate interest or for the period required to
                comply with an applicable legal requirement, whichever is
                longer.
              </p>
            </div>
          </div>
          <div class="data-accuracy">
            <div class="sub-title">11. DATA ACCURACY</div>
            <div class="desc">
              <p>
                The Group will ensure that all Personal Information is kept as
                accurate, complete and up-to-date. Should Personal Information
                change, the onus is on the provider of such data to notify the
                Group of the change and provide the Group with the accurate
                data.
              </p>
            </div>
          </div>
          <div class="legal-rights">
            <div class="sub-title">12. YOUR LEGAL RIGHTS</div>
            <div class="desc">
              <p>
                You may have rights under the South African and other laws to
                have access to your Personal Information and to ask the Group to
                rectify, erase and restrict use of, your Personal Information.
                You may also have rights to object to your Personal Information
                being used, to ask for the transfer of Personal Information you
                have made available to us and to withdraw consent to the use of
                your Personal Information.
              </p>
            </div>
          </div>
          <div class="information-flows">
            <div class="sub-title">13. TRANS-BORDER INFORMATION FLOWS</div>
            <div class="desc">
              <p>
                The Group may also send Personal Information to a foreign
                jurisdiction outside of the Republic of South Africa in order to
                achieve the purpose for which the Personal Information was
                collected and processed, including for processing and storage by
                third party service providers. When Personal Information is
                transferred to a jurisdiction outside the Republic of South
                Africa, the Group will obtain the necessary consent to transfer
                the Personal Information to such foreign jurisdiction or
                transfer the Personal Information without the necessary consent
                where the Group is permitted to do so in accordance with the
                laws applicable to the trans-border flows of Personal
                Information under the POPIA.
              </p>
            </div>
          </div>
          <div class="cookies">
            <div class="sub-title">14. COOKIES</div>
            <div class="desc">
              <p>
                The Group may process your Personal Information by our use of
                Cookies. Cookies are small text files sent by a web server to
                store on a web browser. They are used to ensure websites
                function properly, store user preferences when needed and
                collect anonymous statistics on website usage. When you visit
                our Website we may place Cookies onto your device, or read
                Cookies already on your device, subject always to obtaining your
                consent, where required, in accordance with applicable law.
              </p>
              <p>
                You may refuse to accept cookies by activating the setting on
                your browser which allows you to refuse the setting of cookies.
                However, if you select this setting you may be unable to access
                certain parts of the Website. Unless you have adjusted your
                browser setting so that it will refuse cookies, our system will
                issue cookies when you log on to the Website. If you accept a
                cookie or fail to deny the use of cookies, you agree that we may
                use your personal information collected using cookies.
              </p>
            </div>
          </div>
          <div class="protection">
            <div class="sub-title">15. CHANGES TO DATA PROTECTION NOTICE</div>
            <div class="desc">
              <p>
                The Group will review this Privacy Notice and may amend or
                supplement this Notice from time to time, in accordance with
                regulatory changes, business strategies and new technology
                introduced into our operations. We will publish an updated
                version of this Notice, as and when amendments or supplements
                have been made on our website.
              </p>
            </div>
          </div>
          <div class="contact">
            <div class="sub-title">16. CONTACT DETAILS</div>
            <div class="desc">
              <p>
                You may address all your enquiries, requests and/or concerns
                regarding this Notice or relating to the processing of personal
                information to
                <a :href="'mailto:' + 'popia@randwater.co.za'"
                  >popia@randwater.co.za</a
                >
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </parent-component>
</template>

<script>
import ParentComponent from '@/components/ParentComponent.vue';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: 'PrivacyTemplate',
  components: { ParentComponent },
  computed: {
    publicPath() {
      return process.env.BASE_URL;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins.scss';

.partners {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  @include d-flex-column;
  gap: 30px;
  padding: 40px 0 40px;

  .generate-title {
    text-align: center;
    .privacy_logo {
      width: 25%;
      height: auto;
      margin-bottom: 15px;
    }
    .title {
      font-weight: $fw-500;
      font-size: 20px;
      line-height: 24px;
      color: #103b7c;
    }
    span {
      font-size: 12px;
      font-weight: $fw-400;
      line-height: 16px;
      color: #103b7c;
    }
  }
}

.introduction,
.about_us,
.collection-personal_info,
.categories-personal,
.personal-info,
.use-of-personal_info,
.sharing,
.access-personal_info,
.protection,
.data-retention,
.data-accuracy,
.legal-rights,
.information-flows,
.cookies,
.protection,
.contact {
  padding-bottom: 20px;
}

.sub-title {
  font-weight: $fw-600;
  text-align: left;
  font-size: 14px;
  line-height: 147.02%;
  color: $color-black;
  padding-bottom: 20px;
}

.desc {
  color: $color-black;
  font-weight: $fw-400;
  line-height: 30px;
  font-size: 14px;
  text-align: left;
  padding-left: 15px;
}

.responsibility {
  @include d-flex-row-between;
  & p:last-child {
    margin-left: auto;
  }
}

@media only screen and (max-width: 480px) {
  .privacy_logo {
    width: 50% !important;
    height: auto;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) {
  .generate-title {
    max-width: 750px;
    margin: 0 auto;
  }
}
</style>
