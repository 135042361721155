<template>
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.29784 20.1835C4.04024 21.1064 5.15573 22.0001 6.43956 22.0001C7.17002 22.0001 7.79044 21.6564 8.45734 21.4055C9.07148 21.179 9.72089 21.0626 10.3754 21.0617C11.007 21.058 11.6328 21.1808 12.2162 21.4227C12.5256 21.5532 12.8316 21.6924 13.146 21.8127C13.465 21.936 13.8042 21.9995 14.1462 22.0001C14.7791 21.9929 15.3949 21.7946 15.9132 21.4313C17.4187 20.4 18.5342 18.5885 19.3248 17.0023C19.4038 16.8443 19.4795 16.6843 19.5551 16.5263C19.6807 16.2616 19.483 15.9264 19.1324 15.734C17.5855 14.8849 16.6798 13.5718 16.5285 11.7603C16.3772 9.94881 17.0819 8.51889 18.4965 7.48067L18.7748 7.25202C18.9295 7.1248 18.8453 6.77247 18.5548 6.49748V6.49733C17.4849 5.44371 16.0516 4.84092 14.5501 4.81299C14.2587 4.81315 13.9679 4.83724 13.6804 4.88512C12.3742 5.10855 11.3258 5.7308 10.0952 5.7308C9.72187 5.7311 9.35066 5.6731 8.99525 5.55893C7.96403 5.22884 6.93972 4.81299 5.84312 4.81299C5.72281 4.81299 5.60081 4.81299 5.47882 4.82849C4.12687 4.97258 2.86469 5.57337 1.90037 6.53168C-0.000498282 8.40509 -0.270267 11.315 0.204057 13.7742C0.659525 16.1563 1.78004 18.303 3.29773 20.1831L3.29784 20.1835Z"
      fill="white"
    />
    <path
      d="M9.96843 4.81283C9.99298 4.81498 10.0177 4.81498 10.0424 4.81283C13.6739 4.13564 14.071 1.33075 14.0934 0.30648C14.095 0.222694 14.0617 0.142129 14.0012 0.0839675C13.9409 0.0259597 13.8591 -0.00426992 13.7755 0.000487456C13.7448 0.000487456 13.7142 0.00386351 13.6843 0.0107691C10.0167 0.85983 9.63524 3.50495 9.62463 4.49316C9.62632 4.58155 9.66392 4.66564 9.72868 4.7258C9.79359 4.78611 9.88004 4.81744 9.96843 4.81283Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'AppleAuthIcon',
};
</script>

<style scoped></style>
