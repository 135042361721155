<template>
  <router-view />
  <LoaderPWA v-if="isLoaded" class="loader" />
</template>

<script>
import LoaderPWA from './components/LoaderPWA.vue';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isLoaded']),
  },
  components: {
    LoaderPWA,
  },
};
</script>

<style lang="scss">
*,
::after,
::before {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  min-width: 100px;
  min-height: 100%;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body,
html {
  margin: 0;
  box-sizing: border-box;
}
#app,
body,
html {
  width: 100%;
  direction: ltr;
}

img {
  object-fit: cover;
}

//#############[ INTER fonts ]###############//

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter-Black.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(./assets/fonts/Inter-ExtraBold.ttf) format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(./assets/fonts/Inter-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter-Thin.ttf) format('truetype');
  font-weight: 100;
}

//#############[ MONTSERRAT fonts ]###############//

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat-BlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(./assets/fonts/Montserrat-Black.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat-ExtraBoldItalic.ttf) format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(./assets/fonts/Montserrat-ExtraBold.ttf) format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat-SemiBoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(./assets/fonts/Montserrat-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(./assets/fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat-ExtraLightItalic.ttf) format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat-ThinItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat-Thin.ttf) format('truetype');
  font-weight: 100;
}
</style>
