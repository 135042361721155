<template>
  <div class="form-outline">
    <label class="title-label" :for="id">{{ label }}</label>
    <textarea
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="label"
      :id="id"
      required="required"
      class="form-control form-control-lg"
      :rows="rows"
      :class="{ 'is-invalid': validInput }"
    ></textarea>
    <div class="invalid-feedback">
      {{ validInput }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Label',
    },
    rows: {
      type: Number,
      default: 8,
    },
    id: {
      type: String,
      default: 'text_content',
    },
    modelValue: {
      type: String,
      default: '',
    },
    validInput: {
      type: String,
      default() {
        return '';
      },
    },
  },
  emits: ['update:modelValue'],
  name: 'BaseTextarea',
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins';

.title-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 147.02%;
  color: #000000;
  text-align: left;
  width: 100%;
}
input::placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 147.02%;
  display: flex;
  align-items: center;
  color: #bababa;
}
.is-invalid {
  border: 1px solid #f64e4e !important;
}
.invalid-feedback {
  color: #f64e4e;
}
.form-control:focus {
  @include shadow-default-form;
}
</style>
