<template>
  <div class="main">
    <header-control-button
      :title="title"
      :user="getUser"
      :position="{ position: position }"
      :top="{ top: top + 'px' }"
      @openSidebar="openSidebarMenu"
      class="control-header"
    />
    <section v-for="(block, index) of pages" :key="index" id="section-block">
      <div v-if="block.type === 'image_title_html'">
        <img
          v-if="block?.image_url"
          class="logo"
          :src="block?.image_url"
          alt="logo"
        />
        <div
          id="about"
          class="background d-flex flex-column gap-3 mb-4"
          :class="{
            padding_block: !block?.image_url,
          }"
        >
          <div class="title">{{ block?.title }}</div>
          <div class="description preview ql-editor" v-html="block?.html"></div>
        </div>
      </div>
      <div v-if="block.type === 'multiple_images'" class="multiple-image">
        <div class="images gap-3" v-if="block?.images.length">
          <div
            class="image-block"
            v-for="(image, index) in block.images"
            :key="index"
            :class="{
              full_width: arrImagesLength % 2 === 1 && image?.index === 0,
            }"
          >
            <img :src="image?.image_url" alt="logo" />
            <p class="title-img">{{ image?.title }}</p>
          </div>
        </div>
        <div id="about" class="background d-flex flex-column gap-3">
          <div class="title">{{ block?.title }}</div>
          <div class="description preview ql-editor" v-html="block?.html"></div>
        </div>
      </div>
    </section>
    <slide-menu :user="getUser" :show="isOpen" @close="openSidebarMenu" />
  </div>
</template>

<script>
import HeaderControlButton from '@/components/HeaderControlButton.vue';
import { mapGetters } from 'vuex';
import SlideMenu from '@/components/SlideMenu.vue';
import { baseErrHandler, loadPageInform } from '@/services/api';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: 'AboutUsTemplate',
  components: { SlideMenu, HeaderControlButton },
  created() {
    this.getAboutUsData();
  },
  data() {
    return {
      title: 'About Us',
      isOpen: false,
      color: '#182337',
      background: '#64748B',
      position: 'absolute',
      top: '90',
      pages: [],
      arrImagesLength: 0,
    };
  },
  methods: {
    openSidebarMenu() {
      this.isOpen = !this.isOpen;
    },
    async getAboutUsData() {
      await baseErrHandler(async () => {
        const { page } = await loadPageInform({ pageName: 'about_us' });
        this.pages = page?.blocks;
        this.arrImagesLength = page?.blocks
          .map((block) => {
            if (block?.images) {
              return block?.images?.length;
            }
          })
          .filter((item) => !!item)
          .join();
      });
    },
  },
  computed: {
    ...mapGetters(['getUser']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins.scss';
@import '@/assets/_variables.scss';

.main {
  background: linear-gradient(180deg, #0058a2 0%, #0095ab 100%);
  height: 100%;
  padding-bottom: 20px;
}
.padding_block {
  padding-top: 160px !important;
}
.logo {
  position: relative;
  width: 100%;
  height: 100%;
}
.control-header {
  padding: 0 25px;
  z-index: 1;
  position: absolute;
}
.background {
  padding: 30px 25px;
}
.title {
  width: fit-content;
  padding: 7px;
  background: #0095ab;
  border-radius: 10px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: $fw-700;
  font-size: 22px;
  line-height: 24px;
  color: $color-white;
  justify-content: center;
  @include d-flex-center;
}
.images {
  @include d-flex-row-center;
  justify-content: center;
  flex-wrap: wrap;
  .image-block {
    position: relative;
    gap: 5px;
    @include d-flex-column;
    width: calc(50% - 30px);
  }
  img {
    width: 100%;
    height: 140px;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    border-radius: 15px;
  }
  .title-img {
    padding: 10px 10px;
    font-weight: $fw-700;
    font-size: 16px;
    line-height: 19px;
    color: $color-white;
    height: auto;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: end;
    @include p-absolut-center;
  }
}
.full_width {
  width: calc(100% - 42px) !important;
}
</style>

<style>
#about > .ql-editor ol,
#about > .ql-editor ul {
  padding-left: 0;
}
#about > .ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 1em;
  text-align: right;
  content: '';
  border-right: 2px solid #52e2ff;
  border-bottom: 2px solid #52e2ff;
  transform: translateY(-50%) rotate(45deg);
  width: 8px;
  height: 12px;
  position: relative;
  top: 3px;
}
#about > .preview em,
#about > .preview span,
#about > .preview strong {
  font-family: Inter, sans-serif !important;
  font-style: normal !important;
  /* font-weight: 500!important; */
  /* font-size: 14px!important; */
  line-height: 24px !important;
  /*color: #fff !important;*/
  /*opacity: 0.7 !important;*/
}

.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6,
.ql-editor ol,
.ql-editor p,
.ql-editor pre,
.ql-editor ul {
  margin-bottom: 1rem !important;
}

@media only screen and (min-width: 768px) {
  /*.control-button__back p {*/
  /*  left: 15%;*/
  /*}*/
  #section-block {
    width: 600px;
    margin: 0 auto;
  }
}
</style>
