<template>
  <div class="main">
    <div class="slot">
      <slot></slot>
    </div>
    <menu-template />
  </div>
</template>

<script>
import MenuTemplate from '@/components/MenuTemplate.vue';

export default {
  name: 'MainTemplate',
  components: { MenuTemplate },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.main {
  background-image: url('@/assets/app_img/dashboard-background.png');
  background-size: cover;
  @include vw-vh-scroll;
  .slot {
    padding-bottom: 80px;
    min-height: 100vh;
    @include d-flex-column;
  }
}
</style>
