<template>
  <svg
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.95862 0.090332L3.53962 3.50937L1.9938 1.96347L0.813477 3.14375L3.53962 5.86985L8.1389 1.27066L6.95862 0.090332Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'DoneIcon',
};
</script>

<style lang="scss" scoped>
svg {
  border-radius: 40px;
  height: 14px;
  width: 14px;
  background: #0058a2;
  padding: 1px;
}
</style>
