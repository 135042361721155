<template>
  <div class="container_page">
    <div class="login_form_body">
      <div class="control-button">
        <back-icon class="control-button__back" @click="$router.go(-1)" />
        <p class="control-button__skip" @click="skipCheckAuthUser">Skip</p>
      </div>
      <div class="welcome_block">
        <div class="title_welcome text-center">Sign Up</div>
        <p>
          Please enter your details in the form below to create your account.
        </p>
      </div>

      <form action="#" @submit.prevent autocomplete="off" class="form-pwa">
        <div class="form_block">
          <div class="input_block">
            <div class="input_icon">
              <profile-user-icon />
            </div>
            <input
              v-model="formData.name"
              type="text"
              autocomplete="off"
              class="form__input"
              placeholder="Name"
            />
          </div>
          <div class="input_block">
            <div class="input_icon">
              <profile-user-icon />
            </div>
            <input
              v-model="formData.surname"
              type="text"
              autocomplete="off"
              class="form__input"
              placeholder="Surname"
            />
          </div>
          <div class="input_block">
            <div class="input_icon">
              <phone-icon />
            </div>
            <input
              v-model="formData.phone"
              type="text"
              autocomplete="off"
              class="form__input"
              placeholder="Cell number"
            />
          </div>
          <!--          <div class="input_block">-->
          <!--            <div class="input_icon">-->
          <!--              <profile-user-icon />-->
          <!--            </div>-->
          <!--            <input-->
          <!--              v-model="formData.id_card"-->
          <!--              type="text"-->
          <!--              autocomplete="off"-->
          <!--              class="form__input"-->
          <!--              placeholder="ID/ Passport number"-->
          <!--            />-->
          <!--          </div>-->
          <div class="input_block">
            <div class="input_icon">
              <email-icon />
            </div>
            <input
              v-model="formData.email"
              type="email"
              autocomplete="off"
              class="form__input"
              placeholder="Email address"
            />
          </div>
          <div class="input_block">
            <div class="input_icon">
              <password-lock />
            </div>
            <input
              v-model="formData.password"
              :type="view_password ? 'text' : 'password'"
              autocomplete="off"
              class="form__input"
              placeholder="New Password"
            />
            <div class="show_pass" @click="showPassword">
              <eye-icon v-if="!view_password" />
              <eye-open-icon v-else />
            </div>
          </div>
          <div class="input_block">
            <div class="input_icon">
              <password-lock />
            </div>
            <input
              v-model="formData.password_confirm"
              :type="view_confirm_password ? 'text' : 'password'"
              autocomplete="off"
              class="form__input"
              placeholder="Confirm New Password"
            />
            <div class="show_pass" @click="showConfirmPassword">
              <eye-icon v-if="!view_confirm_password" />
              <eye-open-icon v-else />
            </div>
          </div>
        </div>
      </form>

      <div class="terms-condition">
        <input type="checkbox" v-model="checked" />
        By pressing the Register button, you confirm that you have read and
        accept the
        <a :href="`${publicPath}privacy`" class="terms-condition__link"
          >Group Privacy Notice</a
        >
      </div>
      <div class="sign-and-social">
        <div class="btn">
          <button
            class="btn__sign-in"
            :class="{ 'opacity-50': !checked }"
            :disabled="!checked"
            @click="registerInService"
          >
            Register
          </button>
        </div>
      </div>
      <div class="register-block">
        Already have an account?
        <span class="register-block__link" @click="goToSignIn">Sign In</span>
      </div>
    </div>
  </div>
</template>
<script>
import { isInValidEmail, rules } from '@/helpers/validate.helper';
import EyeOpenIcon from '@/components/svg/EyeOpenIcon.vue';
import EyeIcon from '@/components/svg/EyeCloseIcon.vue';
import EmailIcon from '@/components/svg/EmailIcon.vue';
import PasswordLock from '@/components/svg/PasswordLock.vue';
import BackIcon from '@/components/svg/BackIcon.vue';
import ProfileUserIcon from '@/components/svg/ProfileUserIcon.vue';
import PhoneIcon from '@/components/svg/PhoneIcon.vue';
import { errorMessage } from '@/helpers/messages';
import { mapGetters, mapMutations } from 'vuex';
import { baseErrHandler, registerAccount } from '@/services/api';
import { base_url, pwa_jwt_key } from '@/helpers/enums';

export default {
  name: 'RegisterTemplate',
  components: {
    PhoneIcon,
    ProfileUserIcon,
    EyeOpenIcon,
    EyeIcon,
    EmailIcon,
    PasswordLock,
    BackIcon,
  },
  inject: ['gtag'],
  async mounted() {
    await this.redirectAuthUser();
  },
  data() {
    return {
      checked: false,
      formData: {
        name: '',
        surname: '',
        phone: '',
        // id_card: '',
        email: '',
        password: '',
        password_confirm: '',
      },
      view_password: false,
      view_confirm_password: false,
    };
  },
  methods: {
    ...mapMutations(['setUser']),
    handleBuy(user) {
      const { name = '', surname = '' } = user;
      this.gtag.event('new-user', {
        'event-label': 'new-register-user',
        value: `${name} ${surname}`,
      });
    },
    async redirectAuthUser() {
      if (this.isLogged) {
        await this.skipCheckAuthUser();
      }
    },
    async skipCheckAuthUser() {
      await this.$router.push('/dashboard');
    },
    async goToSignIn() {
      await this.$router.push('/auth/log-in');
    },
    async gotToDashboard() {
      return await this.$router.push('/dashboard');
    },
    showPassword() {
      this.view_password = !this.view_password;
    },
    showConfirmPassword() {
      this.view_confirm_password = !this.view_confirm_password;
    },
    async registerInService() {
      if (!this.isValidFormRegistration()) {
        return;
      }
      delete this.formData.password_confirm;
      await baseErrHandler(async () => {
        const { token, user } = await registerAccount(this.formData);
        if (token && user) {
          localStorage.setItem(pwa_jwt_key, token);
          this.setUser(user);
          this.handleBuy(user);
          return await this.gotToDashboard();
        }
      });
    },
    isValidFormRegistration() {
      if (
        !this.formData.name ||
        !this.formData.surname ||
        !this.formData.phone ||
        // !this.formData.id_card ||
        !this.formData.email ||
        !this.formData.password ||
        !this.formData.password_confirm
      ) {
        errorMessage('Please fill in all required fields.');
        return false;
      }
      if (this.formData.email) {
        if (isInValidEmail(this.formData.email)) {
          errorMessage('Wrong email format. Please, try again');
          return false;
        }
      }
      if (this.formData.password) {
        for (let condition of rules()) {
          if (!condition.regex.test(this.formData.password)) {
            errorMessage(condition.message);
            return false;
          }
        }
      }
      if (this.formData.password !== this.formData.password_confirm) {
        errorMessage('Password does not match. Please try again. ');
        return false;
      }
      return true;
    },
    clearForm() {
      this.formData.name = '';
      this.formData.surname = '';
      this.formData.phone = '';
      // this.formData.id_card = '';
      this.formData.email = '';
      this.formData.password = '';
      this.formData.password_confirm = '';
    },
  },
  computed: {
    ...mapGetters(['isLogged']),
    publicPath() {
      return base_url;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';

.container_page {
  background-image: url('@/assets/app_img/register-background.png');
  overflow: scroll;
  position: relative;
  background-size: cover;
  min-height: 100vh;

  .login_form_body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 28px;
    padding: 0 25px;
    .control-button {
      justify-content: space-between;
      display: flex;
      align-items: end;
      height: 80px;
      &__skip {
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 147.02%;
        text-align: right;
        color: $color-white;
      }
      &__back {
        cursor: pointer;
      }
    }
    .welcome_block {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .welcome_block > div {
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      color: $color-white;
    }
    .welcome_block > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 147.02%;
      text-align: center;
      opacity: 0.4;
      color: $color-white;
    }
    .form_block {
      .input_block {
        width: 100%;
        position: relative;
        margin-bottom: 14px;

        .form-label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #222626;
          margin-bottom: 4px;
        }

        input {
          padding-left: 40px;
          width: 100%;
          height: 58px;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.18);
          border-radius: 17px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 147.02%;
          color: $color-white;
          text-align: start;

          &::placeholder {
            text-align: start;
            font-weight: 500;
            font-size: 16px;
            line-height: 147.02%;
            color: $color-white;
          }
        }

        .input_icon {
          position: absolute;
          left: 16px;
          top: 16px;
          min-width: 16px;
        }

        .show_pass {
          position: absolute;
          right: 16px;
          top: 16px;
          min-width: 16px;
        }

        .press_here {
          font-weight: 500;
          font-size: 14px;
          line-height: 147.02%;
          text-align: right;
          color: $color-white;
          margin-top: 17px;

          span {
            cursor: pointer;
            color: $color-white;
          }
        }
      }
    }
    .terms-condition {
      position: relative;
      top: -20px;
      font-weight: 400;
      font-size: 12px;
      line-height: 147.02%;
      text-align: center;
      color: rgba(255, 255, 255, 0.53);
      &__link {
        opacity: 1;
        font-weight: 700;
        text-decoration: underline;
        color: $color-white;
        cursor: pointer;
      }
    }
    .sign-and-social {
      position: relative;
      top: -35px;
      display: flex;
      flex-direction: column;
      .btn {
        width: 100%;
        padding: 0;
        &__sign-in {
          cursor: pointer;
          width: 100%;
          height: 58px;
          border-radius: 15px;
          text-align: center;
          align-items: center;
          display: flex;
          justify-content: center;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: #0058a2;
          border: none;
          background: $color-white;
        }
      }
      .or_row {
        font-weight: 400;
        font-size: 16px;
        line-height: 147.02%;
        color: $color-white;
        opacity: 0.8;
        text-transform: uppercase;
      }
      .social-button {
        display: flex;
        gap: 8px;

        &__google,
        &__apple {
          height: 58px;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 13px;
          width: 50%;
        }
      }
    }
    .register-block {
      line-height: 147.02%;
      text-align: center;
      color: $color-white;
      opacity: 0.8;
      position: relative;
      bottom: 22px;
      &__link {
        cursor: pointer;
        opacity: 1;
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
}

.container_page::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-width: 768px) {
  .form-pwa,
  .sign-and-social,
  .terms-condition {
    width: 540px;
    margin: 0 auto;
  }
}
</style>
