<template>
  <svg
    width="27"
    height="22"
    viewBox="0 0 27 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.0384 4.71582L16.8879 12.5954C15.8676 13.4718 14.4853 13.9639 13.0442 13.9639C11.6031 13.9639 10.2208 13.4718 9.2005 12.5954L0.0500027 4.71582C0.0347845 4.86371 0 4.99757 0 5.14453V16.377C0.00172603 17.6178 0.574903 18.8074 1.5938 19.6848C2.61271 20.5622 3.99414 21.0558 5.43508 21.0572H20.6533C22.0942 21.0558 23.4757 20.5622 24.4946 19.6848C25.5135 18.8074 26.0867 17.6178 26.0884 16.377V5.14453C26.0884 4.99757 26.0536 4.86371 26.0384 4.71582Z"
      fill="#0095AB"
    />
    <path
      d="M15.3507 10.8075L25.2795 2.2568C24.7985 1.57004 24.1201 1.0016 23.3098 0.606302C22.4995 0.211005 21.5845 0.00219096 20.6532 0H5.43494C4.5036 0.00219096 3.58864 0.211005 2.7783 0.606302C1.96795 1.0016 1.28958 1.57004 0.808594 2.2568L10.7374 10.8075C11.35 11.3329 12.1794 11.6279 13.044 11.6279C13.9087 11.6279 14.7381 11.3329 15.3507 10.8075Z"
      fill="#0095AB"
    />
  </svg>
</template>

<script>
export default {
  name: 'EmailAddressIcon',
};
</script>

<style scoped></style>
