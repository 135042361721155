<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#0095AB" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.5956 27.7177C22.8934 27.4244 22.8932 26.944 22.5952 26.651L16.9566 21.1049C16.5583 20.7131 16.5583 20.0709 16.9566 19.679L22.5954 14.1328C22.8933 13.8398 22.8934 13.3596 22.5957 13.0664C22.3043 12.7794 21.8364 12.7796 21.5452 13.0669L14.8394 19.6837C14.4424 20.0754 14.4426 20.7162 14.8398 21.1077L21.5451 27.7174C21.8364 28.0046 22.3042 28.0047 22.5956 27.7177Z"
      fill="white"
      stroke="white"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'BackIcon',
};
</script>

<style lang="scss" scoped>
svg > path {
  cursor: pointer;
}
</style>
