<template>
  <svg
    width="22"
    height="12"
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.07154 10.0642H0.658095C0.294621 10.0642 0 9.76953 0 9.40607V1.23964C0 0.876164 0.294643 0.581543 0.658095 0.581543H2.07154C2.43502 0.581543 2.72964 0.876185 2.72964 1.23964V9.40607C2.72964 9.76955 2.435 10.0642 2.07154 10.0642Z"
      fill="white"
    />
    <path
      d="M19.9284 10.051H21.3418C21.7053 10.051 21.9999 9.75634 21.9999 9.39289V1.22645C21.9999 0.86298 21.7053 0.568359 21.3418 0.568359H19.9284C19.5649 0.568359 19.2703 0.863002 19.2703 1.22645V9.39289C19.2703 9.75636 19.5649 10.051 19.9284 10.051Z"
      fill="white"
    />
    <path
      d="M14.6562 1.70797L13.6384 0.807261C12.7987 -0.168663 11.2691 -0.275595 10.301 0.573227L7.23526 3.26178C6.71298 3.71959 6.66428 4.55317 7.13268 5.0684C7.5932 5.57491 8.38964 5.62309 8.90926 5.17501L11.5803 2.84576C11.7479 2.6997 11.9994 2.71112 12.1531 2.87162L17.8964 8.86676H18.2554L18.2552 1.70775L14.6562 1.70797Z"
      fill="white"
    />
    <path
      d="M15.8176 8.50481L11.5612 4.04789L9.54744 5.87785C8.65545 6.69329 7.27105 6.63016 6.45716 5.737C5.64507 4.84587 5.70804 3.46534 6.59785 2.65175L8.78372 0.653226C7.55748 0.0127535 6.00452 0.381587 5.21547 1.57642H3.79248V9.18241H5.04072L7.06357 11.2504C7.39716 11.6205 7.94111 11.7131 8.37864 11.4747C8.96002 11.1579 9.1138 10.4039 8.72734 9.88669L9.78286 11.0661C10.0777 11.3956 10.5503 11.4999 10.9566 11.3251L10.9826 11.3139C11.6547 11.0246 11.8472 10.1675 11.3655 9.62193C10.6638 8.82699 10.0184 8.06897 10.0184 8.06897L12.0458 10.3549C12.3779 10.7261 12.9195 10.825 13.3614 10.5952L13.3879 10.5814C14.0526 10.2357 14.1128 9.31168 13.6088 8.76353C12.6415 7.71153 11.5922 6.49523 11.5922 6.49523C11.5922 6.49523 13.4426 8.48184 14.4095 9.51433C14.7122 9.83751 15.1828 9.94093 15.5931 9.77455L15.621 9.7633C16.1682 9.42233 16.0554 8.88322 15.8177 8.50484L15.8176 8.50481Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'PartnerIcon',
};
</script>

<style scoped></style>
