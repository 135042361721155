<template>
  <div
    class="modal fade"
    id="inviteModal"
    ref="inviteModal"
    tabindex="-1"
    aria-labelledby="inviteModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex">
            <button class="btn close-modal" @click="closeModal">
              <close-input-icon />
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class="this_modal_body">
            <p class="title_app_form">Are you sure you want to log out?</p>
            <div class="step-application-form d-flex justify-content-between">
              <BaseButtonPrevForm :title="`Cancel`" @prev-step="cancel" />
              <BaseButtonForm
                :title="`Ok`"
                :form-input="true"
                @next-step="next"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from 'bootstrap';
import BaseButtonForm from '@/components/forms/BaseButtonForm.vue';
import BaseButtonPrevForm from '@/components/forms/BaseButtonPrevForm.vue';
import CloseInputIcon from '@/components/svg/CloseInputIcon.vue';

export default {
  name: 'LogoutModal',
  components: {
    CloseInputIcon,
    BaseButtonPrevForm,
    BaseButtonForm,
  },
  emits: ['logout'],
  data() {
    return {
      inviteModal: null,
      left: '0',
      background: '#0058A2',
    };
  },
  async mounted() {
    this.inviteModal = new Modal(this.$refs.inviteModal);
  },
  methods: {
    closeModal() {
      this.inviteModal.hide();
    },
    show() {
      this.inviteModal.show();
    },
    next() {
      this.closeModal();
      this.$emit('logout', true);
    },
    cancel() {
      this.closeModal();
    },
  },
  beforeUnmount() {
    /* Cleaning the body from a frozen scroll  */
    document.body.style.overflow = 'auto';
    this.inviteModal.dispose();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/_variables.scss';
@import '@/assets/mixins.scss';

.modal::-webkit-scrollbar {
  display: none;
}
.mobile_display_none {
  display: block;
}

.desktop_display_none {
  display: none;
}

.title_modal {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  color: $color-black;
}

.close-modal {
  position: absolute;
  right: 15px;
}

.invite-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 139px;
  height: 47px;
  border-radius: 10px;
}

.next {
  background: #0058a2;
  color: $color-white;
}

.prev {
  border: 1px solid #0095ab;
  background: $color-white;
  color: #0095ab;
}

.text-btn {
  font-style: normal;
  letter-spacing: 0.8px;
  font-weight: $fw-700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.modal-header {
  border: none;
}

.modal-footer {
  border: none;
}

.this_modal_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
.this_modal_body > span {
  margin-bottom: -0.8rem !important;
}
.title_app_form {
  font-style: normal;
  font-weight: $fw-600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  text-align: center;
  color: red;
}
.mobile_display_none {
  display: none;
}

.desktop_display_none {
  display: block;
}

.modal-dialog {
  //width: 100% !important;
  //height: 100vh !important;
  //min-width: 100% !important;
  //min-height: 100vh !important;
  margin: 0 auto;
}

.modal-header {
  @include d-flex-column-center;
  gap: 40px;
  padding-top: 30px;
}

.modal-header,
.modal-body {
  padding: 20px 32px;
}

.modal-content {
  width: 100% !important;
  border-radius: 0;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  background: $color-white;
  gap: 25px;
}

.modal-body {
  background: $color-white;
}

section {
  gap: 3px;
  display: flex;
  flex-direction: column;
}
.title-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 147.02%;
  color: #000000;
  text-align: left;
  width: 100%;
}
.form-label {
  margin-bottom: 0.2rem;
}

.declaration_title {
  font-weight: $fw-500;
  font-size: 12px;
  line-height: 147.02%;
  color: #d84a4a;
  text-align: left;
}

.signature-paid {
  width: 100%;
  height: 225px;
  background: rgba(0, 88, 162, 0.05);
  border: 2px dashed #add0ee;
  border-radius: 10px;
}

.reset {
  width: 100%;
  height: auto;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cccccc;
  border-radius: 3px;

  button {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 147.02%;
    background: $color-white;
    color: brown;
  }
}

.step-application-form {
  margin-top: 40px;
}

.next-button {
  margin-top: 60px;
}
.is-invalid {
  border: 1px solid #f64e4e !important;
  border-radius: 10px;
}
.invalid-feedback {
  color: #f64e4e;
}
</style>

<style lang="scss">
.v-calendar {
  width: 100% !important;
}
.v-calendar .input-field input {
  width: 100%;
  border: 1px solid #ced4da;
}
.v-calendar.left .content {
  width: 100% !important;
}

@media screen and (max-width: 320px) {
  .v-calendar .calendar .days-selection,
  .v-calendar .calendar .selected-field {
    padding: 0 !important;
  }
  //.v-calendar .input-field {
  //  position: relative;
  //  min-width: 140px;
  //  font-weight: var(--v-calendar-input-font-weight);
  //  width: calc(100% - 35px);
  //}
  //.v-calendar.left .content {
  //  width: calc(100% - 25px) !important;
  //}
}
</style>
