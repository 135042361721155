<template>
  <div class="form-outline">
    <label
      :style="[fontWeight, fontSize]"
      class="form-label title-label"
      :for="id"
      >{{ label }}</label
    >
    <input
      :id="id"
      :value="modelValue"
      :placeholder="placeholder"
      :type="type"
      :disabled="disabled"
      @focus="onTheWayInputError"
      @input="$emit('update:modelValue', $event.target.value)"
      autocomplete="off"
      required="required"
      class="form-control form-control-lg"
      :class="{ 'is-invalid': validInput }"
    />
    <div class="invalid-feedback">
      {{ validInput }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    validInput: {
      type: String,
      default() {
        return '';
      },
    },
    fontWeight: {
      type: Object,
      default() {
        return {};
      },
    },
    fontSize: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      default() {
        return 'text';
      },
    },
    label: {
      type: String,
      default: 'Label',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    id: {
      type: String,
      default: 'text_content',
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'clear-form'],
  name: 'BaseInput',
  methods: {
    onTheWayInputError() {
      this.$emit('clear-form');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins';

.form-control:focus {
  @include shadow-default-form;
}
.title-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 147.02%;
  color: #000000;
  text-align: left;
  width: 100%;
}
.form-label {
  margin-bottom: 0.2rem;
}
input::placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 147.02%;
  display: flex;
  align-items: center;
  color: #bababa;
}
.is-invalid {
  border: 1px solid #f64e4e !important;
}
.invalid-feedback {
  color: #f64e4e;
}
</style>
