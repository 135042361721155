/* PROPERTIES FOR USER KEY AND TOKEN */

export const pwa_jwt_key = 'rw_pwa_jwt';
export const pwa_user = 'rw_pwa_user';

/* HTTP */
export const base_url =
  process.env.BASE_URL || 'https://academyapp.randwater.co.za/';

export const contact_us_email = 'rwainfodesk@randwater.co.za';

export const https_protocol = 'https://';

/* COURSES STATUS LIST */
export const DECLINED = 'DECLINED';
export const CONFIRMED = 'CONFIRMED';
export const COMPLETED = 'COMPLETED';

export const course_status_sponsored = 'Sponsored';
export const course_status_funded = 'Self funded';
export const course_status_free = 'Free';
export const partner_link_sponsored =
  'https://randwater.erecruit.co/candidateapp/jobs/categories';
export const partner_link_free = 'https://opencourseware.online';

/* VALIDATION UPLOAD IMAGES FOR APPLICATION */
export const MAX_10_MB = 10_485_760; // 10MB
export const isCheckFileType = ['png', 'jpeg', 'jpg', 'svg', 'pdf'];
export const file_size_validation = `File size should be less than ${MAX_10_MB} MB`;
export const file_type_validation = `File type should be ${isCheckFileType.join(
  ', ',
)}`;

/* VALIDATION MESSAGE FORM FOR APPLICATION */
export const max_description_input = 150;
export const require_input_error = 'This field is required.';
export const require_form_error_all = 'Please fill in all required fields';
export const incorrect_email_error = 'Incorrect email. Please, try again';
export const max_size_description_error = 'Maximum number of characters 600';

export const monthList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const monthListFullName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
