<template>
  <div
    class="image"
    :class="{
      isCheckFilePdf: file && file.type === accepted_type,
    }"
  >
    <img
      class="preview"
      v-if="file"
      @click="imageClick"
      :src="previewSrc"
      alt="preview"
      :class="{
        upload_pdf: file.type === accepted_type,
      }"
    />
    <span v-if="file && file.type === accepted_type">{{ getFileName }}</span>
    <div
      v-if="file && file.type === accepted_type"
      @click="removeFile"
      class="remove-file"
    >
      <remove-input-file-icon />
    </div>
    <label for="file-input" v-if="!file">
      <span class="upload-icon">
        <upload-application-image-icon />
      </span>
    </label>
    <input
      type="file"
      id="file-input"
      @change="fileChanged"
      ref="document"
      accept="image/*,application/pdf"
    />
    <button
      v-if="file && file.type !== accepted_type"
      @click="removeFile"
      class="btn remove-uploaded"
    >
      DELETE PHOTO
    </button>
  </div>
</template>

<script>
import UploadApplicationImageIcon from '@/components/svg/UploadApplicationImageIcon.vue';
import { isValidFileSize, isValidFileType } from '@/helpers/validate.helper';
import { errorMessage } from '@/helpers/messages';
import { file_size_validation, file_type_validation } from '@/helpers/enums';
import RemoveInputFileIcon from '@/components/svg/RemoveInputFileIcon.vue';

export default {
  name: 'BaseInputFile',
  components: { RemoveInputFileIcon, UploadApplicationImageIcon },
  emits: ['document-file'],
  data() {
    return {
      file: null,
      type: null,
      accepted_type: 'application/pdf',
      setPdfFileData: null,
    };
  },
  methods: {
    async fileChanged({ target }) {
      const file = target?.files[0] || null;
      const { type } = file;
      this.type = type;
      if (!this.validFile(file)) {
        return;
      }
      this.file = file;
      this.$emit('document-file', file);
    },
    getFile() {
      return this.file;
    },
    removeFile() {
      this.$emit('document-file', undefined);
      this.file = null;
      this.$refs.document.value = null;
    },
    validFile(image) {
      let is_valid = true;
      if (isValidFileSize(image.size)) {
        errorMessage(file_size_validation);
        return (is_valid = false);
      }
      if (!isValidFileType(image.name.split('.').pop())) {
        errorMessage(file_type_validation);
        return (is_valid = false);
      }
      return is_valid;
    },
    imageClick() {
      this.$refs.document.click();
    },
  },
  computed: {
    previewSrc() {
      return this.type === this.accepted_type
        ? this.publicPath + `img/preview-pdf.png`
        : URL.createObjectURL(this.file);
    },
    publicPath() {
      return process.env.BASE_URL;
    },
    getFileName() {
      return this.file.name.length <= 15
        ? this.file.name
        : this.file.name.slice(0, 15) + '...' + this.file.name.split('.').pop();
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.isCheckFilePdf {
  justify-content: start !important;
  padding-left: 18px;
  align-items: center;
}
.upload_pdf {
  width: 24px;
  height: 24px;
}
.remove-file {
  min-width: 18px;
  height: 18px;
  background: #0095ab;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 18px;
  cursor: pointer;
}
.image {
  position: relative;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  height: 118px;
  background: rgba(0, 88, 162, 0.05);
  border: 2px dashed #add0ee;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  gap: 12px;
}
label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.file_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.upload-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type='file'] {
  display: none;
}
.remove-uploaded {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: brown;
  height: auto;
  background: aliceblue;
}
</style>
